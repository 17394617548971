import { ItemPermissions } from '@/types/auth/permissions';
import { IMaterials } from '@/types/materials/materials';

import { ChangeEvent, FC } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Group,
  Input,
  MultiSelect,
  NumberInput,
  Radio,
  Select,
  Textarea,
  TextInput,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form';
import { IconCalendar } from '@tabler/icons';

import { transliteration } from '@/utils/transliteration';

import { FileLoader } from '@/ui/components/FileLoader';
import { TooltipTextWrapper } from '@/ui/components/TooltipFormWrapper';
import { IMaterialsFormFields } from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialForm.types';
import {
  containsOptions,
  EActivityFieldValues,
  EMaterialsFormFields,
  selectDisplayOnPageOptions,
  selectDisplayOnSitesOptions,
  sortTooltip,
} from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialsForm.constants';
import { getImageData } from '@/ui/containers/NewsDetailInfo/NewsForm/NewsForm.helpers';

import styles from './MaterialsForm.module.scss';

import { useDictionaryOptions } from '@/store/slices/dictionary';
import { useUmkOptions } from '@/store/slices/umk/umk.hooks';

interface IProps {
  form: UseFormReturnType<IMaterialsFormFields>;
  sessionID: string;
  material: IMaterials | null;
  isNewMaterial: boolean;
  permissions: ItemPermissions;
}

export const MaterialsForm: FC<IProps> = ({
  form,
  isNewMaterial,
  sessionID,
  material,
  permissions: { canUpdate, canUpdateActivity },
}) => {
  const { data: ageSelectOptions } = useDictionaryOptions('cnclassage', material?.age);
  const { data: controlSelectOptions } = useDictionaryOptions(
    'typeofcontrol',
    material?.typeOfControl,
  );
  const { data: roleSelectOptions } = useDictionaryOptions('readerrole', material?.readerRole);
  const { data: levelSelectOptions } = useDictionaryOptions(
    'cnedulevelid',
    material?.educationLevel,
  );
  const { data: subjectSelectOptions } = useDictionaryOptions('cnsubject', material?.subject);
  const { data: themeSelectOptions } = useDictionaryOptions('theme', material?.theme);
  const { data: directionSelectOptions } = useDictionaryOptions(
    'cndirectionid',
    material?.direction,
  );
  const { data: authorsSelectOptions } = useDictionaryOptions('materialauthor', material?.author);
  const { data: typeSelectOptions } = useDictionaryOptions('materialtype', material?.type);
  const { data: digitalServiceOptions } = useDictionaryOptions(
    'digitalservice',
    material?.digitalService,
  );
  const { data: tagOptions } = useDictionaryOptions('tag', material?.tags);

  const umk = useUmkOptions(material?.lineUmk);

  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    if (isNewMaterial) {
      form.setFieldValue(
        EMaterialsFormFields.URL_CODE,
        `${transliteration(event.currentTarget.value, { onlyLower: true })}`,
      );
    }
    return form.getInputProps(EMaterialsFormFields.TITLE).onChange(event);
  };

  const savedFormFiles = getImageData(material?.image?.imageData);

  return (
    <Box
      style={{
        display: 'grid',
        gap: '24px',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <TextInput
        withAsterisk
        label='Заголовок'
        style={{ gridColumn: 'span 8' }}
        placeholder='Введите заголовок новости'
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.TITLE)}
        onChange={handleChangeTitle}
      />

      <Radio.Group
        label='Активность'
        withAsterisk
        style={{ gridColumn: 'span 4', justifyItems: 'end' }}
        {...form.getInputProps(EMaterialsFormFields.IS_ACTIVE)}
      >
        <Group>
          <Radio
            value={EActivityFieldValues.ACTIVE}
            label='Да'
            disabled={!(canUpdate || canUpdateActivity)}
          />
          <Radio
            value={EActivityFieldValues.NOT_ACTIVE}
            label='Нет'
            disabled={!(canUpdate || canUpdateActivity)}
          />
        </Group>
      </Radio.Group>

      <TextInput
        label='Символьный код URL'
        withAsterisk
        style={{ gridColumn: 'span 8' }}
        placeholder='Cсылка на новость'
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.URL_CODE)}
      />

      <DatePickerInput
        locale='ru'
        leftSection={<IconCalendar size={16} />}
        placeholder='Дата публикации'
        title='Дата публикации'
        withAsterisk
        style={{ gridColumn: 'span 4' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.DATA_ACTIVE_FROM_DAYS)}
      />

      <MultiSelect
        label={
          <TooltipTextWrapper
            label='Показывать на сайтах'
            text='Используется также для заполнения SEO-тега name у изображения'
            required
          />
        }
        data={selectDisplayOnSitesOptions}
        style={{ gridColumn: 'span 6' }}
        placeholder='Выберите сайты'
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.DISPLAY_ON_SITES)}
      />

      <MultiSelect
        withAsterisk
        label='Показывать на страницах'
        data={selectDisplayOnPageOptions}
        style={{ gridColumn: 'span 6' }}
        placeholder='Выберите страницы'
        disabled={!canUpdate}
        defaultValue={[selectDisplayOnPageOptions[0].value]}
        {...form.getInputProps(EMaterialsFormFields.TARGET_PAGES)}
      />

      <NumberInput
        style={{ gridColumn: 'span 3' }}
        label={<TooltipTextWrapper label='Вес сортировки' text={sortTooltip} />}
        hideControls
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.SORT)}
      />

      <NumberInput
        required
        label='Время чтения (в минутах)'
        style={{ gridColumn: 'span 3' }}
        hideControls
        placeholder='Укажите время чтения'
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.READING_TIME)}
      />
      <Box style={{ gridColumn: 'span 3' }}></Box>

      <Checkbox
        label='Сделать главным материалом'
        style={{ gridColumn: 'span 12' }}
        checked={form.values[EMaterialsFormFields.IS_MAIN_ARTICLE]}
        onChange={({ target: { checked } }) => {
          form.setFieldValue(EMaterialsFormFields.IS_MAIN_ARTICLE, checked);
        }}
        disabled={!canUpdate}
      />
      {/*TODO: скрыто PROSVNEW-4336[4] */}
      {/*<Checkbox*/}
      {/*  label='Сгенерировать оглавление'*/}
      {/*  style={{ gridColumn: 'span 6' }}*/}
      {/*  checked={form.values[EMaterialsFormFields.IS_MAIN_ARTICLE]}*/}
      {/*  {...form.getInputProps(EMaterialsFormFields.IS_MAIN_ARTICLE)}*/}
      {/*/>*/}

      <Textarea
        label={
          <TooltipTextWrapper
            label='Краткое описание'
            text='Используется также для заполнения SEO-тега name у изображения'
          />
        }
        placeholder='Введите краткое описание новости'
        className={styles.textarea}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.SHORT_DESCRIPTION)}
      />

      <Input.Wrapper
        label='Изображение (допускаются только широкоформатные изображения)'
        style={{ gridColumn: 'span 6' }}
      >
        <FileLoader
          sessionID={sessionID}
          savedFormFiles={savedFormFiles}
          {...form.getInputProps(`image.${EMaterialsFormFields.IMAGE_ID}`)}
          onRemove={() => form.setFieldValue(`image.${EMaterialsFormFields.IMAGE_ID}`, '')}
          onChange={(params) => {
            form.setFieldValue(`image.${EMaterialsFormFields.IMAGE_ID}`, params.id);
          }}
          disabled={!canUpdate}
        />
      </Input.Wrapper>

      <Select
        withAsterisk
        label='Тип'
        placeholder='Выберите тип'
        data={typeSelectOptions}
        style={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.TYPE)}
      />

      <MultiSelect
        label='Роль читателя'
        placeholder='Выберите роль читателя'
        comboboxProps={{ position: 'top' }}
        data={roleSelectOptions}
        style={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.READER_ROLE)}
      />

      <MultiSelect
        label='Направление'
        placeholder='Выберите направление'
        comboboxProps={{ position: 'top' }}
        data={directionSelectOptions}
        style={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.DIRECTION)}
      />

      <MultiSelect
        label='Форма контроля'
        placeholder='Выберите форму контроля'
        comboboxProps={{ position: 'top' }}
        data={controlSelectOptions}
        style={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.TYPE_OF_CONTROL)}
      />

      <MultiSelect
        label='Уровень образования'
        placeholder='Выберите уровень образования'
        comboboxProps={{ position: 'top' }}
        data={levelSelectOptions}
        style={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.EDUCATION_LEVEL)}
      />

      <MultiSelect
        label='Класс и возраст'
        placeholder='Выберите класс'
        comboboxProps={{ position: 'top' }}
        data={ageSelectOptions}
        style={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.AGE)}
      />

      <MultiSelect
        label='Тема'
        placeholder='Выберите тему'
        comboboxProps={{ position: 'top' }}
        data={themeSelectOptions}
        style={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.THEME)}
      />

      <MultiSelect
        label='Статья содержит'
        placeholder='Выберите содержание статьи'
        comboboxProps={{ position: 'top' }}
        data={containsOptions}
        style={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.CONTAINS)}
      />

      <MultiSelect
        label='Цифровой сервис'
        placeholder='Выберите цифровой сервис'
        comboboxProps={{ position: 'top' }}
        data={digitalServiceOptions}
        style={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.DIGITAL_SERVICE)}
      />

      <MultiSelect
        label='Предмет'
        placeholder='Выберите предмет'
        comboboxProps={{ position: 'top' }}
        data={subjectSelectOptions}
        style={{ gridColumn: 'span 12' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.SUBJECT)}
      />

      <MultiSelect
        label='Серия/Линия УМК'
        placeholder='Серия/Линия УМК'
        data={umk.options}
        style={{ gridColumn: 'span 6' }}
        comboboxProps={{ position: 'top' }}
        disabled={!canUpdate}
        searchValue={umk.input}
        onSearchChange={umk.setInput}
        searchable
        {...form.getInputProps(EMaterialsFormFields.LINE_UMK)}
      />

      <MultiSelect
        label='Авторы'
        placeholder='Выберите автора'
        disabled={!canUpdate}
        data={authorsSelectOptions}
        {...form.getInputProps(EMaterialsFormFields.AUTHOR)}
        style={{ gridColumn: 'span 12' }}
      />

      <MultiSelect
        label='Теги'
        placeholder='Выберите теги'
        disabled={!canUpdate}
        data={tagOptions}
        {...form.getInputProps(EMaterialsFormFields.TAGS)}
        style={{ gridColumn: 'span 8' }}
      />

      <Box style={{ gridColumn: 'span 4', position: 'relative', height: '100%' }}>
        <Button
          component={Link}
          to='/dictionary/tag'
          variant='white'
          target='_blank'
          style={{ top: 26, position: 'absolute', padding: 0 }}
        >
          Добавить новый тег
        </Button>
      </Box>
    </Box>
  );
};
