import { ComboboxItem } from '@mantine/core';
import { CatalogSnippet, SnippetValues } from '@prosv/core/types/snippets';
import { v4 as uuid_v4 } from 'uuid';

import { ISelection } from '@/api/catalog/categories';

import { ECatalogSnippetFields } from './CatalogSnippet.constans';

export const getCatalogBlockDefaultValues = (
  defaultValues?: CatalogSnippet,
): SnippetValues<CatalogSnippet> => {
  return {
    [ECatalogSnippetFields.SELECTION_CODE]: [...(defaultValues?.selectionCode ?? '')],
    [ECatalogSnippetFields.ID]: defaultValues?.id ?? uuid_v4(),
  };
};

export const getSelectionOptions = (selections: ISelection[]) => {
  const options: ComboboxItem[] = [];
  selections.forEach(({ code, name }) => options.push({ value: code, label: name }));
  return options;
};
