import { Button, Popover, TextInput } from '@mantine/core';
import { useDisclosure, useInputState } from '@mantine/hooks';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { IconTooltip } from '@tabler/icons';

import styles from './TooltipControl.module.scss';

export const TooltipControl = () => {
  const { editor, labels } = useRichTextEditorContext();

  const [tooltip, setTooltip] = useInputState('');
  const [opened, { open, close }] = useDisclosure(false);

  const handleOpen = () => {
    open();
    setTooltip(editor?.getAttributes('tooltipView').content ?? '');
  };

  const handleClose = () => {
    close();
    setTooltip('');
  };

  const setContent = () => {
    if (editor) {
      editor
        .chain()
        .setMark('tooltipView', { content: tooltip })
        .extendMarkRange('tooltipView')
        .updateAttributes('tooltipView', { content: tooltip })
        .focus()
        .run();
    }
    handleClose();
  };

  return (
    <Popover
      trapFocus
      shadow='md'
      withinPortal
      opened={opened}
      onClose={handleClose}
      offset={-44}
      zIndex={10000}
    >
      <Popover.Target>
        <RichTextEditor.Control
          active={editor?.isActive('tooltipView')}
          onClick={handleOpen}
          title='Tooltip'
        >
          <IconTooltip width={16} height={16} strokeWidth={1.5} />
        </RichTextEditor.Control>
      </Popover.Target>

      <Popover.Dropdown>
        <div className={styles.tooltipEditor}>
          <TextInput
            aria-label={labels.linkEditorInputLabel}
            value={tooltip}
            onChange={setTooltip}
            classNames={{ input: styles.tooltipEditorInput }}
          />

          <Button variant='default' onClick={setContent} className={styles.tooltipEditorSave}>
            Сохранить
          </Button>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};
