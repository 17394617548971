import { EStatusFeatureToggle, TFeature } from '@/types/features/features';

import React from 'react';
import { useDispatch } from 'react-redux';
import { Table } from '@mantine/core';

import { subString } from '@/utils/subString';

import { usePermission } from '@/hooks/usePermissions';

import { ActiveStatus } from './ActiveStatus';

import { setEditFeature } from '@/store/slices/features/features';

type TTableBodyProps = {
  items?: TFeature[] | null;
};

const TableBodyUI: React.FC<TTableBodyProps> = ({ items }) => {
  // Исправить скрытия страницы тоглов
  const canUpdate = usePermission('DICTIONARY_CREATE');
  const dispatch = useDispatch();

  const handleEditFeature = (feature: TFeature) => {
    dispatch(setEditFeature(feature));
  };

  return (
    <Table.Tbody>
      {items?.map((item, index) => {
        return (
          <Table.Tr key={index}>
            <Table.Td style={{}}>{item.name}</Table.Td>
            <Table.Td style={{}}>
              <ActiveStatus
                canUpdate={!!canUpdate}
                status={EStatusFeatureToggle[item.status]}
                onChangeStatus={() => handleEditFeature(item)}
              />
            </Table.Td>
            <Table.Td style={{}}>{subString(item.description, 200)}</Table.Td>
            <Table.Td style={{}}>{subString(item.strategy, 200)}</Table.Td>
            <Table.Td style={{ maxWidth: '50px' }}></Table.Td>
          </Table.Tr>
        );
      })}
    </Table.Tbody>
  );
};

export const TableBody = React.memo(TableBodyUI);
