import { EventSnippet, SnippetValues } from '@prosv/core/types/snippets';
import dayjs from 'dayjs';
import { v4 as uuid_v4 } from 'uuid';

import { EEventSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/EventSnippetModal/EventSnippetModal.constants';

import { EventSnippetFormValues } from './EventSnippetModal.types';

export const getEventSnippetInitialValues = (
  defaultValues?: EventSnippet,
): EventSnippetFormValues => {
  const dayjsDate = dayjs(defaultValues?.date);
  const dayjsTime = dayjs(defaultValues?.time);

  return {
    [EEventSnippetFormFields.ID]: defaultValues?.id || uuid_v4(),
    [EEventSnippetFormFields.LINK]: defaultValues?.link || '',
    [EEventSnippetFormFields.BACKGROUND]: defaultValues?.background || '',
    [EEventSnippetFormFields.IMAGE]: defaultValues?.image || {
      id: '',
      path: '',
      name: '',
    },
    [EEventSnippetFormFields.DESCRIPTION]: defaultValues?.description || '',
    [EEventSnippetFormFields.TITLE]: defaultValues?.title || '',
    [EEventSnippetFormFields.IMAGE_TITLE]: defaultValues?.imageTitle || '',
    [EEventSnippetFormFields.ALT]: defaultValues?.alt || '',
    [EEventSnippetFormFields.DATE]: dayjsDate.isValid() ? dayjsDate.toDate() : null,
    [EEventSnippetFormFields.TIME]: dayjsTime.isValid() ? dayjsTime.format('HH:mm') : null,
  };
};
