import { NotificationData, notifications } from '@mantine/notifications';

type NotifyType = {
  message: string;
  type?: 'info' | 'success' | 'warning' | 'error' | 'default' | 'dark';
  params?: NotificationData;
};

const typeNotify = {
  info: 'blue',
  success: 'green',
  warning: 'orange',
  error: 'red',
  dark: 'black',
};

export default function notify({ message, type = 'default', params }: NotifyType) {
  const config: NotificationData = {
    id: message,
    autoClose: 5000,
    message,
    ...params,
  };

  notifications.show({ ...config, color: typeNotify[type as keyof typeof typeNotify] });
}

export const notifySaveSuccess = () => notify({ message: 'Изменения сохранены', type: 'success' });

export const notifySaveError = () =>
  notify({ message: 'Произошла ошибка, изменения не были сохранены', type: 'error' });
