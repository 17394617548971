import { FC } from 'react';
import { Table, UnstyledButton, useMantineTheme } from '@mantine/core';
import { ChevronDown, ChevronUp, Selector } from 'tabler-icons-react';

import { TableColumnTitle } from '@/ui/components/TableExt/components/TableColumnTitle';

import styles from './TableHeadSortable.module.scss';

interface ITableHeadSortable {
  name: string;
  reversed?: boolean;
  sorted?: boolean | null;
  onSort(sortFieldName?: string): void;
}

export const TableHeadSortable: FC<ITableHeadSortable> = ({ name, reversed, sorted, onSort }) => {
  const Icon = sorted ? (reversed ? ChevronUp : ChevronDown) : Selector;
  const theme = useMantineTheme();

  return (
    <Table.Th>
      <UnstyledButton onClick={() => onSort()} className={styles.control}>
        <Icon size={20} color={theme.colors.greyDefault[9]} />
        <TableColumnTitle>{name}</TableColumnTitle>
      </UnstyledButton>
    </Table.Th>
  );
};
