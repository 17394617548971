import { useEffect, useMemo } from 'react';
import { Box, Button, Group, MultiSelect, Select, TextInput } from '@mantine/core';
import { RotateClockwise } from 'tabler-icons-react';
import { useDebouncedCallback } from 'use-debounce';

import { PAGE_ELEMENTS } from '@/constants/common';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import styles from './SupportsFilter.module.scss';

import { inputStyles } from '@/pages/Banners/BannersListing/BannerListing.constants';
import { supportsInitialState, work } from '@/pages/Support/SupportsListing.constants';
import {
  CreateFilterSlice,
  isDirtySupportFilter,
  supportsFilterActions,
  supportsFiltersSelector,
  useGetCategoryTreatmentQuery,
} from '@/store/slices/support/supportDetailSlice';

export const SupportsFilter = ({
  getSupportsListing,
}: {
  getSupportsListing: (params: CreateFilterSlice) => void;
}) => {
  const { resetFilters, setFilterWithResetCurrentPage } = supportsFilterActions;
  const { limit, category, question, isActive } = useAppSelector(supportsFiltersSelector);
  const { data } = useGetCategoryTreatmentQuery();

  const categoryOptions = useMemo(
    () => (data?.payload.items || []).map((item) => ({ label: item.name, value: item.code })),
    [data?.payload.items],
  );
  const filter = useAppSelector((state) => state.supportsFilter);
  const isDirty = useAppSelector(isDirtySupportFilter);

  const dispatch = useAppDispatch();

  useEffect(() => {
    getSupportsListing(supportsInitialState);
  }, []);

  const handleSearchDebounced = useDebouncedCallback((params: Partial<CreateFilterSlice>) => {
    getSupportsListing({ ...filter, ...params });
  }, 300);

  const actionWithDebounce = (params: Partial<CreateFilterSlice>) => {
    dispatch(setFilterWithResetCurrentPage(params));
    handleSearchDebounced(params);
  };

  const onReset = () => {
    dispatch(resetFilters());
    getSupportsListing(supportsInitialState);
  };

  return (
    <Group
      className={styles.root}
      style={{ zIndex: 5 }}
      mb={24}
      justify='flex-start'
      align='flex-end'
      gap='xl'
      grow
    >
      <Box className={styles.field}>
        <Select
          clearable
          label='Активность'
          placeholder='Активность'
          height={40}
          data={work}
          value={isActive}
          onChange={(v) => {
            actionWithDebounce({ isActive: v === isActive ? undefined : v });
          }}
        />
      </Box>
      <Box className={styles.field}>
        <TextInput
          label='Вопрос'
          placeholder='Заголовок'
          value={question}
          styles={inputStyles}
          onChange={(v) => actionWithDebounce({ question: v.target.value })}
        />
      </Box>
      <Box className={cn(styles.field, styles.big)}>
        <MultiSelect
          label='Категория обращения'
          placeholder='Категория обращения'
          data={categoryOptions}
          value={category}
          onChange={(v) => actionWithDebounce({ category: v })}
          height={40}
        />
      </Box>

      <div style={{ display: 'flex', marginLeft: 'auto', justifyContent: 'end' }}>
        {isDirty && (
          <Box
            style={{ display: 'flex', alignItems: 'center', height: 38, maxWidth: 'fit-content' }}
          >
            <Button
              className={styles.resetBtn}
              variant='subtle'
              radius='xs'
              size='compact-sm'
              rightSection={<RotateClockwise size={20} />}
              onClick={onReset}
            >
              Сбросить
            </Button>
          </Box>
        )}
        <Box className={styles.pageSize}>
          <Select
            className={styles.select}
            size='sm'
            value={limit || PAGE_ELEMENTS[0].value}
            onChange={(v) => actionWithDebounce({ limit: v as string })}
            allowDeselect
            data={PAGE_ELEMENTS}
          />
        </Box>
      </div>
    </Group>
  );
};
