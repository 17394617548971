import React, { FC, FormEventHandler, PropsWithChildren } from 'react';
import { Box, Button, Group, Modal, Text } from '@mantine/core';

import { ReactComponent as ArrowBack } from '@/assets/icons/arrow-back.svg';

interface IProps {
  handleClickBackBtn: () => void;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  onSubmit: FormEventHandler<HTMLFormElement> | undefined;
  withScroll?: boolean;
  isNew?: boolean;
}

export const SnippetModal: FC<PropsWithChildren<IProps>> = ({
  children,
  handleClickBackBtn,
  isOpen,
  onClose,
  title,
  onSubmit,
  withScroll,
  isNew,
}) => {
  const overflowContent = withScroll ? 'auto' : 'none';

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      styles={{
        body: {
          height: '700px',
        },
      }}
      title={
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ cursor: 'pointer' }} pt={6} pl={6} pr={12} onClick={handleClickBackBtn}>
            <ArrowBack width={16} height={18} />
          </Box>

          <Text fz={24} ta='left' fw={600}>
            {title}
          </Text>
        </Box>
      }
      size={1300}
      withCloseButton
    >
      <form
        onSubmit={onSubmit}
        style={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <section
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
            gap: '24px',
            padding: '0 5px',
            overflow: overflowContent,
          }}
        >
          {children}
        </section>
        <Group justify='flex-start' pt={16}>
          <Button type='submit' px={60} style={{ fontSize: 16 }}>
            {isNew ? 'Добавить' : 'Сохранить'}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
