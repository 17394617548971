import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Group, Paper, Title } from '@mantine/core';

import { usePermission } from '@/hooks/usePermissions';

import { BannerListingFilters } from './BannerListingFilters/BannerListingFilters';
import { BannerListingTable } from './BannerListingTable';

import styles from './BannerListing.module.scss';

import {
  CreateFilterSlice,
  useLazyGetBannersListingQuery,
} from '@/store/slices/banners/bannersSlice';

export const BannerListing = () => {
  const canCreate = usePermission('BANNER_CREATE');
  const navigate = useNavigate();
  const [getBannersListingRequest, data] = useLazyGetBannersListingQuery();

  const getBannersListing = useCallback(
    (params: CreateFilterSlice) => {
      getBannersListingRequest(params);
    },
    [getBannersListingRequest],
  );

  return (
    <Box style={{ height: '96vh', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}>
      <Container fluid mb={16} style={{ marginLeft: 'initial', marginRight: 'initial' }}>
        <Group className={styles.head}>
          <Title order={1}>Управление баннерами</Title>
          {canCreate && <Button onClick={() => navigate('/banners/new')}>Создать</Button>}
        </Group>
      </Container>
      <Paper
        radius={8}
        p={24}
        pb={12}
        shadow='xs'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: '100%',
          overflow: 'hidden',
        }}
      >
        <BannerListingFilters getBannersListing={getBannersListing} />
        <BannerListingTable
          getBannersListing={getBannersListing}
          status={data.isFetching}
          data={data.data?.payload.items}
          total={data.data?.payload.meta.total}
        />
      </Paper>
    </Box>
  );
};
