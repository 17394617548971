import { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AppShell } from '@mantine/core';

import { getCookie } from '@/utils/cookie';

import { LayoutNavbar } from './components/LayoutNavbar';

import styles from './Layout.module.scss';

export const Layout: FC = () => {
  const parsedToken = getCookie('token');
  const [opened, setOpened] = useState(true);

  if (!parsedToken) return <Outlet />;

  return (
    <AppShell
      className={styles.root}
      navbar={{ width: opened ? 220 : 72, breakpoint: 'sm' }}
      padding='md'
    >
      <LayoutNavbar {...{ opened, setOpened }} />

      <AppShell.Main className={styles.main}>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
};
