import { FC, useEffect } from 'react';
import { Box } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { isEqual } from 'lodash';

import { useGetAudioAttributes } from '@/api/banners/hooks/useGetAttributesAudio';

import { IBannerCreationState } from '@/ui/containers/BannerCreationContainer/BannerCreationContainer.types';
import { useViewsAndClicks } from '@/ui/containers/BannerCreationContainer/components/BannerDetailInfo/BannerDetailInfo.hooks';
import { BannerForm } from '@/ui/containers/BannerCreationContainer/components/BannerForm';
import { BANNER_CREATION_FORM_ID } from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerForm.constants';
import { getDefaultValues } from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerForm.helpers';
import { IBannerFormFields } from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerForm.types';
import { bannerFormSchema } from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerFrom.schema';
import { BannerGeneralInfo } from '@/ui/containers/BannerCreationContainer/components/BannerGeneralInfo';
import {
  setSelectorDefaultFormData,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';

interface IProps {
  bannerState: IBannerCreationState;
  handleSubmit: (values: IBannerFormFields) => void;
}

export const BannerDetailInfo: FC<IProps> = ({ bannerState, handleSubmit }) => {
  const {
    isNewBanner,
    sessionID,
    setIsShouldBlock,
    defaultFormData,
    trySaveBanner,
    setTrySaveBanner,
    banner,
    getNumberOfViews,
    getNumberOfClicks,
    permissions,
  } = bannerState;

  const form = useForm({
    initialValues: getDefaultValues(banner),
    validate: yupResolver(bannerFormSchema),
  });
  const audioAttributes = useGetAudioAttributes();
  const setDefaultFormData = useStoreContentEditor(setSelectorDefaultFormData);

  const { clicks, views } = useViewsAndClicks({
    getNumberOfViews,
    getNumberOfClicks,
    bannerId: banner?.uuid,
  });

  useEffect(() => {
    if (form.values.page.includes('audio')) {
      audioAttributes.getAudioAttributes(['subject', 'class']);
    }
  }, [form.values.page]);

  useEffect(() => {
    setDefaultFormData(form.values);
    return () => {
      setDefaultFormData(null);
    };
  }, []);

  useEffect(() => {
    if (defaultFormData && !isEqual(form.values, defaultFormData)) {
      setIsShouldBlock(true);
    }
  }, [form]);

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        gap: '24px',
      }}
    >
      <form
        id={BANNER_CREATION_FORM_ID}
        style={{
          border: '1px solid #EDF1F3',
          borderRadius: '6px',
          padding: '16px 12px',
          overflow: 'scroll',
        }}
        onSubmit={form.onSubmit(handleSubmit)}
      >
        <BannerForm
          permissions={permissions}
          isNewBanner={isNewBanner}
          form={form}
          banner={banner}
          handleSubmit={handleSubmit}
          sessionID={sessionID.current}
          setTrySave={setTrySaveBanner}
          trySaveBanner={trySaveBanner}
          audioAttributes={audioAttributes.data?.payload}
        />
      </form>
      <Box>
        <BannerGeneralInfo {...{ isNewBanner, banner, views, clicks, permissions }} />
      </Box>
    </Box>
  );
};
