import { FC } from 'react';
import { Box, Text } from '@mantine/core';

import { STR_LENGTH_LIFT } from '@/constants/common';

interface IProps {
  currentLength: string;
  maxLength: number;
}

export const LineLengthDisplay: FC<IProps> = ({ currentLength, maxLength }) => {
  return (
    <Box
      style={{
        position: 'absolute',
        right: '12px',
        bottom: '8px',
        display: 'flex',
        flexDirection: 'row',
        fontSize: '12px',
        backgroundColor: 'white',
        zIndex: STR_LENGTH_LIFT,
      }}
    >
      <Text style={{ color: '#121212' }}>{currentLength}</Text> {'/'}
      <Text style={{ color: '#66788A' }}>{maxLength}</Text>
    </Box>
  );
};
