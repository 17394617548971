import { useState } from 'react';
import { Box, Button, Group, MultiSelect, Text, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { Filter, RotateClockwise } from 'tabler-icons-react';

import { endOfDay } from '@/utils/datetime';

import { formatDate } from '@/constants/common';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import { useGetMaterialsList } from '@/ui/containers/MaterialsContainer/Materials.hooks';

import styles from './MaterialsFilter.module.scss';

import { inputStyles } from '@/pages/Banners/BannersListing/BannerListing.constants';
import { useDictionaryOptions } from '@/store/slices/dictionary';
import {
  IInitialState,
  materialsListingFilters,
  resetMaterialsFilter,
  setMaterialsFilter,
} from '@/store/slices/materials/materials';
import { useUmkOptions } from '@/store/slices/umk/umk.hooks';

export const MaterialsFilter = () => {
  const dispatch = useAppDispatch();
  const [moreOptions, setMoreOptions] = useState(false);

  const { data: ageSelectOptions } = useDictionaryOptions('cnclassage', 'code');
  const { data: controlSelectOptions } = useDictionaryOptions('typeofcontrol', 'code');
  const { data: roleSelectOptions } = useDictionaryOptions('readerrole', 'code');
  const { data: levelSelectOptions } = useDictionaryOptions('cnedulevelid', 'code');
  const { data: subjectSelectOptions } = useDictionaryOptions('cnsubject', 'code');
  const { data: themeSelectOptions } = useDictionaryOptions('theme', 'code');
  const { data: directionSelectOptions } = useDictionaryOptions('cndirectionid', 'code');
  const { data: authorsSelectOptions } = useDictionaryOptions('materialauthor', 'code');
  const { data: typeSelectOptions } = useDictionaryOptions('materialtype', 'code');
  const { data: digitalServicesSelectOptions } = useDictionaryOptions('digitalservice', 'code');

  const {
    query,
    type,
    typeOfControl,
    direction,
    author,
    lineUmk,
    theme,
    readerRole,
    age,
    educationLevel,
    subject,
    digitalService,
  } = useAppSelector(materialsListingFilters);

  const [datePickerVal] = useState();

  const getMaterialsList = useGetMaterialsList();

  const onReset = () => {
    dispatch(resetMaterialsFilter());
    getMaterialsList();
  };

  const onChange = (params: Partial<IInitialState['filter']>) => {
    dispatch(setMaterialsFilter(params));
    getMaterialsList();
  };

  const handleSetTitle = (searchTitle: string) => onChange({ query: searchTitle });

  const umk = useUmkOptions();

  return (
    <>
      <Group className={styles.group} justify='flex-start' align='flex-end' gap='xs' grow>
        <Box>
          <Text size='sm'>Заголовок</Text>
          <TextInput
            value={query}
            styles={inputStyles}
            onChange={(v) => handleSetTitle(v.target.value)}
            placeholder='Введите заголовок материала'
          />
        </Box>
        <MultiSelect
          data={typeSelectOptions}
          label='Тип материала'
          placeholder='Выберите тип'
          value={type}
          onChange={(value) => onChange({ type: value, page: 1 })}
        />
        <DatePickerInput
          type='range'
          locale='ru'
          placeholder='Выберите период'
          label='Период создания'
          allowSingleDateInRange
          value={datePickerVal}
          onChange={(value) =>
            onChange({
              createDateFrom: value[0],
              createDateTo: endOfDay(value[1]),
              page: 1,
            })
          }
          valueFormat={formatDate}
          className={styles.dateRange}
        />
        <MultiSelect
          data={roleSelectOptions}
          label='Роль читателя'
          placeholder='Выберите роль'
          value={readerRole}
          onChange={(value) => onChange({ readerRole: value, page: 1 })}
        />

        <Box>
          <Button
            variant='subtle'
            radius='xs'
            size='compact-sm'
            className={styles.resetBtn}
            rightSection={<RotateClockwise size={20} />}
            onClick={onReset}
          >
            Сбросить
          </Button>

          <Button
            onClick={() => setMoreOptions((prev) => !prev)}
            style={{
              width: '190px',
              maxWidth: '190px',
              height: '42px',
              display: 'flex',
              gap: 100,
              justifyContent: 'center',
            }}
          >
            <Filter fill='white' />
            {moreOptions ? 'Скрыть фильтры' : 'Ещё фильтры'}
          </Button>
        </Box>
      </Group>
      {moreOptions && (
        <>
          <Group className={styles.group} gap='xs' grow>
            <MultiSelect
              data={ageSelectOptions}
              label='Класс и возраст'
              placeholder='Выберите класс'
              value={age}
              onChange={(value) => onChange({ age: value, page: 1 })}
            />
            <MultiSelect
              data={directionSelectOptions}
              label='Направление'
              placeholder='Выберите направление'
              value={direction}
              onChange={(value) => onChange({ direction: value, page: 1 })}
            />
            <MultiSelect
              data={controlSelectOptions}
              label='Форма контроля'
              placeholder='Форма контроля'
              value={typeOfControl}
              onChange={(value) => onChange({ typeOfControl: value, page: 1 })}
            />
            <MultiSelect
              data={levelSelectOptions}
              label='Уровень образования'
              placeholder='Уровень образования'
              value={educationLevel}
              onChange={(value) => onChange({ educationLevel: value, page: 1 })}
            />
            <MultiSelect
              data={subjectSelectOptions}
              label='Предмет'
              placeholder='Предмет'
              value={subject}
              onChange={(value) => onChange({ subject: value, page: 1 })}
            />
          </Group>
          <Group className={styles.group} gap='xs' grow>
            <MultiSelect
              data={themeSelectOptions}
              label='Тема'
              placeholder='Выберите тему'
              value={theme}
              onChange={(value) => onChange({ theme: value, page: 1 })}
            />
            <MultiSelect
              data={umk.options}
              label='Линия УМК'
              placeholder='Линия УМК'
              value={lineUmk}
              searchValue={umk.input}
              onSearchChange={umk.setInput}
              searchable
              onChange={(value) => onChange({ lineUmk: value, page: 1 })}
            />
            <MultiSelect
              data={authorsSelectOptions}
              label='Авторы'
              placeholder='Авторы'
              value={author}
              onChange={(value) => onChange({ author: value, page: 1 })}
            />
            <MultiSelect
              data={digitalServicesSelectOptions}
              label='Цифровые сервисы'
              placeholder='Цифровые сервисы'
              value={digitalService}
              onChange={(value) => onChange({ digitalService: value, page: 1 })}
            />
          </Group>
        </>
      )}
    </>
  );
};
