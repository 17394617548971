import React from 'react';
import { Box, Loader as LoaderLib } from '@mantine/core';

export const Loader: React.FC<{ loading?: boolean; blur?: boolean }> = ({ loading, blur }) => {
  if (!loading) return null;

  return (
    <Box
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'not-allowed !important',
        zIndex: 1000,
        ...(blur ? { backdropFilter: 'blur(2px)' } : {}),
      }}
    >
      <LoaderLib color='primary' size={70} />
    </Box>
  );
};
