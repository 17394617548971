import { PublicationsDictionaryItem } from '@prosv/core/types/bff/base';
import { UMKItem } from '@prosv/core/types/bff/umk';

import { URLS } from '@/api/urls';

import { DictionarySelectOption } from '../dictionary/dictionary.types';

export const selectedUmkOptions = (selectedItems: PublicationsDictionaryItem[]) =>
  selectedItems.map<DictionarySelectOption>(({ id, name }) => ({ value: id, label: name }));

export const prepareUmkOptions = (apiItems: UMKItem[]) => {
  const items: UMKItem[] = [];

  for (const item of apiItems) {
    if (!item.uuid) {
      console.warn(`[${URLS.umk.searchUmk}] Пустой uuid:`, item);
      continue;
    }

    const existsItem = items.find((addedItem) => addedItem.uuid === item.uuid);
    if (existsItem) {
      console.warn(`[${URLS.umk.searchUmk}] Повтор uuid:`, existsItem, item);
      continue;
    }

    items.push(item);
  }

  return items.map<DictionarySelectOption>((item) => ({
    value: item.uuid ?? '',
    label: item.name,
  }));
};
