import { IRedirect } from '@/types/redirects/redirects';

import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Center,
  Group,
  Input,
  LoadingOverlay,
  MultiSelect,
  Pagination,
  Paper,
  Text,
} from '@mantine/core';
import { RotateClockwise } from 'tabler-icons-react';

import { tryGetRedirectStatus, trySendFileId, uploadRedirectFile } from '@/api/redirects/redirects';

import { getOffset } from '@/utils/getOffset';
import { getPages } from '@/utils/getPages';
import notify from '@/utils/notify';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import { Loader } from '@/ui/containers/ContentEditor/Loader';
import { ProgressUpload } from '@/ui/containers/RedirectsContainer/components/ProgressUpload';
import {
  ACCEPTED_FILE_TYPES,
  FILE_TYPE_ERROR_MESSAGE,
  FILE_UPLOAD_ERROR_MESSAGE,
  FILE_UPLOAD_SUCCEED_MESSAGE,
  LIMIT_QUERY,
  OFFSET_QUERY,
  REDIRECTS_UPLOAD_SUCCEED_MESSAGE,
  REDIRECTS_UPLOAD_UNSUCCEED_MESSAGE,
} from '@/ui/containers/RedirectsContainer/RedirectsContainer.constants';
import { FileStatus } from '@/ui/containers/RedirectsContainer/RedirectsContainer.types';

import { EditRedirectModal } from './components/EditRedirectModal/EditRedirectModal';
import { RedirectsTable } from './components/RedirectsTable/RedirectsTable';
import { mockOptions } from './RedirectsContainer.mock';

import styles from './RedirectsContainer.module.scss';

import { ReactComponent as SearchIcon } from '@/assets/icons/search-filter.svg';
import {
  editRedirectItemSelector,
  fetchRedirectListAction,
  loadingRedirectListSelector,
  redirectListSelector,
  redirectsCodeFilterSelector,
  redirectsLimitSelector,
  redirectsOffsetSelector,
  redirectsSearchMessageSelector,
  redirectsTotalSelector,
  redirectsUrlFilterSelector,
  setClearFilters,
  setCode,
  setEditRedirectItem,
  setRedirectOffset,
  setUrl,
  updateRedirectAction,
} from '@/store/slices/redirects/redirects';

interface IProps {}

export const RedirectsContainer: FC<IProps> = () => {
  const dispatch = useAppDispatch();

  const redirectList = useSelector(redirectListSelector);
  const redirectsTotal = useSelector(redirectsTotalSelector);
  const redirectsLimit = useSelector(redirectsLimitSelector);
  const redirectsOffset = useSelector(redirectsOffsetSelector);
  const isLoadingRedirectList = useSelector(loadingRedirectListSelector);
  const urlFilter = useSelector(redirectsUrlFilterSelector);
  const codeFilter = useSelector(redirectsCodeFilterSelector);
  const filterMessage = useSelector(redirectsSearchMessageSelector);
  const editRedirectItem = useSelector(editRedirectItemSelector);

  const [isDirty, setIsDirty] = useState(false);

  const [isUploadFile, setIsUploadFile] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [fileStatus, setFileStatus] = useState<FileStatus>(FileStatus.waiting);
  const [fileID, setFileID] = useState<string | null>(null);
  const [progressFile, setProgressFile] = useState<number>(0);
  const [successfullyRedirects, setSuccessfullyRedirects] = useState(0);
  const [unSuccessfullyRedirects, setUnsuccessfullyRedirects] = useState(0);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const getFileStatus = async (jobId: string) => {
    try {
      const { status, id, progress, failCount, createGoodCount, updateGoodCount } =
        await tryGetRedirectStatus(jobId);
      setFileStatus(status);
      setFileID(id);
      setProgressFile(progress);
      setSuccessfullyRedirects(createGoodCount + updateGoodCount);
      setUnsuccessfullyRedirects(failCount);
      if (status === FileStatus.done) {
        notify({ message: FILE_UPLOAD_SUCCEED_MESSAGE, type: 'success' });
      }
    } catch (err) {
      notify({ message: FILE_UPLOAD_ERROR_MESSAGE, type: 'error' });
    }
  };

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileNameParts = file?.name.split('.');
      const fileExtension = fileNameParts?.[fileNameParts.length - 1];
      if (fileExtension && !ACCEPTED_FILE_TYPES.includes(`.${fileExtension}`)) {
        notify({ message: FILE_TYPE_ERROR_MESSAGE, type: 'error' });
        return;
      } else {
        try {
          setIsUploadFile(true);
          const formData = new FormData();
          formData.append('file', file);
          formData.append('name', file.name);
          formData.append('configType', 'seoRedirectFiles');

          const id = await uploadRedirectFile(formData);
          const { id: jobId } = await trySendFileId({ fileId: id });

          await getFileStatus(jobId);
        } catch (e) {
          notify({ message: FILE_UPLOAD_ERROR_MESSAGE, type: 'error' });
        }
      }
    }
  };

  const onChangeUrlHandler = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setUrl(event.target.value));
  };

  const onChangeCodeHandler = (value: string[]) => {
    dispatch(setCode(value));
  };

  const onApplyFilters = () => {
    dispatch(
      fetchRedirectListAction({
        filter: {
          old: urlFilter || null,
          statusCode: codeFilter || null,
        },
      }),
    );
  };

  const resetFilters = () => {
    dispatch(setClearFilters());
    dispatch(fetchRedirectListAction({}));
  };

  const onEditRedirectItem = (values: IRedirect) => {
    const { new: newUrl, old, statusCode, id } = values;
    const code = Number(statusCode);
    if (code) {
      dispatch(updateRedirectAction({ id, new: newUrl, old, statusCode: code }));
    }
  };

  useEffect(() => {
    dispatch(
      fetchRedirectListAction({
        [OFFSET_QUERY]: redirectsOffset,
        [LIMIT_QUERY]: redirectsLimit,
      }),
    );
  }, [redirectsOffset]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (fileStatus === FileStatus.pending && fileID) {
        getFileStatus(fileID);
      }
    }, 3000);

    return () => clearInterval(intervalId);
  }, [fileStatus]);

  useEffect(() => {
    if (progressFile >= 100) {
      setFileID(null);
      setProgressFile(0);
      setFileStatus(FileStatus.done);
      if (successfullyRedirects > 0) {
        notify({
          message: `${REDIRECTS_UPLOAD_SUCCEED_MESSAGE}${successfullyRedirects} `,
          type: 'success',
        });
      }
      if (unSuccessfullyRedirects > 0) {
        notify({
          message: `${REDIRECTS_UPLOAD_UNSUCCEED_MESSAGE}${unSuccessfullyRedirects}`,
          type: 'error',
        });
      }
    }
  }, [progressFile]);

  useEffect(() => {
    if (codeFilter || urlFilter) {
      setIsDirty(true);
      return;
    } else {
      setIsDirty(false);
    }
  }, [urlFilter, codeFilter]);

  useEffect(() => {
    if (fileStatus === FileStatus.done) {
      dispatch(
        fetchRedirectListAction({
          [OFFSET_QUERY]: redirectsOffset,
          [LIMIT_QUERY]: redirectsLimit,
        }),
      );
      setIsUploadFile(false);
    }
  }, [fileStatus]);

  return (
    <Box
      style={{
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Loader loading={false} blur />

      <PageHeader
        title='Настройка редиректов'
        rightButton={
          <>
            <Input
              type='file'
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileSelect}
              accept={ACCEPTED_FILE_TYPES.join(',')}
            />
            <Button
              style={{ width: '200px' }}
              onClick={handleUploadClick}
              loading={isUploadFile}
              disabled={isUploadFile}
            >
              Загрузить
            </Button>
          </>
        }
      />

      <Paper
        radius={8}
        p={24}
        shadow='xs'
        style={{
          overflow: 'hidden',
          gap: '32px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 40px)',
          maxHeight: '100%',
        }}
      >
        <Group mb={24} justify='space-between' align='flex-end'>
          <Group justify='space-between' align='flex-end' gap='xs' grow>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                minWidth: 426,
              }}
            >
              <Text size='sm' color='grayDefault'>
                Фильтр по URL
              </Text>
              <Input
                placeholder='Введите URL'
                value={urlFilter || ''}
                onChange={onChangeUrlHandler}
              />
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                minWidth: 190,
              }}
            >
              <MultiSelect
                label='Код редиректа'
                placeholder='Тип страницы'
                data={mockOptions}
                value={codeFilter || []}
                onChange={(value) => onChangeCodeHandler(value)}
              />
            </Box>
          </Group>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 38,
              maxWidth: 'fit-content',
            }}
          >
            {isDirty && (
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: 38,
                  maxWidth: 'fit-content',
                }}
              >
                <Button
                  className={styles.resetBtn}
                  variant='subtle'
                  radius='xs'
                  size='compact-sm'
                  rightSection={<RotateClockwise size={20} />}
                  onClick={resetFilters}
                >
                  Сбросить
                </Button>
              </Box>
            )}
            <Button radius='xs' size='sm' onClick={onApplyFilters}>
              Применить фильтр
            </Button>
          </Box>
        </Group>

        {redirectList && redirectList?.length > 0 && (
          <>
            <Box style={{ position: 'relative', overflow: 'scroll' }}>
              <RedirectsTable items={redirectList} />
              <ProgressUpload isUpload={isUploadFile} progress={progressFile} />
            </Box>

            <Box
              style={{
                marginTop: 'auto',
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
                minHeight: '38px',
              }}
            >
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Text size='md' color='greyDefault'>
                  Всего:
                </Text>
                &nbsp;
                <Text size='md'>{redirectsTotal}</Text>
              </Box>
              {redirectsTotal && getPages(redirectsTotal, redirectsLimit) > 1 && (
                <Pagination
                  style={{ right: '0', position: 'absolute', justifyContent: 'flex-end' }}
                  total={getPages(redirectsTotal, redirectsLimit)}
                  onChange={(page) => {
                    dispatch(setRedirectOffset(getOffset(page, redirectsLimit)));
                  }}
                />
              )}
            </Box>
          </>
        )}

        {!redirectList?.length && (
          <Box style={{ position: 'relative', height: '100%' }}>
            {!isUploadFile && <LoadingOverlay visible={isLoadingRedirectList} />}

            {!isLoadingRedirectList && (
              <Center style={{ height: '100%' }}>
                {filterMessage ? (
                  <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <SearchIcon />
                    <Text ta='center' style={{ maxWidth: 380 }}>
                      {filterMessage}
                    </Text>
                  </Box>
                ) : (
                  <>
                    <Text ta='center'>У вас нет редирект шаблонов</Text>
                    <ProgressUpload isUpload={isUploadFile} progress={progressFile} />
                  </>
                )}
              </Center>
            )}
          </Box>
        )}
      </Paper>
      {editRedirectItem && (
        <EditRedirectModal
          isOpen={!!editRedirectItem}
          editItem={editRedirectItem}
          onChange={onEditRedirectItem}
          onClose={() => dispatch(setEditRedirectItem(null))}
        />
      )}
    </Box>
  );
};
