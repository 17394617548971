import { useCallback, useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';

/** Представляет элемент, который можно перетаскивать.*/
export interface DragItem {
  /** Индекс элемента в списке. */
  index: number;
  /** Уникальный идентификатор элемента (опционально). */
  id?: string;
  /** Тип элемента, используется в `react-dnd`. */
  type: string;
}

/** Параметры для пользовательского хука `useDnd`.*/
interface UseDndParams<T extends DragItem> {
  /** Тип перетаскиваемого элемента. */
  type: T['type'];
  /** Индекс перетаскиваемого элемента. */
  index: number;
  /** Функция обратного вызова для перемещения элемента.*/
  move?: (dragIndex: number, hoverIndex: number) => void;
  /** Отступ при расчетах (по умолчанию 0). */
  margin?: number;
}

export const useDnd = <T extends DragItem>({ type, index, move, margin = 0 }: UseDndParams<T>) => {
  const ref = useRef<HTMLDivElement>(null);

  const hover = useCallback(
    (item: T & { index: number }, monitor: DropTargetMonitor<T, void>) => {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY + margin < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY - margin > hoverMiddleY) return;

      move?.(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    [index, margin, move],
  );

  const [{ handlerId }, drop] = useDrop<T, void, { handlerId: Identifier | null }>({
    accept: type,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover,
  });

  const [{ isDragging }, drag, preview] = useDrag<T, void, { isDragging: boolean }>({
    type,
    item: { index } as T,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return { ref, handlerId, drag, preview, isDragging };
};
