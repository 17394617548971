import { Box, Group, Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';

import { formatDateWithHours } from '@/constants/common';

import { variants } from '@/ui/components/GeneralInformationCard/GeneralInformationCard.constants';
import { GeneralInformationCardTypes } from '@/ui/components/GeneralInformationCard/GeneralInformationCard.types';

export const ProjectVariant = ({
  generalId,
  author,
  editor,
  cardType,
}: GeneralInformationCardTypes) => {
  return (
    <Stack
      p={24}
      gap={12}
      style={{ borderRadius: 6, border: '1px solid #E8ECF0', background: '#F6F8F8' }}
    >
      <Text c='grayDefault'>ID: {generalId || ' - '}</Text>
      <Text mt={12} fw={500}>
        Основная информация
      </Text>
      <Group style={{ alignItems: 'start' }} justify='space-between'>
        <Text color='grayDefault'>Дата создания:</Text>
        <Text>
          {author.createDate ? dayjs(author.createDate).format(formatDateWithHours) : ' - '}
        </Text>
      </Group>
      {editor?.editDate && (
        <Group style={{ alignItems: 'end' }} justify='space-between'>
          <Text style={{ width: 50 }} color='grayDefault'>
            {variants[cardType].editDate ?? 'Дата изменения:'}
          </Text>
          <Text>{dayjs(editor.editDate).format(formatDateWithHours)}</Text>
        </Group>
      )}
      <Group style={{ alignItems: 'start', rowGap: 0 }} justify='space-between'>
        <Text c='grayDefault'>{variants[cardType].author}</Text>
        <Text style={{ maxWidth: 180, textAlign: 'right' }}>{author?.name || ' - '}</Text>
        {author?.email && (
          <Box style={{ width: '100%' }}>
            <Text color='grayDefault' style={{ marginLeft: 'auto', width: 'fit-content' }}>
              {author.email}
            </Text>
          </Box>
        )}
      </Group>
      {editor?.name && (
        <Group style={{ alignItems: 'start', rowGap: 0 }} justify='space-between'>
          <Text c='grayDefault'> Изменено:</Text>
          <Text style={{ maxWidth: 180, textAlign: 'right' }}>{editor.name}</Text>
          {editor?.email && (
            <Box style={{ width: '100%' }}>
              <Text c='grayDefault' style={{ marginLeft: 'auto', width: 'fit-content' }}>
                {editor.email}
              </Text>
            </Box>
          )}
        </Group>
      )}
    </Stack>
  );
};
