import { Box, Text } from '@mantine/core';

import { FieldModifiers } from '@/ui/containers/TemplateCreationContainer/LegendInfo/FieldModifiers/FieldModifiers';
import {
  caseChangeData,
  letterCaseData,
  numberData,
} from '@/ui/containers/TemplateCreationContainer/LegendInfo/LegendInfo.constants';
import { TextLegend } from '@/ui/containers/TemplateCreationContainer/LegendInfo/TextLegend/TextLegend';

export const LegendInfo = () => {
  return (
    <Box
      style={{
        borderRadius: '6px',
        backgroundColor: '#F6F8F8',
        border: '1px solid #E8ECF0',
        padding: '24px 16px',
        rowGap: '14px',
        columnGap: '4px',
        width: '360px',
      }}
    >
      <Text c='black' style={{ gridColumn: 'span 2', fontWeight: 700 }}>
        Легенда
      </Text>

      <Box style={{ fontSize: '14px', paddingTop: 16 }}>
        <TextLegend title='page_h1' text='заголовок страницы (выводится в самом начале страницы)' />
        <TextLegend title='title' text='заголовок страницы (верняя строка браузера' />
        <TextLegend title='desciption' text='метатег DESCRIPTION' />
        <TextLegend title='keywords' text='метатег KEYWORDS' />
        <TextLegend title='robots' text='файл robots.txt' />
        <TextLegend title='autor' text=' ФИО автора' />
        <TextLegend title='copyright' text='копирайт' />
        <TextLegend title='content' text='контент' />
      </Box>

      <Text c='black' style={{ gridColumn: 'span 2', fontWeight: 700, paddingTop: '24px' }}>
        Модификаторы полей
      </Text>
      <Box style={{ fontSize: '14px', paddingTop: 16 }}>
        {caseChangeData.map(({ id, ...restProps }) => {
          return <FieldModifiers key={id} {...restProps} />;
        })}
      </Box>
      <Box style={{ fontSize: '14px', paddingTop: 16 }}>
        {letterCaseData.map(({ id, ...restProps }) => {
          return <FieldModifiers key={id} {...restProps} />;
        })}
      </Box>
      <Box style={{ fontSize: '14px', paddingTop: 16 }}>
        {numberData.map(({ id, ...restProps }) => {
          return <FieldModifiers key={id} {...restProps} />;
        })}
      </Box>
    </Box>
  );
};
