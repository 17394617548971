import { FC } from 'react';
import { Box, Button, Modal, Text, Title } from '@mantine/core';

import { AsyncJobStatus, AsyncJobStatusPayload } from '@/modules/asyncJob';
import {
  EXPORT_MODAL_DESCRIPTION,
  EXPORT_MODAL_TITLE,
} from '@/modules/asyncJob/components/ExportModalWindow/ExportModalWindow.constants';

import styles from './ExportModalWindow.module.scss';

interface Props {
  isOpen: boolean;
  jobData: AsyncJobStatusPayload;
  onClose: () => void;
}

export const ExportModalWindow: FC<Props> = ({ jobData, isOpen, onClose }) => {
  const status: AsyncJobStatus = jobData.status;
  const title = EXPORT_MODAL_TITLE[status];
  const description = EXPORT_MODAL_DESCRIPTION[status];
  const downloadUrl = `${jobData.result?.path}`;

  const isSuccessStatus = jobData.status === 'done';

  return (
    <Modal
      centered
      size='sm'
      opened={isOpen}
      onClose={onClose}
      title={
        <Title className={styles.title} order={3}>
          {title}
        </Title>
      }
      closeOnEscape
    >
      <Box className={styles.content}>
        {description && <Text ta='center'>{description}</Text>}
        <Box className={styles.btnWrapper}>
          {isSuccessStatus && downloadUrl && (
            <Button
              className={styles.btn}
              component='a'
              target='_blank'
              download
              href={downloadUrl}
              onClick={onClose}
            >
              Скачать файл
            </Button>
          )}
          {!isSuccessStatus && (
            <Button className={styles.btn} onClick={onClose}>
              Ок
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
