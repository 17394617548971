import { DownloadSnippet, SnippetValues } from '@prosv/core/types/snippets';

import { replaceTempFile } from '@/api';

import notify from '@/utils/notify';

import { TContentData } from '@/ui/containers/ContentEditor/ContentEditor.types';
import { TViewActions, TViewState } from '@/ui/containers/ContentEditor/store/store.types';

export const parseContentData = (data?: string | void): null | TContentData => {
  if (!data) return { versionModel: 1, grid: [] };

  try {
    const parsed = JSON.parse(data);
    const isCorrectData = parsed instanceof Object && 'versionModel' in parsed && 'grid' in parsed;

    if (isCorrectData) {
      return parsed as TContentData;
    } else {
      console.log(
        'Полученные данные редактора не являются требуемым объектом. Создан новый объект',
      );
      return { versionModel: 1, grid: [] };
    }
  } catch (error) {
    console.log({ error });
    return null;
  }
};

export const getUpdateContentDataParams = (state: TViewState & TViewActions, draftId?: string) => {
  if (draftId) {
    return {
      id: draftId,
      contentData: state.savedContentDataList[draftId] as TContentData,
    };
  }

  return {
    id: state.draftId as string,
    contentData: state.savedContentData as TContentData,
  };
};

export const replaceFiles = async (values: SnippetValues<DownloadSnippet>, sessionId: string) => {
  if (values.files?.length === 0) {
    return notify({ message: 'Введите текст', type: 'error' });
  }
  const filesIds = values.files?.map(({ file }) => file?.id);

  if (filesIds && filesIds.length) {
    await replaceTempFile({
      query: {
        sessionId: sessionId,
        ids: [...filesIds] as string[],
      },
    });
  }
};
