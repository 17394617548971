import { FC } from 'react';
import { Button, Group, Modal, ModalProps, Text, TextProps, Title } from '@mantine/core';

interface IModalConfirm extends ModalProps {
  title: string;
  text: string;
  confirmHandler: () => void;
  isReverseButton?: boolean;
  disabled?: boolean;
  subtitleProps?: TextProps;
}

const ModalConfirm: FC<IModalConfirm> = ({
  title,
  text,
  confirmHandler,
  isReverseButton,
  disabled,
  subtitleProps = {},
  ...props
}) => {
  return (
    <Modal
      centered
      size={'xs'}
      styles={{
        header: {
          marginBottom: 0,
        },
      }}
      {...props}
      title=''
    >
      <Title ta='center' order={3} mb={20}>
        {title}
      </Title>
      <Text size='xs' ta='center' mb={30} {...subtitleProps}>
        {text}
      </Text>
      <Group justify='space-between' grow>
        {isReverseButton ? (
          <>
            <Button
              variant='outline'
              color='red'
              onClick={confirmHandler}
              style={{ flexGrow: 1 }}
              {...{ disabled }}
            >
              Да
            </Button>
            <Button onClick={props.onClose} style={{ flexGrow: 1 }} {...{ disabled }}>
              Нет
            </Button>
          </>
        ) : (
          <>
            <Button onClick={confirmHandler} style={{ flexGrow: 1 }} {...{ disabled }}>
              Да
            </Button>
            <Button
              variant='outline'
              color='red'
              onClick={props.onClose}
              style={{ flexGrow: 1 }}
              {...{ disabled }}
            >
              Нет
            </Button>
          </>
        )}
      </Group>
    </Modal>
  );
};

export default ModalConfirm;
