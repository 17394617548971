import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Button, Text, useMantineTheme } from '@mantine/core';
import { Pencil } from 'tabler-icons-react';
import { useDebouncedCallback } from 'use-debounce';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';
import { usePermission } from '@/hooks/usePermissions';

import { setSortDirection } from '@/ui/components/CustomTable/CustomTable.helppers';
import { ITableColumns } from '@/ui/components/CustomTable/CustomTable.types';

import {
  isProjectDirtySelector,
  projectFilterSelector,
  supportsFilterActions,
} from '@/store/slices/projects/projectsSlice';
import {
  projectDefaultFilter,
  projectTypes,
} from '@/store/slices/projects/projectsSlice.constants';
import {
  ProjectItem,
  ProjectSliceTypes,
  ReturnedProjectConfig,
} from '@/store/slices/projects/projectsSlice.types';

export const useProjectsConfig = (fetch: (params: ProjectSliceTypes) => void) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(projectFilterSelector);

  const { setFilterWithResetCurrentPage, setFilter, resetFilters } = supportsFilterActions;

  const handleSearchDebounced = useDebouncedCallback((params: ProjectSliceTypes) => {
    fetch({ ...filters, ...params });
  }, 500);

  const actionWithDebounce = (params: ProjectSliceTypes) => {
    dispatch(setFilterWithResetCurrentPage(params));
    handleSearchDebounced(params);
  };

  const isDirty = useAppSelector(isProjectDirtySelector);

  const onChange = (params: ProjectSliceTypes) => {
    dispatch(setFilter(params));
    fetch({ ...filters, ...params });
  };

  const onReset = () => {
    dispatch(resetFilters());
    fetch(projectDefaultFilter);
  };

  const onChangeSort = (sortField: string) => {
    const params = {
      sortField,
      sortDirection: setSortDirection(filters.sortDirection),
    };
    onChange({ ...projectDefaultFilter, ...params });
  };

  useEffect(() => {
    fetch(filters);
  }, []);

  return { actionWithDebounce, isDirty, onReset, onChangeSort, onChange, filters };
};

export const useProjectsColumns = (onChangeSort: ReturnedProjectConfig['onChangeSort']) => {
  const canView = usePermission('PROJECT_VIEW');
  const canUpdate = usePermission('PROJECT_UPDATE');
  const theme = useMantineTheme();

  const filters = useAppSelector(projectFilterSelector);

  const getSort = useCallback(
    (title: string) => (filters.sortField === title ? filters.sortDirection : undefined),
    [filters.sortDirection, filters.sortField],
  );

  const navigate = useNavigate();

  const columns: ITableColumns<ProjectItem>[] = useMemo(
    () => [
      {
        title: 'ID',
        field: 'uuid',
        gridWidth: 'auto',
      },
      {
        title: 'Название',
        field: 'title',
        sort: getSort('title'),
        gridWidth: '1fr',
        sortAction: () => {
          onChangeSort('title');
        },
      },
      {
        title: 'Тип',
        field: 'type',
        gridWidth: '1fr',
        render: ({ type }) => <span>{projectTypes?.[type] || ' - '}</span>,
      },
      {
        title: 'Активность',
        field: 'isActive',
        sort: getSort('isActive'),
        style: { minWidth: 200, maxWidth: 200 },

        sortAction: () => {
          onChangeSort('isActive');
        },
        render: ({ isActive }) => {
          return (
            <Badge
              color={isActive ? 'green' : 'greyLine'}
              size='lg'
              radius='sm'
              variant='filled'
              p={0}
              style={{ width: 88, marginLeft: 8 }}
            >
              <Text
                size='sm'
                c='black'
                ta='center'
                style={{ textTransform: 'none', fontWeight: 400 }}
              >
                {isActive ? 'Активно' : 'Не активно'}
              </Text>
            </Badge>
          );
        },
      },
      {
        title: '',
        gridWidth: '1fr',
        field: 'actions',
        position: 'right',
        render: ({ uuid }) =>
          canView || canUpdate ? (
            <Button
              onClick={() => {
                navigate(`/projects/${uuid}`);
              }}
              style={{ position: 'static' }}
              name='Редактировать'
              title='Редактировать'
              variant='subtle'
              size='compact-sm'
              c='gray.4'
            >
              <Pencil size={22} />
            </Button>
          ) : null,
      },
    ],
    [getSort],
  );
  return columns;
};
