import { Box, Button, Select, Text, TextInput } from '@mantine/core';
import { DatePickerInput, DatesRangeValue } from '@mantine/dates';
import { IconCalendar, IconSearch } from '@tabler/icons';
import { RotateClockwise } from 'tabler-icons-react';

import { endOfDay } from '@/utils/datetime';

import { formatDate, PAGE_ELEMENTS } from '@/constants/common';

import {
  activeStatus,
  inputStyles,
} from '@/ui/containers/UmkListContainer/components/UmkListFilters/UmkListFilters.constants';

import styles from './UmkListFilters.module.scss';

import { ReturnedUmkListConfig } from '@/store/slices/umkList/umkList.types';

export const UmkListFilters = ({
  actionWithDebounce,
  isDirty,
  onChange,
  onReset,
  filters,
  isShowResetFiltersBtn = false,
}: ReturnedUmkListConfig & { isShowResetFiltersBtn?: boolean }) => {
  const { title, isActive, createdAt, updatedAt, limit } = filters;
  const onChangeDate = (v: DatesRangeValue, key: 'createdAt' | 'updatedAt') => {
    if (!v[0] && !v[1]) {
      return onChange({ [key]: [null, null] });
    } else if (v[0] && v[1]) {
      return onChange({ [key]: [v[0], endOfDay(v[1])] });
    }
  };

  return (
    <>
      <Box
        mb={24}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <Box style={{ display: 'flex', gap: '24px', flexWrap: 'wrap' }}>
          <Box className={styles.field}>
            <Text size='sm' c='gray'>
              Название
            </Text>
            <TextInput
              value={title}
              leftSection={<IconSearch size={20} />}
              styles={inputStyles}
              onChange={(e) => actionWithDebounce({ title: e.target.value })}
              placeholder='Введите название'
            />
          </Box>
          <Box className={styles.field}>
            <Text size='sm' c='gray'>
              Активность
            </Text>
            <Select
              data={activeStatus}
              clearable
              styles={{ input: { height: 39 } }}
              placeholder='Активность'
              value={isActive}
              onChange={(value) => {
                actionWithDebounce({ isActive: value });
              }}
            />
          </Box>
          <Box className={styles.field}>
            <Text size='sm' c='gray'>
              Дата создания
            </Text>
            <DatePickerInput
              type='range'
              allowSingleDateInRange
              className={styles.datePicker}
              valueFormat={formatDate}
              locale='ru'
              leftSection={<IconCalendar size={16} />}
              placeholder='Дата создания'
              value={createdAt}
              onChange={(e) => onChangeDate(e, 'createdAt')}
            />
          </Box>
          <Box className={styles.field}>
            <Text size='sm' c='gray'>
              Дата редактирования
            </Text>
            <DatePickerInput
              type='range'
              allowSingleDateInRange
              className={styles.datePicker}
              valueFormat={formatDate}
              locale='ru'
              leftSection={<IconCalendar size={16} />}
              placeholder='Дата редактирования'
              value={updatedAt}
              onChange={(e) => onChangeDate(e, 'updatedAt')}
            />
          </Box>
        </Box>

        {isDirty && isShowResetFiltersBtn && (
          <Box
            style={{ display: 'flex', alignItems: 'center', height: 38, maxWidth: 'fit-content' }}
          >
            <Button
              className={styles.resetBtn}
              variant='subtle'
              radius='xs'
              size='compact-sm'
              rightSection={<RotateClockwise size={20} />}
              onClick={onReset}
            >
              Сбросить
            </Button>
          </Box>
        )}

        <Select
          className={styles.pageSelect}
          size='sm'
          value={limit || PAGE_ELEMENTS[0].value}
          onChange={(v) => {
            if (v === limit) return;
            actionWithDebounce({ limit: v as string });
          }}
          label=''
          data={PAGE_ELEMENTS}
        />
      </Box>
    </>
  );
};
