import React from 'react';
import { InputBase, Select } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { handleAddSnippetData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper/SelectSnippetModalWrapper.helpers';
import { useGetSnippetData } from '@/ui/containers/ContentEditor/snippets/hooks/useGetSnippetData';
import { alignmentOptions } from '@/ui/containers/ContentEditor/snippets/ImageSnippetModal/ImageSnippetModal.constants';
import { ELinkButtonModal } from '@/ui/containers/ContentEditor/snippets/LinkButtonSnippetModal/LinkButtonModal.constants';
import { getLinkButtonSnippetInitialValues } from '@/ui/containers/ContentEditor/snippets/LinkButtonSnippetModal/LinkButtonModal.helpers';
import { linkBtnSchema } from '@/ui/containers/ContentEditor/snippets/LinkButtonSnippetModal/LinkButtonModal.schema';
import { SnippetModal } from '@/ui/containers/ContentEditor/snippets/SnippetModal';

const LinkButtonModalUI: React.FC = () => {
  const { defaultValues, snippetData, handleClose } = useGetSnippetData('link-btn');

  const form = useForm({
    initialValues: getLinkButtonSnippetInitialValues(defaultValues),
    validate: yupResolver(linkBtnSchema),
  });

  return (
    <SnippetModal
      isOpen
      isNew={snippetData?.isNew}
      onClose={handleClose}
      title='Кнопка с ссылкой'
      handleClickBackBtn={handleClose}
      onSubmit={form.onSubmit((values) =>
        handleAddSnippetData('link-btn', values, handleClose, snippetData),
      )}
    >
      <InputBase
        withAsterisk
        style={{ width: '100%', gridColumn: 'span 6' }}
        label='Название кнопки'
        placeholder='Введите название'
        {...form.getInputProps(ELinkButtonModal.NAME)}
      />
      <InputBase
        withAsterisk
        style={{ width: '100%', gridColumn: 'span 6' }}
        label='Ссылка'
        placeholder='Введите ссылку'
        {...form.getInputProps(ELinkButtonModal.LINK)}
      />

      <Select
        withAsterisk
        style={{ gridColumn: 'span 6' }}
        data={alignmentOptions}
        label='Выравнивание'
        placeholder='Выравнивание'
        {...form.getInputProps(ELinkButtonModal.ALIGNMENT)}
      />
    </SnippetModal>
  );
};

export const LinkButtonSnippetModal = React.memo(LinkButtonModalUI);
