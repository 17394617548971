import { FC } from 'react';
import { Select } from '@mantine/core';

import { useCategoriesOptions } from './CategorySelect.hooks';
import { CategorySelectProps } from './CategorySelect.types';
import { CategorySelectItem, CategorySelectItemProps } from './CategorySelectItem';

export const CategorySelect: FC<CategorySelectProps> = ({ disabled, ...props }) => {
  const data = useCategoriesOptions();

  return (
    <Select
      data={data}
      renderOption={({ option }) => <CategorySelectItem {...(option as CategorySelectItemProps)} />}
      disabled={disabled || !data.length}
      {...props}
    />
  );
};
