import { IGetBannerListResponse } from '@/types/banner/bannerDetail';

import { useCallback, useEffect, useState } from 'react';
import { Select } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useDebouncedCallback } from 'use-debounce';

import { useGetBanners } from '@/ui/containers/BannerCreationContainer/BannerCreationContainer.hooks';
import { Loader } from '@/ui/containers/ContentEditor/Loader';
import { handleAddSnippetData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper';
import { EBannerSnippet } from '@/ui/containers/ContentEditor/snippets/BannerSnippet/BannerSnippet.constants';
import { bannerSchema } from '@/ui/containers/ContentEditor/snippets/BannerSnippet/BannerSnippet.schema';
import { useGetSnippetData } from '@/ui/containers/ContentEditor/snippets/hooks/useGetSnippetData';
import { SnippetModal } from '@/ui/containers/ContentEditor/snippets/SnippetModal';
import { getConvertAndFilteredBanners } from '@/ui/containers/PromotionCreation/components/PromotionDetailInfo/PromotionDetailInfo.helper';
import { IBannerSelectorItem } from '@/ui/containers/PromotionCreation/PromotionCreation.types';

import { getBannerSnippetInitialValues } from './BannerSnippet.helpers';

export const BannerSnippet = () => {
  const { getBanners, isLoading } = useGetBanners();
  const [banners, setBanners] = useState<IBannerSelectorItem[]>();
  const { defaultValues, snippetData, handleClose } = useGetSnippetData('banner');

  const form = useForm({
    initialValues: getBannerSnippetInitialValues(defaultValues),
    validate: yupResolver(bannerSchema),
  });

  const handleSearch = useCallback(
    async (search: string) => {
      const { payload } = (await getBanners({ search })) as IGetBannerListResponse;
      if (!!payload && !!payload?.items?.length) {
        const convertedBanners = getConvertAndFilteredBanners(payload.items);
        setBanners(convertedBanners);
      }
    },
    [getBanners],
  );

  const searchChange = useDebouncedCallback((value: string) => handleSearch(value), 500);

  useEffect(() => {
    getBanners({}).then((bannersResponse) => {
      if (bannersResponse) {
        setBanners(getConvertAndFilteredBanners(bannersResponse.payload.items));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SnippetModal
      isOpen
      isNew={snippetData?.isNew}
      onClose={handleClose}
      title='Баннер'
      handleClickBackBtn={handleClose}
      onSubmit={form.onSubmit((values) =>
        handleAddSnippetData('banner', values, handleClose, snippetData),
      )}
    >
      <Loader loading={isLoading} blur />
      <Select
        withAsterisk
        style={{ width: '100%', gridColumn: 'span 6' }}
        data={banners || []}
        label='Баннер'
        onSearchChange={searchChange}
        searchable
        placeholder='Выберите из списка'
        {...form.getInputProps(EBannerSnippet.BANNER_ID)}
      />
    </SnippetModal>
  );
};
