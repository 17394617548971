import { useEffect, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import { supportsInitialState } from '@/pages/Support/SupportsListing.constants';
import {
  CreateFilterSlice,
  isDirtySupportFilter,
  supportsFilterActions,
  supportsFiltersSelector,
  useGetCategoryTreatmentQuery,
} from '@/store/slices/support/supportDetailSlice';

export const useSupportFilterConfig = (request: (params: Partial<CreateFilterSlice>) => void) => {
  const { resetFilters, setFilterWithResetCurrentPage, setFilter } = supportsFilterActions;

  const { data } = useGetCategoryTreatmentQuery();

  const categoryOptions = useMemo(
    () => (data?.payload.items || []).map((item) => ({ label: item.name, value: item.code })),
    [data?.payload.items],
  );
  const filter = useAppSelector(supportsFiltersSelector);
  const isDirty = useAppSelector(isDirtySupportFilter);

  const dispatch = useAppDispatch();

  const handleSearchDebounced = useDebouncedCallback((params: Partial<CreateFilterSlice>) => {
    request({ ...filter, ...params });
  }, 300);

  const actionWithDebounce = (params: Partial<CreateFilterSlice>) => {
    dispatch(setFilterWithResetCurrentPage(params));
    handleSearchDebounced(params);
  };

  const onChangePage = (params: Partial<CreateFilterSlice>) => {
    dispatch(setFilter(params));
    handleSearchDebounced(params);
  };

  const handleSerActive = (v: string) => {
    actionWithDebounce({ isActive: v === filter.isActive ? undefined : v });
  };

  const onReset = () => {
    dispatch(resetFilters());
    request(supportsInitialState);
  };

  useEffect(() => {
    request({ ...supportsInitialState, ...filter });
  }, []);

  return {
    onReset,
    handleSerActive,
    onChangePage,
    isDirty,
    filter,
    categoryOptions,
    actionWithDebounce,
  };
};
