import { Paper, Text } from '@mantine/core';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import { CustomTable } from '@/ui/components/CustomTable/CustomTable';
import { ReviewAndQuestionsFilter } from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter';
import { ReviewAndQuestionsQueryParams } from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.types';
import {
  useProductsTab,
  useQuestionsConfig,
} from '@/ui/containers/Questions/listing/ProductsTab/ProductsTab.hooks';

import {
  questionProductsFilterSelector,
  questionsProductsFilterActions,
  useLazyGetQuestionsProductsListQuery,
} from '@/store/slices/questions/questionsSlice';

export const ProductsTab = () => {
  const dispatch = useAppDispatch();

  const [get, data] = useLazyGetQuestionsProductsListQuery();
  const filterProps = useQuestionsConfig(get);
  const filter = useAppSelector(questionProductsFilterSelector);
  const { setFilter } = questionsProductsFilterActions;
  const { columns } = useProductsTab(filterProps.onChange);

  const handleChange = (value: Partial<ReviewAndQuestionsQueryParams>) => {
    dispatch(setFilter(value));
    filterProps.onChange(value);
  };

  return (
    <Paper
      radius={8}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '90%',
        overflow: 'hidden',
      }}
    >
      <ReviewAndQuestionsFilter {...filterProps} />
      <CustomTable
        limit={Number(filter.limit)}
        noData={
          data.isError ? (
            <Text style={{ color: 'red' }}>Произошла ошибка , попробуйте пожалуйста снова.</Text>
          ) : (
            'К сожалению, данный раздел пуст.'
          )
        }
        total={data.data?.payload.meta.total}
        currentPage={filter.currentPage}
        status={data.isFetching ? 'loading' : 'loaded'}
        data={data.data?.payload.items || []}
        columns={columns}
        onChangePagination={(v) => handleChange({ currentPage: v })}
      />
    </Paper>
  );
};
