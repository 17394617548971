import { IPromotionCreationData } from '@/types/promotions';

import dayjs from 'dayjs';

import {
  ACTIVITY_FIELD_VALUES,
  DATE_TEMPLATE,
  EActivityFieldValues,
  EMainFieldValues,
  EPromotionFormFields,
  EVisibilityFieldValues,
  MAIN_PROMOTION_FIELD_VALUES,
  VISIBILITY_DELIVERY_FIELD_VALUES,
} from '@/ui/containers/PromotionCreation/components/PromotionForm/PromotionForm.constants';
import { IPromotionFormFields } from '@/ui/containers/PromotionCreation/components/PromotionForm/PromotionForm.types';

export const getBreadcrumbs = (title: string) => [
  {
    name: 'Акции',
    url: '/promotions',
  },
  {
    name: title,
  },
];

export const dateFormToPromotionDate = (date: null | Date, template: string) => {
  if (!date) return '';
  return dayjs(date).format(template);
};

export const formFieldsToCreate = (
  formFields: IPromotionFormFields,
  draftId: string,
): IPromotionCreationData => {
  const active = formFields[EPromotionFormFields.IS_ACTIVE] as EActivityFieldValues;
  const isMain = formFields[EPromotionFormFields.IS_MAIN] as EMainFieldValues;
  const isShowDelivery = formFields[
    EPromotionFormFields.IS_SHOW_DELIVERY
  ] as EVisibilityFieldValues;
  const dateActiveStart = dateFormToPromotionDate(
    formFields[EPromotionFormFields.DATE_ACTIVE_START],
    DATE_TEMPLATE,
  );
  const dateActiveEnd = dateFormToPromotionDate(
    formFields[EPromotionFormFields.DATE_ACTIVE_END],
    DATE_TEMPLATE,
  );

  return {
    ...formFields,
    draftId,
    isMain: MAIN_PROMOTION_FIELD_VALUES[isMain],
    isShowDelivery: VISIBILITY_DELIVERY_FIELD_VALUES[isShowDelivery],
    isActive: ACTIVITY_FIELD_VALUES[active],
    dateActiveStart,
    dateActiveEnd,
  };
};
