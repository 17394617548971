import React, { FC } from 'react';
import { Modal, Tabs, Text } from '@mantine/core';

import {
  selectContentBlockDataSelector,
  setSelectContentBlockDataSelector,
  setSnippetDataSelector,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';

import { NewsCards } from './NewsCards';

const SelectContentBlockModalUI: FC = () => {
  const setSnippetData = useStoreContentEditor(setSnippetDataSelector);
  const selectContentBlockData = useStoreContentEditor(selectContentBlockDataSelector);
  const setSelectContentBlockData = useStoreContentEditor(setSelectContentBlockDataSelector);

  const handleClose = () => {
    setSelectContentBlockData(null);
    setSnippetData(null);
  };

  return (
    <Modal
      opened={!!selectContentBlockData}
      onClose={handleClose}
      title={
        <Text fz={24} ta='left' fw={600}>
          Выбор блока
        </Text>
      }
      size={1300}
      withCloseButton
      // overflow='inside'
    >
      <Tabs color='gray' variant='pills' orientation='vertical' defaultValue='news' pt={4}>
        <Tabs.List>
          <Tabs.Tab value='news' style={{ width: 200, backgroundColor: '#F8F9FA !important' }}>
            <Text size='sm' ta='left' fw={400} c='blue'>
              Новости
            </Text>
          </Tabs.Tab>
          <Tabs.Tab value='materials' style={{ width: 200 }} disabled>
            <Text size='sm' ta='left' fw={400} c='greyDark'>
              Материалы
            </Text>
          </Tabs.Tab>
          <Tabs.Tab value='landings' style={{ width: 200 }} disabled>
            <Text size='sm' ta='left' fw={400} c='greyDark'>
              Лендинги
            </Text>
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value='news' pl='xs'>
          <NewsCards />
        </Tabs.Panel>

        <Tabs.Panel value='materials' pl='xs'>
          {null}
        </Tabs.Panel>

        <Tabs.Panel value='landings' pl='xs'>
          {null}
        </Tabs.Panel>
      </Tabs>
    </Modal>
  );
};

export const SelectContentBlockModal = React.memo(SelectContentBlockModalUI);
