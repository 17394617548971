import { ReactNode } from 'react';
import { Center, Group, UnstyledButton, useMantineTheme } from '@mantine/core';
import { ChevronDown, ChevronUp, Selector } from 'tabler-icons-react';

import { TableColumnTitle } from '@/ui/components/TableExt/components/TableColumnTitle';

export const SortHeader = ({
  children,
  onSort,
  sorted,
  reversed,
}: {
  children: ReactNode;
  onSort: () => void;
  sorted: boolean;
  reversed: boolean;
}) => {
  const Icon = sorted ? (reversed ? ChevronUp : ChevronDown) : Selector;
  const theme = useMantineTheme();

  return (
    <UnstyledButton onClick={onSort}>
      <Group
        gap='xs'
        style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'nowrap', gap: 6 }}
      >
        <Center>
          <Icon size={20} color={theme.colors.greyDefault[9]} />
        </Center>
        <TableColumnTitle>{children}</TableColumnTitle>
      </Group>
    </UnstyledButton>
  );
};
