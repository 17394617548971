import { ItemPermissions } from '@/types/auth/permissions';
import { IBanner, TNullable } from '@/types/banner/banner';

import { FC } from 'react';
import { Box, Text } from '@mantine/core';

import { getDate } from '@/ui/containers/NewsDetailInfo/NewsGeneralInfo/NewsGeneralInfo.helpers';

interface IProps {
  isNewBanner: boolean;
  banner: TNullable<IBanner>;
  views: number;
  clicks: number;
  permissions: ItemPermissions;
}

export const BannerGeneralInfo: FC<IProps> = ({ isNewBanner, banner, clicks, views }) => {
  const createdDate = banner?.meta?.createdAt?.value;
  const editDate = banner?.meta?.updatedAt?.value;

  return (
    <Box
      style={{
        borderRadius: '6px',
        backgroundColor: '#F6F8F8',
        border: '1px solid #E8ECF0',
        padding: '16px 24px',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        rowGap: '14px',
        columnGap: '4px',
        maxWidth: '320px',
        alignItems: 'baseline',
      }}
    >
      {!isNewBanner && (
        <>
          <Text color='greyDark' style={{ gridColumn: 'span 2' }}>
            ID баннера: {banner?.uuid}
          </Text>

          <Text color='black' style={{ gridColumn: 'span 2', fontWeight: 600, paddingTop: '14px' }}>
            Основная информация
          </Text>

          {createdDate && (
            <>
              <Text color='greyDark' style={{ paddingTop: '6px' }}>
                Дата создания:
              </Text>
              <Text style={{ textAlign: 'right' }}>
                {getDate(banner?.meta.createdAt?.value || '')}
              </Text>
            </>
          )}

          {editDate && (
            <>
              <Text color='greyDark' style={{ paddingTop: '6px' }}>
                Дата изменения:
              </Text>
              <Text style={{ textAlign: 'right' }}>
                {getDate(banner?.meta.updatedAt?.value || '')}
              </Text>
            </>
          )}

          <Text color='greyDark'>Автор:</Text>
          <Text style={{ textAlign: 'right' }}>{banner?.meta.authorFullName}</Text>

          {banner?.meta?.editorFullName && (
            <>
              <Text color='greyDark'>Изменено:</Text>
              <Text style={{ textAlign: 'right' }}>{banner?.meta.editorFullName}</Text>{' '}
            </>
          )}
        </>
      )}
      <Text color='black' style={{ fontWeight: 600 }}>
        Показы
      </Text>
      <Text style={{ textAlign: 'right' }}>{views}</Text>

      <Text color='black' style={{ fontWeight: 600 }}>
        Переходы
      </Text>
      <Text style={{ textAlign: 'right' }}>{clicks}</Text>
    </Box>
  );
};
