import { FC, useEffect, useMemo, useState } from 'react';
import { Box, Button, Group, MultiSelect, Select, TextInput, TextInputProps } from '@mantine/core';
import { SelectionsPlacesRequest } from '@prosv/core/types/bff/catalog/admin';
import { RotateClockwise } from 'tabler-icons-react';
import { useDebouncedCallback } from 'use-debounce';

import { PAGE_ELEMENTS } from '@/constants/common';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import { activityOptions } from './SelectionsPlacesFilters.constants';

import styles from './SelectionsPlacesFilters.module.scss';

import { useDictionaryOptions } from '@/store/slices/dictionary';
import {
  getSelectionsPlacesFilters,
  selectionsPlacesFiltersActions,
} from '@/store/slices/selections';

export const SelectionsPlacesFilters: FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(getSelectionsPlacesFilters);
  const { changeFilters, resetFilters } = selectionsPlacesFiltersActions;

  const { data: pagesOptions = [] } = useDictionaryOptions('selectionpage', 'code');

  const [state, setState] = useState(filters);

  useEffect(() => {
    setState(filters);
  }, [filters]);

  const selectedPages = useMemo<string[]>(
    () =>
      (state.pages ?? '')
        .split(',')
        .map((v) => v.trim())
        .filter((v) => !!v),
    [state.pages],
  );

  const activityValue = useMemo(
    () =>
      typeof filters.isActive === 'boolean'
        ? activityOptions[filters.isActive ? 0 : 1].value
        : null,
    [filters.isActive],
  );

  const updateFiltersDebounced = useDebouncedCallback(
    (newFilters: SelectionsPlacesRequest) => dispatch(changeFilters(newFilters)),
    300,
  );

  const updateState = (newState: SelectionsPlacesRequest) => {
    setState((oldState) => {
      newState = { ...oldState, ...newState };
      updateFiltersDebounced(newState);
      return newState;
    });
  };

  const handleSelectionChange: TextInputProps['onChange'] = ({ target }) => {
    updateState({ selection: target.value });
  };

  const handlePageSizeChange = (v: string | null) => {
    updateState({ offset: 0, limit: parseInt(v ?? PAGE_ELEMENTS[0].value) });
  };

  return (
    <Group style={{ zIndex: 5 }} mb={24} justify='flex-start' align='flex-end' gap='xl' grow>
      <Box className={styles.field}>
        <TextInput
          label='Выберите подборку'
          placeholder='Название'
          value={state.selection ?? ''}
          className={styles.textInput}
          onChange={handleSelectionChange}
        />
      </Box>
      <Box className={cn(styles.field, styles.big)}>
        <MultiSelect
          clearable
          label='Расположение'
          placeholder='Расположение'
          value={selectedPages}
          data={pagesOptions}
          onChange={(v) => updateState({ pages: (Array.isArray(v) ? v : [v]).join(',') })}
          height={40}
        />
      </Box>
      <Box className={styles.field}>
        <Select
          clearable
          label='Активность'
          placeholder='Активность'
          data={activityOptions}
          value={activityValue}
          onChange={(v) => {
            const value = v === 'true';
            updateState({ isActive: v === null ? v : value === state.isActive ? null : value });
          }}
          height={40}
        />
      </Box>

      <Box style={{ display: 'flex', alignItems: 'center', height: 38, maxWidth: 'fit-content' }}>
        <Button
          className={styles.resetBtn}
          variant='subtle'
          radius='xs'
          size='compact-sm'
          rightSection={<RotateClockwise size={20} />}
          onClick={() => dispatch(resetFilters())}
        >
          Сбросить
        </Button>
      </Box>

      <div style={{ display: 'flex', marginLeft: 'auto', justifyContent: 'end' }}>
        <Box className={styles.pageSize}>
          <Select
            className={styles.select}
            size='sm'
            value={`${filters.limit || PAGE_ELEMENTS[0].value}`}
            onChange={handlePageSizeChange}
            allowDeselect
            data={PAGE_ELEMENTS}
          />
        </Box>
      </div>
    </Group>
  );
};
