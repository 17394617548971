import { FC } from 'react';
import { Burger, Group, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { useHover } from '@mantine/hooks';

import styles from './LayoutNavbarBurger.module.scss';

interface ILayoutNavbarBurger {
  opened: boolean;
  onClick?: () => void;
}

export const LayoutNavbarBurger: FC<ILayoutNavbarBurger> = ({ opened, onClick }) => {
  const title = opened ? 'Свернуть' : 'Развернуть';
  const { hovered, ref } = useHover();
  const theme = useMantineTheme();

  return (
    <div ref={ref}>
      <Group gap={10} wrap='nowrap'>
        <Burger
          color={hovered ? theme.colors.primary[7] : theme.colors.greyLight[9]}
          opened={opened}
          title={title}
          size='sm'
          onClick={onClick}
          // style={{ padding: opened ? 0 : 2 }}
        />
        <UnstyledButton title={title} className={styles.root} py={12} onClick={onClick}>
          {opened && (
            <Text lineClamp={1} style={{ lineHeight: 1.125, paddingBottom: '2px' }} c='greyDark'>
              {title}
            </Text>
          )}
        </UnstyledButton>
      </Group>
    </div>
  );
};
