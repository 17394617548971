import { IPositionItem } from '@/types/banner/banner';

import { PermissionInfoItem } from '@prosv/core/src/lib/types/bff/base/permitions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '@/api/instance';
import { URLS } from '@/api/urls';

import { getUrlWithSearchParams } from '@/utils/getUrlWithSearchParams';

import { bannersInitialState } from '@/pages/Banners/BannersListing/BannerListing.constants';
import { SortDirection } from '@/store/slices/promotions/promotions.types';

export interface CreateFilterSlice {
  currentPage?: number;
  limit?: string;
  query?: string;
  page?: string[];
  place?: string[];
  site?: string[];
  isActive?: string | null;
  sortField?: string;
  sortDirection?: SortDirection;
}
export const createTableFilterSlice = <T extends CreateFilterSlice>({
  name,
  initialState,
}: {
  name: string;
  initialState: T;
}) =>
  createSlice({
    name,
    initialState,
    reducers: {
      fullReset: () => initialState,
      resetFilters: (state) => ({ ...initialState, limit: state.limit }),
      goToPage: (state, action: PayloadAction<number>) => {
        state.currentPage = action.payload;
      },
      nextPage: (state) => {
        if (typeof state.currentPage === 'number') {
          state.currentPage++;
        }
      },
      previousPage: (state) => {
        if (typeof state.currentPage === 'number') {
          state.currentPage--;
        }
      },
      setPageSize: (state, action: PayloadAction<string>) => {
        state.limit = action.payload;
      },
      setFilterWithResetCurrentPage: (state, { payload }: PayloadAction<Partial<T>>) => ({
        ...state,
        ...payload,
        currentPage: 1,
      }),
      setFilter: (state, { payload }: PayloadAction<T>) => ({ ...state, ...payload }),
    },
  });

export const bannersFilterSlice = createTableFilterSlice<CreateFilterSlice>({
  name: 'bannersFilter',
  initialState: bannersInitialState,
});

export const bannersFilterActions = bannersFilterSlice.actions;

export const bannersApi = createApi({
  reducerPath: 'api/banners',
  baseQuery: axiosBaseQuery(),
  tagTypes: [],
  endpoints: (build) => ({
    getBannersListing: build.query<IBannerListResponse, CreateFilterSlice>({
      query: (params) => {
        const { limit, currentPage, query, ...rest } = params;

        const newParams = {
          offset: currentPage === 1 ? 0 : Number(limit) * (Number(currentPage) - 1),
          limit,
          query,
          place: rest.place,
          site: rest.site,
          isActive: rest.isActive,
          page: rest.page,
          sortField: rest.sortField,
          sortDirection: rest.sortDirection,
        };

        return {
          method: 'get',
          url: getUrlWithSearchParams(URLS.banners.listing, newParams, { arrayFormat: 'index' }),
        };
      },
    }),
  }),
});

export const { useLazyGetBannersListingQuery } = bannersApi;
export interface IBannerListResponse {
  code: string;
  message: string;
  payload: {
    meta: { total: number };
    items: IBannerResponse[];
  };
}
export interface IBannerResponse {
  background: string;
  bannerLink: string;
  buttonLink: string;
  buttonText: string;
  description: string;
  desktopImage?: string;
  isActive: boolean;
  isShare: boolean;
  logo1Image?: string;
  logo2Image?: string;
  logo3Image?: string;
  mobileImage: null;
  numberOfClicksBeforeHiding: 633;
  numberOfViewsBeforeHiding: 350;
  positions: IPositionItem[];
  promocode: string;
  site: string;
  sort: number;
  subTitle: string;
  tabletImage: null;
  title: string;
  uuid: string;
  dateActiveFrom: {
    value: string;
  };
  dateActiveTo: {
    value: string;
  };
  permissions: PermissionInfoItem[];
  meta: {
    authorFullName: string;
    authorId: string;
    createdAt: { value: string };
    value: string;
    editorFullName: null;
    editorId: null;
    updatedAt: null;
  };
}
