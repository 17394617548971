import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, useMantineTheme } from '@mantine/core';
import dayjs from 'dayjs';
import { Pencil } from 'tabler-icons-react';
import { useDebouncedCallback } from 'use-debounce';

import { formatDate } from '@/constants/common';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import { SupportStatusCell } from '@/ui/components/CustomTable/columns/SupportStatusCell';
import { setSortDirection } from '@/ui/components/CustomTable/CustomTable.helppers';
import { ITableColumns } from '@/ui/components/CustomTable/CustomTable.types';
import { reviewAndQuestionInitialFilter } from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.consts';
import { ReviewAndQuestionsQueryParams } from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.types';
import { ReviewProductItem } from '@/ui/containers/Reviews/listing/tabs/ProductsTab/ReviewProductsTab.types';

import { ReactComponent as StarIcon } from '@/assets/icons/star.svg';
import {
  isDirtyReviewsProductsFilterSelector,
  reviewsProductsFilterActions,
  reviewsProductsFilterSelector,
} from '@/store/slices/reviews/reviewsSlice';

export const useReviewsProductsTab = (
  action: (params: Partial<ReviewAndQuestionsQueryParams>) => void,
) => {
  const filter = useAppSelector(reviewsProductsFilterSelector);
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const handleSetSort = (type: string) => {
    const params = {
      sortField: type,
      sortDirection: setSortDirection(filter.sortDirection),
    };
    action(params);
  };

  const columns: ITableColumns<ReviewProductItem>[] = [
    {
      title: 'ID',
      field: 'id',
      style: {
        minWidth: 200,
        maxWidth: 200,
      },
    },
    {
      title: 'Статус',
      sort: filter.sortField === 'status' ? filter.sortDirection : undefined,
      sortAction: () => handleSetSort('status'),
      field: 'status',
      render: ({ status }) => <SupportStatusCell status={status} />,
    },
    {
      title: 'Артикул',
      field: 'article',
      render: ({ entity }) => <>{entity?.article || ' - '}</>,
    },
    {
      title: 'Название',
      field: 'name',
      style: { width: 200 },
      render: (data) => (
        <>
          {data.entity?.name ? (
            <a
              target='_blank'
              href={`${process.env.PREVIEW_URL}/product/${data.entity.code || ''}`}
              className='line-clamp-2'
              style={{ cursor: 'pointer', color: '#322FC6', textDecoration: 'none' }}
            >
              {data.entity?.name || ' - '}
            </a>
          ) : (
            ' - '
          )}
        </>
      ),
    },
    {
      title: 'Оценка',
      sort: filter.sortField === 'rating' ? filter.sortDirection : undefined,
      sortAction: () => handleSetSort('rating'),
      field: 'rating',
      render: ({ rating }) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
          <StarIcon style={{ color: '#F2C94C' }} width={14} height={14} /> {rating}
        </div>
      ),
    },
    {
      title: 'Текст отзыва',
      field: 'text',
    },
    {
      title: 'Ответ',
      field: 'hasReply',
      sort: filter.sortField === 'hasReply' ? filter.sortDirection : undefined,
      sortAction: () => handleSetSort('hasReply'),
      render: ({ hasReply }) => <>{hasReply ? 'Да' : 'Нет'}</>,
    },

    {
      title: 'Создан',
      sort: filter.sortField === 'dateCreate' ? filter.sortDirection : undefined,
      sortAction: () => handleSetSort('dateCreate'),
      field: 'dateCreate',
      render: ({ createDate }) => dayjs(createDate).format(formatDate),
    },
    {
      title: '',
      field: 'action',
      style: { width: 80 },
      render: ({ id }) => (
        <Button
          name='Редактировать'
          title='Редактировать'
          variant='subtle'
          style={{ position: 'static' }}
          size='compact-sm'
          onClick={() => navigate(`/reviews/${id}`)}
        >
          <Pencil size={22} color={theme.colors.greyLight[9]} />
        </Button>
      ),
    },
  ];
  return { columns };
};

export const useReviewProductConfig = (
  getReviewsList: (params: ReviewAndQuestionsQueryParams) => void,
) => {
  const filter = useAppSelector(reviewsProductsFilterSelector);

  const { resetFilters, setFilter, setFilterWithResetCurrentPage } = reviewsProductsFilterActions;
  const dispatch = useAppDispatch();

  const handleSearchDebounced = useDebouncedCallback(
    (params: { article?: string; id?: string }) => {
      getReviewsList({ ...filter, ...params });
    },

    500,
  );

  const actionWithDebounce = (params: { article?: string; id?: string }) => {
    dispatch(setFilterWithResetCurrentPage(params));
    handleSearchDebounced(params);
  };

  const isDirty = useAppSelector(isDirtyReviewsProductsFilterSelector);

  const onChange = (params: Partial<ReviewAndQuestionsQueryParams>) => {
    dispatch(setFilter(params));
    getReviewsList({ ...filter, ...params });
  };

  const onReset = () => {
    dispatch(resetFilters());
    getReviewsList(reviewAndQuestionInitialFilter);
  };

  useEffect(() => {
    getReviewsList(filter);
  }, []);

  return {
    isDirty,
    ...filter,
    onReset,
    onChange,
    actionWithDebounce,
  };
};
