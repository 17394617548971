import { FC } from 'react';
import { unstable_useBlocker as useBlocker, useParams } from 'react-router-dom';
import { Box, LoadingOverlay, Paper, Tabs } from '@mantine/core';

import { useModeMaterials } from '@/api/materials/hooks/useModeMaterialsContainer';

import ModalConfirm from '@/ui/components/ModalConfirm/ModalConfirm';
import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import { ContentEditor } from '@/ui/containers/ContentEditor';
import { MaterialDetailInfo } from '@/ui/containers/MaterialDetailInfo';
import { useMaterialsActions } from '@/ui/containers/MaterialDetailInfo/MaterialDetailInfo.hooks';
import { ARTICLE_CREATION_FORM_ID } from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialsForm.constants';
import { useMaterialState } from '@/ui/containers/MaterialsCreationContainer/UseMaterialState';
import { ActionButtons } from '@/ui/containers/NewsCreationContainer/ActionButtons';

export const MaterialCreationContainer: FC = () => {
  const { id } = useParams();
  const materialsState = useMaterialState(id);
  const { handleSubmit, handleDelete, closeDeleteModal, openDeleteModal } = useMaterialsActions(
    materialsState,
    id || '',
  );
  const { state } = useBlocker(materialsState.isShouldBlock);
  useModeMaterials(materialsState, id);

  const onTrySave = () => {
    materialsState.setTrySaveMaterials(true);
  };

  if (!materialsState.permissions.canView) {
    return <div>Недостаточно прав.</div>;
  }

  return (
    <Box
      style={{
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PageHeader
        breadcrumbs={materialsState.breadcrumbs}
        title={materialsState.title}
        rightButton={
          <ActionButtons
            permissions={materialsState.permissions}
            isNewNews={materialsState.isNewMaterial}
            handleDelete={openDeleteModal}
            onSave={onTrySave}
            formID={ARTICLE_CREATION_FORM_ID}
          />
        }
      />
      <Paper
        radius={8}
        p={24}
        shadow='xs'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <LoadingOverlay visible={materialsState.isLoading} />
        <Tabs color='teal' defaultValue='first' style={{ height: '100%', position: 'relative' }}>
          <Tabs.List style={{ borderBottom: 'none' }}>
            <Tabs.Tab value='first' color='primary'>
              Общее
            </Tabs.Tab>
            {materialsState.permissions.canUpdate && (
              <Tabs.Tab value='second' color='primary'>
                Редактор страницы
              </Tabs.Tab>
            )}
          </Tabs.List>

          <Tabs.Panel value='first' pt='xs' style={{ padding: '24px 0 34px 0', height: 'inherit' }}>
            {materialsState.isShowMaterialsDetail && (
              <MaterialDetailInfo
                permissions={materialsState.permissions}
                material={materialsState.material}
                sessionID={materialsState.sessionID.current}
                isNewMaterial={materialsState.isNewMaterial}
                handleSubmit={handleSubmit}
                setIsShouldBlock={() => {}}
                defaultFormData={materialsState.defaultFormData}
              />
            )}
          </Tabs.Panel>

          <Tabs.Panel
            value='second'
            pt='xs'
            style={{ padding: '24px 0 34px 0', height: 'inherit' }}
          >
            <ContentEditor />
          </Tabs.Panel>
        </Tabs>

        {state === 'blocked' && (
          <ModalConfirm
            title={'EAddNewsConfirmationTexts.OUT_TITLE'}
            text={'EAddNewsConfirmationTexts.NOT_SAVE_MENU_SITE'}
            confirmHandler={() => {}}
            opened={state === 'blocked'}
            onClose={() => {}}
          />
        )}
        <ModalConfirm
          title='Удалить ?'
          text='Материал будет удалён. Восстановить данные после удаления не получится.'
          confirmHandler={handleDelete}
          opened={materialsState.isDeleteModalOpen}
          onClose={closeDeleteModal}
        />
      </Paper>
    </Box>
  );
};
