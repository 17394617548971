import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { Button, ButtonProps } from '@mantine/core';

export const DropDownItem: React.FC<PropsWithChildren<ButtonHTMLAttributes<{}> & ButtonProps>> = ({
  children,
  ...restProps
}) => {
  return (
    <Button
      {...restProps}
      variant='subtle'
      size='md'
      style={{
        paddingLeft: 16,
        height: 40,
        width: '100%',
        fontWeight: 400,
        textAlign: 'start',
        color: '#212529',
        '*': {
          justifyContent: 'flex-start',
        },
        ':hover': {
          color: 'white',
        },
      }}
    >
      {children}
    </Button>
  );
};
