import { ILoadingStatus } from '@/types';

import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { tryGetMaterialList } from '@/api/materials/materials';

import { LIMIT, OFFSET, PAGE } from '@/constants/common';

import {
  IMaterialsFilter,
  IMaterialsFilterRequest,
} from '@/ui/containers/MaterialsContainer/Materials.types';
import { MaterialsModalsEnum } from '@/ui/containers/MaterialsContainer/MaterialsContainer.constants';
import {
  DATE_TEMPLATE_DAYS,
  DATE_TEMPLATE_HOURS,
} from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.constants';
import { dateFormToNewsDate } from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.helpers';

import { IRootReducer } from '@/store';

const initialState: IInitialState = {
  modals: {
    currentModal: null,
    currentId: '',
    isActive: true,
  },
  materials: {
    data: [],
    total: 0,
    status: 'init',
  },
  filter: {
    query: '',
    page: PAGE,
    limit: LIMIT,
    offset: OFFSET,
    sortField: 'id',
    sortDirection: 'desc',
    subject: [],
    author: [],
    type: [],
    lineUmk: [],
    theme: [],
    digitalService: [],
    typeOfControl: [],
    age: [],
    createDateFrom: null,
    createDateTo: null,
    direction: [],
    readerRole: [],
    educationLevel: [],
  },
};

export interface IInitialState {
  modals: {
    currentModal: MaterialsModalsEnum | null;
    currentId: string;
    isActive: boolean;
  };
  materials: {
    data: any[];
    total: number;
    status: ILoadingStatus;
  };
  filter: IMaterialsFilter;
}

export interface IInitialModalState {
  currentId: string;
  currentModal: MaterialsModalsEnum | null;
  isActive: boolean;
}

export const FILTERED_FIELDS = [
  'query',
  'createDateFrom',
  'createDateTo',
  'limit',
  'offset',
  'page',
  'sortDirection',
  'sortField',
];

const toFiltersRequest = (filter: IMaterialsFilter): IMaterialsFilterRequest => {
  let filtersList = Object.fromEntries(
    Object.entries(filter).filter((key) => !FILTERED_FIELDS.includes(key[0]) && key[1].length > 0),
  );

  for (const val of Object.keys(filtersList)) {
    filtersList[val] = filtersList[val].join(',');
  }

  const createDateFrom = filter.createDateFrom
    ? dateFormToNewsDate(filter.createDateFrom, DATE_TEMPLATE_DAYS + DATE_TEMPLATE_HOURS)
    : '';

  const createDateTo = filter.createDateTo
    ? dateFormToNewsDate(filter.createDateTo, DATE_TEMPLATE_DAYS + DATE_TEMPLATE_HOURS)
    : '';

  return {
    ...filter,
    ...(filtersList as IMaterialsFilterRequest),
    createDateFrom,
    createDateTo,
  };
};

export const getMaterialsRequest = createAsyncThunk(
  'getMaterialsRequest',
  async (_: void, { getState, rejectWithValue }) => {
    const filter = (getState() as IRootReducer).materials.filter;

    const params = toFiltersRequest(filter);

    const response = await tryGetMaterialList(params);
    if (!response) {
      return rejectWithValue(null);
    }
    return response.payload;
  },
);

export const materialsSlice = createSlice({
  name: 'materials',
  initialState,
  reducers: {
    setMaterialsModal: (state, { payload }: PayloadAction<IInitialModalState | undefined>) => {
      if (payload) {
        state.modals.currentModal = payload.currentModal;
        state.modals.currentId = payload.currentId;
        state.modals.isActive = payload.isActive;
      } else {
        state.modals.currentModal = null;
        state.modals.currentId = '';
      }
    },
    resetMaterialsFilter: (state) => {
      state.filter = initialState.filter;
    },
    setMaterialsFilter: (state, { payload }: PayloadAction<Partial<IInitialState['filter']>>) => {
      state.filter = { ...state.filter, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMaterialsRequest.pending, (state) => {
        state.materials.status = 'loading';
      })
      .addCase(getMaterialsRequest.fulfilled, (state, { payload }) => {
        state.materials.status = 'loaded';
        state.materials.data = payload.items;
        state.materials.total = payload.meta.total;
      })
      .addCase(getMaterialsRequest.rejected, (state) => {
        state.materials.status = 'error';
      });
  },
});

export const materials = materialsSlice.reducer;

export const { resetMaterialsFilter, setMaterialsFilter, setMaterialsModal } =
  materialsSlice.actions;

const materialsSelector = (state: IRootReducer) => state.materials;

export const materialsListingFilters = createSelector(materialsSelector, ({ filter }) => filter);

export const materialsCurrentModalSelector = createSelector(
  materialsSelector,
  ({ modals }) => modals,
);

export const materialsFilterSelector = createSelector(materialsSelector, ({ filter }) => filter);
