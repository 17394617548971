import { FC, useState } from 'react';
import { Table } from '@mantine/core';
import { Dictionary, DictionaryFullItem } from '@prosv/core/types/bff/dictionary';

import { TableColumnTitle } from '@/ui/components/TableExt/components/TableColumnTitle';

import { DictionaryItemModal } from '../DictionaryItemModal';

import TableBody from './TableBody';

interface IProps {
  dictionary: Dictionary;
  elements: DictionaryFullItem[];
  columns: string[];
}

export const DictionaryItemsTable: FC<IProps> = ({ dictionary, elements, columns }) => {
  const [editItem, setEditItem] = useState<DictionaryFullItem | null>(null);

  return (
    <>
      <Table highlightOnHover verticalSpacing='xs' fz='sm'>
        <Table.Thead className='sticky'>
          <Table.Tr>
            {columns.map((title, index) => (
              <Table.Th key={index}>
                <TableColumnTitle>{title}</TableColumnTitle>
              </Table.Th>
            ))}
          </Table.Tr>
        </Table.Thead>

        <TableBody items={elements} onClick={setEditItem} />
      </Table>

      <DictionaryItemModal
        dictionary={dictionary}
        item={editItem}
        opened={!!editItem}
        onClose={() => setEditItem(null)}
      />
    </>
  );
};
