import { useEffect, useState } from 'react';
import { LoadingOverlay } from '@mantine/core';
import { MultiSelect } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { CatalogSnippet as ICatalogSnippet, SnippetValues } from '@prosv/core/types/snippets';

import { ISelection } from '@/api/catalog/categories';
import { useSelections } from '@/api/catalog/hooks/useSelections';

import { handleAddSnippetData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper';
import { ECatalogSnippetFields } from '@/ui/containers/ContentEditor/snippets/CatalogSnippet/CatalogSnippet.constans';
import {
  getCatalogBlockDefaultValues,
  getSelectionOptions,
} from '@/ui/containers/ContentEditor/snippets/CatalogSnippet/CatalogSnippet.helpers';
import { catalogBlockSchema } from '@/ui/containers/ContentEditor/snippets/CatalogSnippet/CatalogSnippet.schema';
import { useGetSnippetData } from '@/ui/containers/ContentEditor/snippets/hooks/useGetSnippetData';
import { SnippetModal } from '@/ui/containers/ContentEditor/snippets/SnippetModal';

export const CatalogSnippet = () => {
  const { defaultValues, snippetData, handleClose } = useGetSnippetData('catalog');
  const [selections, setSelections] = useState<ISelection[]>();
  const { isLoading, getSelections } = useSelections();

  const form = useForm({
    initialValues: getCatalogBlockDefaultValues(defaultValues),
    validate: yupResolver(catalogBlockSchema),
  });

  useEffect(() => {
    getSelections().then((selectionResponse) => {
      if (selectionResponse) {
        setSelections(selectionResponse.payload.items);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values: SnippetValues<ICatalogSnippet>) => {
    return handleAddSnippetData('catalog', values, handleClose, snippetData);
  };

  return (
    <SnippetModal
      isOpen
      isNew={snippetData?.isNew}
      onClose={handleClose}
      title='Листинг продуктов'
      handleClickBackBtn={handleClose}
      onSubmit={form.onSubmit((values) => handleSubmit(values))}
    >
      <LoadingOverlay visible={isLoading} />
      <MultiSelect
        withAsterisk
        searchable
        style={{ width: '100%', gridColumn: 'span 6' }}
        data={getSelectionOptions(selections || [])}
        label='Товары по акции'
        placeholder='Выберите подборки товаров'
        {...form.getInputProps(ECatalogSnippetFields.SELECTION_CODE)}
      />
    </SnippetModal>
  );
};
