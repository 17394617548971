import React, { FC } from 'react';
import { Box, Button, Input, Paper } from '@mantine/core';

import { FileLoader } from '@/ui/components/FileLoader';
import { TAccept } from '@/ui/components/FileLoader/FileLoader.types';

import { useFileLoaderForm } from './FileLoaderForm.hooks';

import styles from './FileLoaderForm.module.scss';

const MAX_SIZE_200MB = 200 * 1024 * 1024; // 209715200 байт

interface FileLoaderFormProps {
  formId: 'imageLoader' | 'fileLoader' | 'audioLoader';
}

const FILE_LOADER_ACCEPT: Record<FileLoaderFormProps['formId'], TAccept> = {
  imageLoader: 'images',
  fileLoader: 'docs',
  audioLoader: 'audio',
};

export const FileLoaderForm: FC<FileLoaderFormProps> = ({ formId }) => {
  const accept = FILE_LOADER_ACCEPT[formId];
  const {
    sessionID,
    fileName,
    form,
    file,
    fileLink,
    imgData,
    fileLoaderRef,
    copyHandler,
    handleChange,
    handleRemove,
    onInputChange,
    handleSubmit,
    resetForm,
  } = useFileLoaderForm();

  const isAudioFormLoader = accept === 'audio'

  return (
    <Paper radius={8} p={24} shadow='xs' className={styles.root}>
      <form id={formId} onSubmit={form.onSubmit(handleSubmit)}>
        <Input placeholder='Название файла' value={fileName} onChange={onInputChange} />
        <FileLoader
          fileName={fileName}
          sessionID={sessionID.current}
          savedFormFiles={imgData}
          placeholder='Перетащите или добавьте файл'
          onChange={handleChange}
          onRemove={handleRemove}
          ref={fileLoaderRef}
          accept={accept}
          maxSize={isAudioFormLoader ? MAX_SIZE_200MB : undefined}
        />
        {file && (
          <Box className={styles.infoWrapper}>
            <p>Ссылка на файл: </p>
            <p>{fileLink}</p>
            <section>
              <Button variant='outline' onClick={resetForm}>
                Очистить форму
              </Button>
              <Button onClick={copyHandler}>Скопировать ссылку</Button>
            </section>
          </Box>
        )}
      </form>
    </Paper>
  );
};
