import React, { useRef } from 'react';
import { ColorInput, Input, InputBase, Textarea } from '@mantine/core';
import { DatePickerInput, TimeInput } from '@mantine/dates';
import { useForm, yupResolver } from '@mantine/form';
import { IconCalendar } from '@tabler/icons';
import dayjs from 'dayjs';
import { v4 as uuid_v4 } from 'uuid';

import { replaceTempFile } from '@/api';

import { FileLoader } from '@/ui/components/FileLoader';
import { IFileLoaderOnChangeParams } from '@/ui/components/FileLoader/FileLoader.types';
import {
  getImageData,
  handleAddSnippetData,
} from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper/SelectSnippetModalWrapper.helpers';
import {
  DATE_TEMPLATE,
  EEventSnippetFormFields,
  PICKER_BACKGROUND_COLOR,
} from '@/ui/containers/ContentEditor/snippets/EventSnippetModal/EventSnippetModal.constants';
import { getEventSnippetInitialValues } from '@/ui/containers/ContentEditor/snippets/EventSnippetModal/EventSnippetModal.helpers';
import { eventSnippetSchema } from '@/ui/containers/ContentEditor/snippets/EventSnippetModal/EventSnippetModal.schema';
import { useGetSnippetData } from '@/ui/containers/ContentEditor/snippets/hooks/useGetSnippetData';
import { SnippetModal } from '@/ui/containers/ContentEditor/snippets/SnippetModal';

import styles from './EventSnippetModal.module.scss';

export const EventSnippetModalUI = () => {
  const { defaultValues, snippetData, handleClose } = useGetSnippetData('event');
  const sessionID = useRef(uuid_v4());

  const form = useForm({
    initialValues: getEventSnippetInitialValues(defaultValues),
    validate: yupResolver(eventSnippetSchema),
  });

  const handleFileLoaderChange = ({ id, path, name }: IFileLoaderOnChangeParams) => {
    form.values[EEventSnippetFormFields.IMAGE] = {
      id,
      path,
      name,
    };
  };

  const handleFileLoaderRemove = () => {
    form.values[EEventSnippetFormFields.IMAGE] = {
      id: '',
      path: '',
      name: '',
    };
  };

  const imgData = getImageData({
    id: form.values.image.id,
    path: form.values[EEventSnippetFormFields.IMAGE].path?.replace(
      process.env.MEDIA_STORAGE_URL as string,
      '',
    ),
    name: form.values[EEventSnippetFormFields.IMAGE].name,
  });

  return (
    <SnippetModal
      isOpen
      isNew={snippetData?.isNew}
      onClose={handleClose}
      title='Мероприятие'
      handleClickBackBtn={handleClose}
      onSubmit={form.onSubmit(async (values) => {
        if (values[EEventSnippetFormFields.IMAGE].id) {
          await replaceTempFile({
            query: {
              sessionId: sessionID.current,
              ids: [values[EEventSnippetFormFields.IMAGE].id],
            },
          });
        }
        return handleAddSnippetData(
          'event',
          {
            ...values,
            date: dayjs(values[EEventSnippetFormFields.DATE]).format(DATE_TEMPLATE),
            time: dayjs(values[EEventSnippetFormFields.TIME]).format(DATE_TEMPLATE),
          },
          handleClose,
          snippetData,
        );
      })}
    >
      <InputBase
        withAsterisk
        label='Заголовок'
        placeholder='Введите заголовок мероприятия'
        style={{ gridColumn: 'span 6' }}
        {...form.getInputProps(EEventSnippetFormFields.TITLE)}
      />

      <InputBase
        withAsterisk
        label='Ссылка'
        placeholder='Ссылка на мероприятие'
        style={{ gridColumn: 'span 6' }}
        {...form.getInputProps(EEventSnippetFormFields.LINK)}
      />

      <Textarea
        withAsterisk
        label='Описание'
        placeholder='Введите описания мероприятия'
        className={styles.textarea}
        {...form.getInputProps(EEventSnippetFormFields.DESCRIPTION)}
      />

      <DatePickerInput
        withAsterisk
        locale='ru'
        clearable={false}
        leftSection={<IconCalendar size={16} />}
        placeholder='Введите дату'
        title='Дата'
        style={{ gridColumn: 'span 3' }}
        {...form.getInputProps(EEventSnippetFormFields.DATE)}
      />

      <TimeInput
        // clearable
        style={{ gridColumn: 'span 3' }}
        label='Время'
        placeholder='чч:мм'
        {...form.getInputProps(EEventSnippetFormFields.TIME)}
      />

      <Input.Wrapper
        withAsterisk
        label='Изображение'
        style={{ gridColumn: '1 / 7', gridRow: 'span 2' }}
        {...form.getInputProps('image.id')}
      >
        <FileLoader
          onChange={handleFileLoaderChange}
          onRemove={handleFileLoaderRemove}
          sessionID={sessionID.current}
          savedFormFiles={imgData}
          placeholder='Перетащите или добавьте изображение. Изображение должно быть не более 1000 пикс. в высоту'
        />
      </Input.Wrapper>
      <InputBase
        withAsterisk
        label='alt'
        placeholder='Введите alt'
        style={{ gridColumn: 'span 6' }}
        {...form.getInputProps(EEventSnippetFormFields.ALT)}
      />

      <InputBase
        withAsterisk
        label='title'
        placeholder='Введите title'
        style={{ gridColumn: 'span 6' }}
        {...form.getInputProps(EEventSnippetFormFields.IMAGE_TITLE)}
      />

      <ColorInput
        placeholder='Выберите цвет'
        label='Цвет заливки фона'
        style={{ gridColumn: '1 / 4' }}
        withPicker={false}
        swatches={PICKER_BACKGROUND_COLOR}
        {...form.getInputProps(EEventSnippetFormFields.BACKGROUND)}
      />
    </SnippetModal>
  );
};

export const EventSnippetModal = React.memo(EventSnippetModalUI);
