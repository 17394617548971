import { INewsCreationData } from '@/types/news/newsDetail';

import dayjs from 'dayjs';

import {
  DATE_TEMPLATE,
  FILTERED_FIELDS,
} from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.constants';
import { INewsFormFields } from '@/ui/containers/NewsDetailInfo';
import {
  ACTIVITY_FIELD_VALUES,
  EActivityFieldValues,
  ENewsFormFields,
} from '@/ui/containers/NewsDetailInfo/NewsForm/NewsForm.constants';

export const getBreadcrumbs = (title: string) => [
  {
    name: 'Новости',
    url: '/news',
  },
  {
    name: title,
  },
];

export const dateFormToNewsDate = (date: Date | null, time?: string | null) => {
  if (!date) return '';
  let hours = 0;
  let minutes = 0;

  if (time) {
    const parts = time.split(':');
    let val = parseInt(parts[0]);
    if (!isNaN(val)) {
      hours = val;
    }
    val = parseInt(parts[1]);
    if (!isNaN(val)) {
      minutes = val;
    }
  }

  return dayjs(date).set('hour', hours).set('minute', minutes).format(DATE_TEMPLATE);
};

export const formFieldsToCreate = (
  formFields: INewsFormFields,
  draftId: string,
): INewsCreationData => {
  const active = formFields[ENewsFormFields.IS_ACTIVE] as EActivityFieldValues;

  const dateActiveFrom = dateFormToNewsDate(
    formFields[ENewsFormFields.DATA_ACTIVE_FROM_DAYS],
    formFields[ENewsFormFields.DATA_ACTIVE_FROM_HOURS],
  );

  const dateActiveTo = dateFormToNewsDate(formFields[ENewsFormFields.DATA_ACTIVE_TO]);

  return {
    ...formFields,
    draftId,
    umk: formFields[ENewsFormFields.SERIES_LINE_UMK],
    isActive: ACTIVITY_FIELD_VALUES[active],
    dateActiveFrom,
    dateActiveTo,
  };
};

export const formFieldsToUpdate = (
  formFields: INewsFormFields,
  draftId: string,
): INewsCreationData => {
  const fields = formFieldsToCreate(formFields, draftId);

  return Object.fromEntries(
    Object.entries(fields).filter(([key]) => !FILTERED_FIELDS.includes(key)),
  ) as INewsCreationData;
};
