import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Box, LoadingOverlay, Paper } from '@mantine/core';

import { ActionButtons } from '@/ui/components/ActionButtons';
import ModalConfirm from '@/ui/components/ModalConfirm/ModalConfirm';
import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import {
  getBreadcrumbs,
  useCreationBannerHandlers,
} from '@/ui/containers/BannerCreationContainer/BannerCreationContainer.helpers';
import { useModeBanner } from '@/ui/containers/BannerCreationContainer/BannerCreationContainer.hooks';
import { useBannerCreationState } from '@/ui/containers/BannerCreationContainer/BannerCreationContainer.state';
import { BannerDetailInfo } from '@/ui/containers/BannerCreationContainer/components/BannerDetailInfo';
import { BANNER_CREATION_FORM_ID } from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerForm.constants';
import { EAddNewsConfirmationTexts } from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.constants';

export const BannerCreationContainer: FC = () => {
  const { id } = useParams();
  const bannerState = useBannerCreationState(id);
  const breadcrumbs = getBreadcrumbs(bannerState.title);
  useModeBanner(bannerState, id);
  const { handleSubmit, handleDelete, state, onConfirmLeave, onCancel } = useCreationBannerHandlers(
    id || '',
    bannerState,
  );

  if (!bannerState.permissions.canView) {
    return <div>Недостаточно прав.</div>;
  }

  return (
    <Box
      style={{
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PageHeader
        breadcrumbs={breadcrumbs}
        title={bannerState.title}
        backLink='/banners'
        rightButton={
          <ActionButtons
            permissions={bannerState.permissions}
            isNew={bannerState.isNewBanner}
            handleDelete={handleDelete}
            formId={BANNER_CREATION_FORM_ID}
          />
        }
      />
      <Paper
        radius={8}
        p={24}
        shadow='xs'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <LoadingOverlay visible={bannerState.isLoading} />

        {bannerState.isShowBannerDetail && (
          <BannerDetailInfo bannerState={bannerState} handleSubmit={handleSubmit} />
        )}

        {state === 'blocked' && (
          <ModalConfirm
            title={EAddNewsConfirmationTexts.OUT_TITLE}
            text={EAddNewsConfirmationTexts.NOT_SAVE_MENU_SITE}
            confirmHandler={onConfirmLeave}
            opened={state === 'blocked'}
            onClose={onCancel}
          />
        )}
      </Paper>
    </Box>
  );
};
