import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, useMantineTheme } from '@mantine/core';
import { Pencil } from 'tabler-icons-react';
import { useDebouncedCallback } from 'use-debounce';

import { formatDate } from '@/utils/formatDate';
import { getItemPermissions } from '@/utils/permissions';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import { ITableColumns } from '@/ui/components/CustomTable/CustomTable.types';
import { ActiveStatusCell } from '@/ui/containers/MaterialsContainer/Components/ActiveStatusCell';
import { SortHeader } from '@/ui/containers/MaterialsContainer/Components/SortHeader';
import { ITableMaterials } from '@/ui/containers/MaterialsContainer/Materials.types';
import { MaterialsModalsEnum } from '@/ui/containers/MaterialsContainer/MaterialsContainer.constants';

import { IRootReducer } from '@/store';
import {
  getMaterialsRequest,
  IInitialState,
  materialsCurrentModalSelector,
  materialsFilterSelector,
  setMaterialsFilter,
  setMaterialsModal,
} from '@/store/slices/materials/materials';

export const useGetMaterialsList = (delay = 500) => {
  const dispatch = useAppDispatch();
  return useDebouncedCallback(() => dispatch(getMaterialsRequest()), delay);
};

export const useMaterialsModalHelper = (modal: MaterialsModalsEnum) => {
  const dispatch = useAppDispatch();
  const currentModal = useSelector(materialsCurrentModalSelector);
  const onCloseModal = () => {
    dispatch(setMaterialsModal());
  };
  const onOpenModal = () => {
    dispatch(setMaterialsModal(currentModal));
  };

  return { currentModal, onCloseModal, onOpenModal, isOpen: currentModal.currentModal === modal };
};

export const useMaterialsTableData = () => {
  const dispatch = useAppDispatch();
  const { data, status, total } = useSelector((state: IRootReducer) => state.materials.materials);
  const theme = useMantineTheme();
  const getMaterialsList = useGetMaterialsList();
  const filter = useAppSelector(materialsFilterSelector);
  const [tableData, setTableData] = useState<ITableMaterials[]>([]);
  const [currentSortField, setCurrentSortField] = useState(filter.sortField);
  const [isDescDirection, setIsDescDirection] = useState(filter.sortDirection === 'desc');

  const isReversedId = currentSortField === 'id' && !isDescDirection;
  const isReversedIsActive = currentSortField === 'isActive' && !isDescDirection;
  const isReversedTitle = currentSortField === 'title' && !isDescDirection;
  const isReversedSort = currentSortField === 'sort' && !isDescDirection;
  const isReversedCreateDate = currentSortField === 'createDate' && !isDescDirection;
  const isReversedActiveDate = currentSortField === 'dateActiveFrom' && !isDescDirection;

  const onSortChange = (params: Partial<IInitialState['filter']>) => {
    if (params.sortField) setCurrentSortField(params.sortField);
    const changeFieldDirection =
      params.sortField === filter.sortField && filter.sortDirection === 'desc';
    const sortDirection = changeFieldDirection ? 'asc' : 'desc';
    setIsDescDirection(sortDirection === 'desc');
    dispatch(setMaterialsFilter({ ...params, sortDirection }));
    getMaterialsList();
  };

  useEffect(() => {
    const temp = data.map((item) => {
      return {
        ...item,
        permissions: getItemPermissions('ARTICLE_', item.permissions),
        type: item.type?.name,
        createdDate: formatDate(item?.meta.createDate.value),
        dateActiveFrom: formatDate(item.dateActiveFrom?.value),
      };
    });

    setTableData(temp);
  }, [data]);

  const navigate = useNavigate();

  const editMaterialHandler = (id: string) => {
    navigate(`/materials/${id}`);
  };

  const toggleActive = (uuid: string, isActive: boolean) =>
    dispatch(
      setMaterialsModal({
        currentModal: MaterialsModalsEnum.MATERIALS_ACTIVE_STATUS_MODAL,
        currentId: uuid,
        isActive,
      }),
    );

  const columns: ITableColumns<ITableMaterials>[] = [
    {
      title: (
        <SortHeader
          sorted={currentSortField === 'id'}
          reversed={isReversedId}
          onSort={() => onSortChange({ sortField: 'id' })}
        >
          ID
        </SortHeader>
      ),
      field: 'id',
      style: {
        minWidth: 80,
        maxWidth: 120,
      },
    },
    {
      title: (
        <SortHeader
          sorted={currentSortField === 'isActive'}
          reversed={isReversedIsActive}
          onSort={() => onSortChange({ sortField: 'isActive' })}
        >
          Активность
        </SortHeader>
      ),
      field: 'isActive',
      render: (item) => (
        <ActiveStatusCell
          isActive={item.isActive}
          canUpdate={item.permissions.canUpdateActivity || item.permissions.canUpdate}
          toggleActivity={() => toggleActive(item.uuid, item.isActive)}
        />
      ),
    },
    {
      title: (
        <SortHeader
          sorted={currentSortField === 'title'}
          reversed={isReversedTitle}
          onSort={() => onSortChange({ sortField: 'title' })}
        >
          Заголовок
        </SortHeader>
      ),
      field: 'title',
    },
    {
      title: 'Тип материала',
      field: 'type',
    },
    {
      title: (
        <SortHeader
          sorted={currentSortField === 'sort'}
          reversed={isReversedSort}
          onSort={() => onSortChange({ sortField: 'sort' })}
        >
          Сортировка
        </SortHeader>
      ),
      field: 'sort',
      position: 'center',
    },
    {
      title: (
        <SortHeader
          sorted={currentSortField === 'createDate'}
          reversed={isReversedCreateDate}
          onSort={() => onSortChange({ sortField: 'createDate' })}
        >
          Дата создания
        </SortHeader>
      ),
      position: 'right',
      field: 'createdDate',
      style: {
        width: 160,
      },
    },
    {
      title: (
        <SortHeader
          sorted={currentSortField === 'dateActiveFrom'}
          reversed={isReversedActiveDate}
          onSort={() => onSortChange({ sortField: 'dateActiveFrom' })}
        >
          Дата публикации
        </SortHeader>
      ),
      position: 'right',
      style: {
        width: 175,
      },
      field: 'dateActiveFrom',
    },
    {
      title: '',
      style: { width: 50 },
      field: 'actions',
      render: (item) =>
        item.permissions.canGet || item.permissions.canUpdate ? (
          <Button
            name='Редактировать'
            title='Редактировать'
            variant='subtle'
            size='compact-sm'
            onClick={() => editMaterialHandler(item.uuid)}
            style={{ position: 'static' }}
          >
            <Pencil size={22} color={theme.colors.greyLight[9]} />
          </Button>
        ) : null,
    },
  ];

  return { columns, tableData, status, total };
};
