import { Box, Button, Group, Paper } from '@mantine/core';

import { usePermission } from '@/hooks/usePermissions';

import { GeneralInformationCard } from '@/ui/components/GeneralInformationCard/GeneralInformationCard';
import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import { QuestionsAndReviewForm } from '@/ui/components/ReviewAndQuestionsForm/ReviewAndQuestionsForm';
import { PublicationModal } from '@/ui/containers/Questions/details/PublicationModal';
import { useQuestionsDetails } from '@/ui/containers/Questions/details/QuestionsDetails/QuestionsDetails.hook';

import { APPROVE_BUTTON_LABELS, DISAPPROVE_BUTTON_LABELS } from './QuestionsDetail.constants';

export const QuestionsDetails = () => {
  const canUpdate = usePermission('QUESTION_ANSWER_UPDATE');

  const {
    questionsBreadcrumbs,
    isLoading,
    isAnswerLoading,
    id,
    values,
    data,
    onSubmitQuestion,
    onHideQuestion,
    isOpenModal,
    setIsOpenModal,
  } = useQuestionsDetails();

  const disapproveLabel = DISAPPROVE_BUTTON_LABELS[values.status];
  const approveLabel = APPROVE_BUTTON_LABELS[values.status];
  const redText = values.status === 'new' ? 'red' : '';

  return (
    <Box
      style={{
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PageHeader
        backLink='/questions'
        breadcrumbs={questionsBreadcrumbs}
        title={`ID: ${id}`}
        rightButton={
          canUpdate && disapproveLabel && approveLabel ? (
            <Group gap={10}>
              <Button
                disabled={isLoading}
                onClick={onHideQuestion}
                variant='default'
                style={{ color: redText }}
              >
                {disapproveLabel}
              </Button>
              <Button
                disabled={isLoading}
                onClick={() => onSubmitQuestion('published')}
                style={{ background: '#02C39A' }}
              >
                {approveLabel}
              </Button>
            </Group>
          ) : null
        }
      />
      <Paper
        radius={8}
        p={24}
        shadow='xs'
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          height: '100%',
          overflow: 'auto',
          position: 'relative',
          gap: 24,
        }}
      >
        <QuestionsAndReviewForm isLoading={isAnswerLoading} {...values} />
        {data.entity?.code && (
          <GeneralInformationCard
            generalId={data.entity?.code}
            cardType='question'
            rating={data.entity.rating}
            author={{
              name: data.author?.name,
              uuid: data.author?.id,
              createDate: data?.createDate,
            }}
            editor={{
              name: data?.reviewChange?.user?.name || ' - ',
              email: data?.reviewChange?.user?.email,
              uuid: data?.reviewChange?.user?.id,
              editDate: data?.reviewChange?.updateAt,
            }}
            entityCode={data.entity?.code}
          />
        )}
        <PublicationModal
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          onConfirm={() => onSubmitQuestion('hidden')}
        />
      </Paper>
    </Box>
  );
};
