import { ISelectOptions } from '@/ui/containers/TemplateCreationContainer/TemplateForm/TemplateForm.types';

export enum EMetaTagFormFields {
  SITE = 'site',
  SITE_CHECKBOX = 'isHideInSitemap',
  TYPE_PAGE = 'pageType',
  PAGE_H1 = 'pageH1',
  TITLE = 'title',
  DESCRIPTION = 'description',
  KEYWORDS = 'keywords',
  ROBOTS = 'robots',
  AUTHOR = 'author',
  COPYRIGHT = 'copyright',
  CONTENT = 'content',
  CANONICAL_URL = 'canonical',
  PAGE_H1_CHECKBOX = 'isUseTemplatePageH1',
  TITLE_CHECKBOX = 'isUseTemplateTitle',
  DESCRIPTION_CHECKBOX = 'isUseTemplateDescription',
  KEYWORDS_CHECKBOX = 'isUseTemplateKeywords',
  ROBOTS_CHECKBOX = 'isUseTemplateRobots',
  AUTHOR_CHECKBOX = 'isUseTemplateAuthor',
  COPYRIGHT_CHECKBOX = 'isUseTemplateCopyright',
  CONTENT_CHECKBOX = 'isUseTemplateContent',
  CANONICAL_URL_CHECKBOX = 'isUseTemplateCanonical',
}

export const selectRobotOptions: ISelectOptions[] = [
  { value: 'all', label: 'all' },
  { value: 'index', label: 'index' },
  { value: 'noindex', label: 'noindex' },
  { value: 'follow', label: 'follow' },
  { value: 'nofollow', label: 'nofollow' },
  { value: 'noarchive', label: 'noarchive' },
  { value: 'archive', label: 'archive' },
  { value: 'noyaca', label: 'noyaca' },
  { value: 'none', label: 'none' },
];

export const META_TAG_CREATION_FORM_ID = 'meta-tag-creation-form';

export const DEFAULT_ROBOTS_VALUE = ['all'];
