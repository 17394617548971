import { TFeature } from '@/types/features/features';

import React, { FC } from 'react';
import { Table } from '@mantine/core';

import { TableColumnTitle } from '@/ui/components/TableExt/components/TableColumnTitle';

import { TableBody } from './TableBody';

type TConfigurationsTable = {
  elements: TFeature[];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FeatureTogglesTable: FC<TConfigurationsTable> = ({ elements }) => {
  return (
    <Table highlightOnHover verticalSpacing='xs' fz='sm'>
      <Table.Thead className='sticky'>
        <Table.Tr>
          <Table.Th>
            <TableColumnTitle>Feature</TableColumnTitle>
          </Table.Th>
          <Table.Th>
            <TableColumnTitle>Активность</TableColumnTitle>
          </Table.Th>
          <Table.Th>
            <TableColumnTitle>Описание</TableColumnTitle>
          </Table.Th>
          <Table.Th>
            <TableColumnTitle>Стратегия</TableColumnTitle>
          </Table.Th>
          <Table.Th style={{ maxWidth: '50px' }}></Table.Th>
        </Table.Tr>
      </Table.Thead>

      <TableBody {...{ items: elements }} />
    </Table>
  );
};

export default FeatureTogglesTable;
