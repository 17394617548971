import { EPromotionVariant } from '@/types/promotions';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { isEqual } from 'lodash';

import { GeneralInformationCard } from '@/ui/components/GeneralInformationCard/GeneralInformationCard';
import {
  setSelectorDefaultFormData,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';
import { getConvertAndFilteredBanners } from '@/ui/containers/PromotionCreation/components/PromotionDetailInfo/PromotionDetailInfo.helper';
import { promotionFormSchema } from '@/ui/containers/PromotionCreation/components/PromotionDetailInfo/PromotionDetailInfo.schema';
import { PromotionForm } from '@/ui/containers/PromotionCreation/components/PromotionForm';
import { PROMOTION_CREATION_FORM_ID } from '@/ui/containers/PromotionCreation/components/PromotionForm/PromotionForm.constants';
import { getDefaultValues } from '@/ui/containers/PromotionCreation/components/PromotionForm/PromotionForm.helpers';
import { IPromotionFormFields } from '@/ui/containers/PromotionCreation/components/PromotionForm/PromotionForm.types';
import {
  IBannerSelectorItem,
  IPromotionCreationState,
} from '@/ui/containers/PromotionCreation/PromotionCreation.types';

interface IProps {
  handleSubmit: (values: IPromotionFormFields) => void;
  setIsShouldBlock: (isShouldBlock: boolean) => void;
  promotionState: IPromotionCreationState;
  setTemplateType: (type: EPromotionVariant) => void;
}

export const PromotionDetailInfo: FC<IProps> = ({
  handleSubmit,
  setIsShouldBlock,
  promotionState,
  setTemplateType,
}) => {
  const {
    isNewPromotion,
    promotion,
    defaultFormData,
    trySavePromotion,
    setTrySavePromotion,
    isForceChangeTemplate,
    setOpenConfirmModal,
    getBanners,
    permissions,
  } = promotionState;

  const form = useForm({
    initialValues: getDefaultValues(promotion),
    validate: yupResolver(promotionFormSchema),
  });

  const generalInfoData = useMemo(
    () => ({
      generalId: promotion?.uuid,
      author: {
        name: promotion?.meta.author.fullName,
        createDate: promotion?.meta?.createDate?.value,
        uuid: promotion?.meta.author.id,
      },
      editor: {
        name: promotion?.meta.editor?.fullName,
        editDate: promotion?.meta?.updateDate?.value,
        uuid: promotion?.meta.editor?.id,
      },
    }),
    [promotion],
  );

  const [banners, setBanners] = useState<IBannerSelectorItem[]>([]);

  const setDefaultFormData = useStoreContentEditor(setSelectorDefaultFormData);

  useEffect(() => {
    setDefaultFormData(form.values);
    return () => {
      setDefaultFormData(null);
    };
  }, []);

  useEffect(() => {
    if (defaultFormData && !isEqual(form.values, defaultFormData)) {
      setIsShouldBlock(true);
    }
    setTemplateType(form.values.type);
  }, [form]);

  const handleSearch = useCallback(async (search: string) => {
    const { payload } = await getBanners(search);
    if (!!payload && !!payload?.items?.length) {
      const convertedBanners = getConvertAndFilteredBanners(payload.items);
      const banner = promotion?.banner;
      if (banner && !convertedBanners.find(({ value }) => value === banner.uuid)) {
        convertedBanners.push({ value: banner.uuid, label: banner.title });
      }
      setBanners(convertedBanners);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSearch('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        gap: '24px',
      }}
    >
      <form
        id={PROMOTION_CREATION_FORM_ID}
        style={{
          border: '1px solid #EDF1F3',
          borderRadius: '6px',
          padding: '16px 12px',
          overflow: 'scroll',
          width: '100%',
        }}
        onSubmit={form.onSubmit(handleSubmit)}
      >
        <PromotionForm
          permissions={permissions}
          isNewPromotion={isNewPromotion}
          handleSearch={handleSearch}
          form={form}
          setTrySave={setTrySavePromotion}
          trySavePromotion={trySavePromotion}
          banners={banners}
          setOpenConfirmChangeTemplate={setOpenConfirmModal}
          isForceChangeTemplate={isForceChangeTemplate}
        />
      </form>
      {!isNewPromotion && (
        <Box>
          <GeneralInformationCard {...generalInfoData} cardType='support' />
        </Box>
      )}
    </Box>
  );
};
