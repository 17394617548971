import { createTheme, MultiSelect, Select } from '@mantine/core';

import { BREAKPOINTS } from '@/constants/mantine/breakpoints';

import { genColors } from './helpers';
import { SELECT_SCROLL_AREA_PROPS } from './theme.constants';

export const theme = createTheme({
  breakpoints: Object.entries(BREAKPOINTS).reduce(
    (res, [name, width]) => ({ ...res, [name]: `${width}px` }),
    {},
  ),
  colors: {
    primary: genColors('#706FDD'),
    blue: genColors('#322FC6'),
    black: genColors('#121212'),
    greyLight: genColors('#BFCEDB'),
    grayDefault: genColors('#8DA2B5'),
    greyDark: genColors('#66788A'),
    greyLink: genColors('#E8ECF0'),
    greyDefault: genColors('#8DA2B5'),
    greyLine: genColors('#E8ECF0'),
    greyBG: genColors('#F0F1F2'),
    green: genColors('#02C39A'),
    darkest: genColors('#3E4D5B'),
    dark6: genColors('#25262B'),
    yellow: genColors('#F3DD16'),
  },
  components: {
    Select: Select.extend({
      defaultProps: {
        scrollAreaProps: SELECT_SCROLL_AREA_PROPS,
        checkIconPosition: 'right',
      },
    }),
    MultiSelect: MultiSelect.extend({
      defaultProps: {
        scrollAreaProps: SELECT_SCROLL_AREA_PROPS,
        checkIconPosition: 'right',
      },
    }),
  },
});
