import React, { useRef } from 'react';
import { Box, InputBase } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { PromoSnippet as PromoSnippetType, SnippetValues } from '@prosv/core/types/snippets';
import { Editor } from '@tiptap/react';

import { handleAddSnippetData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper';
import { useGetSnippetData } from '@/ui/containers/ContentEditor/snippets/hooks/useGetSnippetData';
import { EPromoSnippetFields } from '@/ui/containers/ContentEditor/snippets/PromoSnippet/PromoSnippet.constants';
import { getPromoSnippetDefaultValues } from '@/ui/containers/ContentEditor/snippets/PromoSnippet/PromoSnippet.helpers';
import { promoSnippetSchema } from '@/ui/containers/ContentEditor/snippets/PromoSnippet/PromoSnippet.schema';
import { SnippetModal } from '@/ui/containers/ContentEditor/snippets/SnippetModal';
import { TextEditor } from '@/ui/containers/ContentEditor/snippets/TextSnippetModal/TextEditor';

export const PromoSnippet = () => {
  const { defaultValues, snippetData, handleClose } = useGetSnippetData('promo');
  const editorRef = useRef<Editor | undefined | null>();

  const form = useForm({
    initialValues: getPromoSnippetDefaultValues(defaultValues),
    validate: yupResolver(promoSnippetSchema),
  });

  const handleSubmit = async (values: SnippetValues<PromoSnippetType>) => {
    return handleAddSnippetData(
      'promo',
      { ...values, description: editorRef.current?.getHTML() || '' },
      handleClose,
      snippetData,
    );
  };

  return (
    <SnippetModal
      isOpen
      isNew={snippetData?.isNew}
      onClose={handleClose}
      title='Текст+промокод'
      handleClickBackBtn={handleClose}
      onSubmit={form.onSubmit((values) => handleSubmit(values))}
    >
      <InputBase
        label='Заголовок описания'
        placeholder='Введите заголовок'
        withAsterisk
        style={{ gridColumn: 'span 12' }}
        {...form.getInputProps(EPromoSnippetFields.TITLE)}
      />
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexDirection: 'column',
          gridColumn: 'span 12',
          minHeight: 200,
          position: 'relative',
        }}
      >
        <label style={{ fontSize: 14 }}>Описание</label>
        <TextEditor
          placeholder='Введите описание'
          defaultTextValue={defaultValues?.description}
          editorRef={editorRef}
        />
      </Box>

      <InputBase
        label='Текст для промокода'
        placeholder='Введите заголовок'
        style={{ gridColumn: 'span 12' }}
        {...form.getInputProps(EPromoSnippetFields.PROMO_TITLE)}
      />

      <InputBase
        label='Промокод'
        placeholder='Введите промокод'
        style={{ gridColumn: 'span 12' }}
        {...form.getInputProps(EPromoSnippetFields.PROMO_CODE)}
      />
    </SnippetModal>
  );
};
