import { FC } from 'react';
import { Box, Group, MultiSelect, Select, Text, Textarea, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import {
  ETemplateFormFields,
  selectPageTypeOptions,
  selectRobotOptions,
  selectSiteOptions,
} from '@/ui/containers/TemplateCreationContainer/TemplateForm/TemplateForm.constants';
import { ITemplateFormFields } from '@/ui/containers/TemplateCreationContainer/TemplateForm/TemplateForm.types';

import styles from './TemplateForm.module.scss';

interface IProps {
  form: UseFormReturnType<ITemplateFormFields>;
}

export const TemplateForm: FC<IProps> = ({ form }) => {
  const { onChange: onChangeRobots, value: valueRobots } = form.getInputProps(
    ETemplateFormFields.ROBOTS,
  );
  const { onChange: onChangeSiteName, value: valueSiteName } = form.getInputProps(
    ETemplateFormFields.SITE,
  );

  const { onChange: onChangePageType, value: valuePageType } = form.getInputProps(
    ETemplateFormFields.PAGE_TYPE,
  );

  return (
    <Box className={styles.root}>
      <Group className={styles.group}>
        <Text size='sm'>Сайт</Text>
        <Box className={styles.selectWrapper}>
          <Select
            disabled
            placeholder='siteName'
            data={selectSiteOptions}
            value={valueSiteName}
            onChange={onChangeSiteName}
          />
        </Box>
      </Group>
      <Group className={styles.group}>
        <Text size='sm' className={styles.label}>
          Тип страницы
        </Text>
        <Box className={styles.selectWrapper}>
          <Select
            disabled
            placeholder='typePage'
            data={selectPageTypeOptions}
            value={valuePageType}
            onChange={onChangePageType}
          />
        </Box>
      </Group>
      <Group className={styles.group}>
        <Text>page_h1</Text>
        <TextInput
          className={styles.textInput}
          placeholder='Введите page_h1'
          {...form.getInputProps(ETemplateFormFields.PAGE_H1)}
        />{' '}
      </Group>
      <Group className={styles.group}>
        <Text>title</Text>
        <TextInput
          className={styles.textInput}
          placeholder='Введите title'
          {...form.getInputProps(ETemplateFormFields.TITLE)}
        />{' '}
      </Group>
      <Group className={styles.group}>
        <Text>description</Text>
        <TextInput
          className={styles.textInput}
          placeholder='Введите description'
          {...form.getInputProps(ETemplateFormFields.DESCRIPTION)}
        />{' '}
      </Group>
      <Group className={styles.group}>
        <Text>keywords</Text>
        <TextInput
          className={styles.textInput}
          placeholder='Введите keywords'
          {...form.getInputProps(ETemplateFormFields.KEYWORDS)}
        />
      </Group>
      <Group className={styles.group}>
        <Text size='sm' className={styles.label}>
          robots
        </Text>
        <Box className={styles.selectWrapper}>
          <MultiSelect
            label='robots'
            data={selectRobotOptions}
            value={valueRobots}
            onChange={onChangeRobots}
          />
        </Box>
      </Group>
      <Group className={styles.group}>
        <Text>author</Text>
        <TextInput
          className={styles.textInput}
          placeholder='Введите author'
          {...form.getInputProps(ETemplateFormFields.AUTHOR)}
        />
      </Group>
      <Group className={styles.group}>
        <Text>copyright</Text>
        <TextInput
          className={styles.textInput}
          placeholder='Введите copyright'
          {...form.getInputProps(ETemplateFormFields.COPYRIGHT)}
        />
      </Group>
      <Group className={styles.group}>
        <Text>content</Text>
        <Textarea
          className={styles.textArea}
          placeholder='Введите content'
          {...form.getInputProps(ETemplateFormFields.CONTENT)}
        />
      </Group>
      <Group className={styles.group}>
        <Text>Канонический URL</Text>
        <TextInput
          className={styles.textInput}
          placeholder='Введите канонический URL'
          {...form.getInputProps(ETemplateFormFields.CANONICAL)}
        />
      </Group>
    </Box>
  );
};
