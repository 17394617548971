import React from 'react';
import { Button, Image } from '@mantine/core';

import { ITableColumns } from '@/ui/components/CustomTable/CustomTable.types';

import TrashSVG from '@/assets/icons/trash.svg';

export const useProductsTableData = () => {
  const columns: ITableColumns<any>[] = [
    {
      title: 'Изображение',
      field: 'image',
      style: { width: '120px' },
      position: 'center',
    },
    {
      title: 'Название',
      field: 'name',
      style: { width: '440px' },
    },
    {
      title: 'Артикул',
      style: { width: '120px' },
      field: 'code',
    },
    {
      title: 'Авторы',
      field: 'authors',
    },
    {
      title: 'Год издания',
      field: 'publicationYear',
      position: 'center',
    },
    {
      title: '',
      style: { width: 50 },
      field: 'actions',
      render: (onRenderClick) => (
        <Button name='Удалить' title='Удалить' size='compact-sm' onClick={onRenderClick}>
          <Image
            src={TrashSVG}
            style={{
              minWidth: 13,
            }}
          />
        </Button>
      ),
    },
  ];

  return { columns };
};
