import { useState } from 'react';
import { Box, Button, Paper } from '@mantine/core';
import { v4 as uuid_v4 } from 'uuid';

import notify from '@/utils/notify';

import { CatalogTagsForm } from '@/modules/catalogTags/components/CatalogTagsForm';
import { CatalogTagsModal } from '@/modules/catalogTags/components/CatalogTagsModal';
import {
  useCreateCatalogTagsMutation,
  useLazyGetAsyncJobStatusQuery,
} from '@/modules/catalogTags/store/catalogTagsSlice';
import { CatalogTagsFormFields } from '@/modules/catalogTags/types';

import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';

import styles from './CatalogTags.module.scss';

const TAGS_FORM_ID = 'tagsForm';
const sessionId = uuid_v4();

export const CatalogTags = () => {
  const [jobId, setJobId] = useState('');
  const isShowPreviewBtn = Boolean(jobId);
  const [open, setOpen] = useState(false);
  const [trigger, { data: asyncJobData }] = useLazyGetAsyncJobStatusQuery();

  const [mutation] = useCreateCatalogTagsMutation();

  const handleSubmit = async (values: CatalogTagsFormFields) => {
    const catalogTagsPayload = await mutation({
      file: {
        sessionId,
        attachmentIds: [values.file.id],
      },
    });

    if ('data' in catalogTagsPayload) {
      setJobId(catalogTagsPayload.data.id);
      notify({
        type: 'success',
        message: 'Задача по загрузке тегов принята в работу',
      });
    }

    if ('error' in catalogTagsPayload && catalogTagsPayload.error) {
      notify({
        type: 'error',
        message: 'Ошибка при загрузке тегов',
      });
    }
  };

  const getAsyncJobInfo = async () => {
    if (jobId) {
      await trigger({ id: jobId });
      setOpen(true);
    }
  };

  return (
    <>
      {asyncJobData && open && (
        <CatalogTagsModal isOpen={open} jobData={asyncJobData} onClose={() => setOpen(false)} />
      )}
      <Box className={styles.root}>
        <PageHeader
          title='Теги в каталоге'
          backLink='/'
          rightButton={
            <Box className={styles.actionWrapper}>
              {isShowPreviewBtn && (
                <Button
                  className={styles.actionButton}
                  type='submit'
                  variant='default'
                  onClick={getAsyncJobInfo}
                >
                  Узнать статус загрузки
                </Button>
              )}
              <Button className={styles.actionButton} type='submit' form={TAGS_FORM_ID}>
                Загрузить
              </Button>
            </Box>
          }
        />
        <Paper radius={8} p={24} shadow='xs' className={styles.formWrapper}>
          <CatalogTagsForm
            sessionID={sessionId}
            formId={TAGS_FORM_ID}
            handleSubmit={handleSubmit}
          />
        </Paper>
      </Box>
    </>
  );
};
