import { FC } from 'react';
import { Box, Select } from '@mantine/core';

import { DndRowConfiguration } from '@/ui/containers/ContentEditor/Rows/DndRowConfiguration';

import { Loader } from '../Loader';
import {
  getTemplateSelectValue,
  savedContentDataSelector,
  savedFromAllDataSelector,
  useStoreContentEditor,
} from '../store';

import { RowConfiguration } from './RowConfiguration';
import { EMPTY_ROW_ID } from './Rows.constnats';

import styles from './Rows.module.scss';

interface Props {
  draftId?: string;
}

export const Rows: FC<Props> = ({ draftId }) => {
  const savedContentData = useStoreContentEditor(savedContentDataSelector);
  const savedContentCurrentEditorData = useStoreContentEditor((state) =>
    savedFromAllDataSelector(state, draftId ?? ''),
  );

  const loadingContentData = useStoreContentEditor((state) => state.loadingContentData);
  const selectValue = useStoreContentEditor(getTemplateSelectValue);

  const savedContent = draftId ? savedContentCurrentEditorData : savedContentData;

  return (
    <>
      <Box className={styles.root}>
        {selectValue && (
          <Select
            className={styles.select}
            data={[selectValue]}
            value={selectValue.value}
            disabled
          />
        )}
        {savedContent?.grid?.map((row, index) => (
          <DndRowConfiguration
            key={index + Math.random()}
            index={index}
            rowData={row}
            rowId={index}
          />
        ))}
        <RowConfiguration rowId={EMPTY_ROW_ID} />
      </Box>
      <Loader loading={loadingContentData} />
    </>
  );
};
