import { FC, useState } from 'react';
import { Box, Button, Container, Group, Paper, Title } from '@mantine/core';

import { AudiosFormBlock } from './AudiosFormBlock';
import { CategoriesFormBlock } from './CategoriesFormBlock';
import { LABELS_DATA } from './CubeContainer.constants';
import { useCubeForm } from './CubeContainer.hooks';
import { LabelBlock } from './LabelBlock';
import { PersonalFormBlock } from './PersonalFormBlock';
import { ProjectsFormBlock } from './ProjectsFormBlock';
import { ServicesFormBlock } from './ServicesFormBlock';
import { WebinarsFormBlock } from './WebinarsFormBlock';

import styles from './CubeContainer.module.scss';

export const CubeContainer: FC = () => {
  const [isAnyOpen, setAnyOpen] = useState(false);
  const { form, onSubmit } = useCubeForm({ isAnyOpen });

  const selectProps = {
    form,
    onDropdownOpen: () => setAnyOpen(true),
    onDropdownClose: () => setAnyOpen(false),
  };

  return (
    <Box className={styles.root}>
      <form onSubmit={onSubmit}>
        <Container fluid className={styles.head}>
          <Group justify='space-between'>
            <Title order={1}>Настройка меню Платформы</Title>

            <Button type='submit' className={styles.submitButton} disabled={!form.isValid()}>
              Сохранить
            </Button>
          </Group>
        </Container>

        <Paper shadow='xs' className={styles.paper}>
          <LabelBlock {...LABELS_DATA.categories} />
          <CategoriesFormBlock {...selectProps} />

          <LabelBlock {...LABELS_DATA.services} />
          <ServicesFormBlock {...selectProps} />

          <LabelBlock {...LABELS_DATA.personal} />
          <PersonalFormBlock {...selectProps} />

          <LabelBlock {...LABELS_DATA.webinars} />
          <WebinarsFormBlock />

          <LabelBlock {...LABELS_DATA.audios} />
          <AudiosFormBlock {...selectProps} />

          <LabelBlock {...LABELS_DATA.projects} />
          <ProjectsFormBlock {...selectProps} />
        </Paper>
      </form>
    </Box>
  );
};
