import type { TGEtNewsQuery } from '@/types/news/news';

import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Center,
  Container,
  Group,
  LoadingOverlay,
  MultiSelect,
  Pagination,
  Paper,
  ScrollArea,
  Select,
  Text,
  Title,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { debounce } from 'lodash';
import { RotateClockwise } from 'tabler-icons-react';

import { getOffset } from '@/utils/getOffset';
import { getPages } from '@/utils/getPages';

import { formatDate, PAGE_ELEMENTS } from '@/constants/common';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { usePermission } from '@/hooks/usePermissions';

import NewsTable from '@/ui/containers/NewsContainer/components/NewsTable/NewsTable';

import { useNewsContainerState } from './NewsContainer.state';

import styles from './NewsContainer.module.scss';

import {
  fetchNewsListAction,
  setNewsLimit,
  setNewsOffset,
  setNewsPage,
} from '@/store/slices/news/news';
import { setServiceChanged } from '@/store/slices/service/service';

const NewsContainer: FC = () => {
  const canCreate = usePermission('NEWS_CREATE');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const state = useNewsContainerState();

  const fetchNewsListDebounced = debounce(() => {
    const payload: TGEtNewsQuery = {
      offset: state.newsOffset,
      limit: state.newsLimit,
      sortDirection: state.sortFiled?.direction,
      sortField: state.sortFiled?.field,
      ...state.filter,
    };

    return dispatch(fetchNewsListAction(payload));
  }, 500);

  useEffect(
    () => void dispatch(setServiceChanged(false)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => void fetchNewsListDebounced(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.newsLimit, state.newsOffset, state.filter, state.sortFiled],
  );

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '96vh',
        overflowY: 'hidden',
      }}
    >
      <Container fluid mb={16} style={{ marginLeft: 'initial', marginRight: 'initial' }}>
        <Group justify='space-between'>
          <Title order={1}>Новости</Title>

          {canCreate && (
            <Button style={{ width: '200px' }} onClick={() => navigate('/news/new')}>
              Создать
            </Button>
          )}
        </Group>
      </Container>

      <Paper
        radius={8}
        p={24}
        pb={12}
        shadow='xs'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: '100%',
          overflow: 'hidden',
        }}
      >
        <Group mb={24} justify='space-between' align='flex-end' gap='xs' grow style={{ zIndex: 5 }}>
          <Box style={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 150 }}>
            <MultiSelect
              clearable
              label='Категория'
              placeholder='Категория'
              data={state.categoryOptions}
              onChange={(value) => {
                state.setCategorySelected(value);
                state.setFilter('category', value);
              }}
              value={state.categorySelected}
            />
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 150 }}>
            <Text size='sm' color='grayDefault'>
              Тема
            </Text>
            <MultiSelect
              clearable
              label='Тема'
              placeholder='Тема'
              data={state.themeOptions}
              value={state.themeSelected}
              onChange={(value) => {
                state.setThemeSelected(value);
                state.setFilter('theme', value);
              }}
            />
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 150 }}>
            <MultiSelect
              clearable
              label='Дивизион'
              placeholder='Дивизион'
              data={state.divisionOptions}
              value={state.divisionSelected}
              onChange={(value) => {
                state.setDivisionSelected(value);
                state.setFilter('division', value);
              }}
            />
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 150 }}>
            <MultiSelect
              clearable
              label='Компания'
              placeholder='Компания'
              data={state.companyOptions}
              value={state.companySelected}
              onChange={(value) => {
                state.setCompanySelected(value);
                state.setFilter('company', value);
              }}
            />
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 226 }}>
            <DatePickerInput
              label='Период создания'
              type='range'
              locale='ru'
              placeholder='Выберите период'
              valueFormat={formatDate}
              value={state.valueDate}
              clearable
              onChange={(value) => {
                state.setValueDate(value);
                state.setDateFilter(value);
              }}
            />
          </Box>
          <Box
            style={{ display: 'flex', alignItems: 'center', height: 38, maxWidth: 'fit-content' }}
          >
            <Button
              variant='subtle'
              radius='xs'
              size='compact-sm'
              className={styles.resetBtn}
              rightSection={<RotateClockwise size={20} />}
              onClick={state.resetFilters}
            >
              Сбросить
            </Button>
          </Box>

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 24,
              maxWidth: 'fit-content',
            }}
          >
            <Select
              className={styles.pages}
              size='sm'
              defaultValue={String(state.newsLimit)}
              allowDeselect
              data={PAGE_ELEMENTS}
              onChange={(value) => {
                if (value) {
                  dispatch(setNewsLimit(Number(value)));
                  dispatch(setNewsOffset(getOffset(state.page, Number(value))));
                }
              }}
            />
          </Box>
        </Group>

        {state.newsList && state.newsList?.length > 0 && (
          <>
            <ScrollArea h='100%'>
              <NewsTable
                elements={state.newsList}
                sortFiled={state.sortFiled}
                setSortField={state.setSortField}
              />
              <LoadingOverlay visible={state.isLoadingNewsList} />
            </ScrollArea>

            <Box
              style={{
                marginTop: 'auto',
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
                minHeight: '38px',
              }}
            >
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Text size='md' color='greyDefault'>
                  Всего:
                </Text>
                &nbsp;
                <Text size='md'>{state.newsTotal}</Text>
              </Box>
              {state.newsTotal && getPages(state.newsTotal, state.newsLimit) > 1 && (
                <Pagination
                  style={{ right: '0', position: 'absolute', justifyContent: 'flex-end' }}
                  total={getPages(state.newsTotal, state.newsLimit)}
                  onChange={(currentPage) => {
                    dispatch(setNewsOffset(getOffset(currentPage, state.newsLimit)));
                    dispatch(setNewsPage(currentPage));
                  }}
                />
              )}
            </Box>
          </>
        )}

        {!state.newsList?.length && (
          <Box style={{ position: 'relative', height: '100%' }}>
            <LoadingOverlay visible={state.isLoadingNewsList} />

            {!state.isLoadingNewsList && (
              <Center style={{ height: '100%' }}>
                <Text ta='center'>У вас нет новостных шаблонов</Text>
              </Center>
            )}
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default NewsContainer;
