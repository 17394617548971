import { useRef } from 'react';
import { Box, Group, Radio } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { Editor } from '@tiptap/react';

import { handleAddSnippetData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper';
import { useGetSnippetData } from '@/ui/containers/ContentEditor/snippets/hooks/useGetSnippetData';
import { EHtmlSnippetFields } from '@/ui/containers/ContentEditor/snippets/HtmlSnippet/HtmlSnippet.constants';
import { getHtmlBlockDefaultValues } from '@/ui/containers/ContentEditor/snippets/HtmlSnippet/HtmlSnippet.helpers';
import { htmlBlockSchema } from '@/ui/containers/ContentEditor/snippets/HtmlSnippet/HtmlSnippet.schema';
import { SnippetModal } from '@/ui/containers/ContentEditor/snippets/SnippetModal';
import { TextEditor } from '@/ui/containers/ContentEditor/snippets/TextSnippetModal/TextEditor';

export const HtmlSnippet = () => {
  const { defaultValues, snippetData, handleClose } = useGetSnippetData('html');
  const editorRef = useRef<Editor | undefined | null>();

  const form = useForm({
    initialValues: getHtmlBlockDefaultValues(defaultValues),
    validate: yupResolver(htmlBlockSchema),
  });

  return (
    <SnippetModal
      isOpen
      isNew={snippetData?.isNew}
      onClose={handleClose}
      title='HTML-блок'
      handleClickBackBtn={handleClose}
      onSubmit={form.onSubmit(({ isFullWidth, ...values }) => {
        return handleAddSnippetData(
          'html',
          {
            ...values,
            content: editorRef.current?.getHTML() || '',
            isFullWidth: /^true$/i.test(isFullWidth),
          },
          handleClose,
          snippetData,
        );
      })}
    >
      <Radio.Group
        label='На всю ширину страницы'
        {...form.getInputProps(EHtmlSnippetFields.IS_FULL_WIDTH)}
        style={{ gridColumn: 'span 12' }}
      >
        <Group>
          <Radio value='true' label='Да' />
          <Radio value='false' label='Нет' />
        </Group>
      </Radio.Group>

      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexDirection: 'column',
          gridColumn: 'span 12',
          gap: 24,
          minHeight: 500,
          position: 'relative',
        }}
      >
        <TextEditor
          placeholder='Введите код'
          defaultTextValue={defaultValues?.content}
          editorRef={editorRef}
        />
      </Box>
    </SnippetModal>
  );
};
