import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Group, Paper, Title } from '@mantine/core';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';
import { usePermission } from '@/hooks/usePermissions';

import { CustomTable } from '@/ui/components/CustomTable/CustomTable';
import { MaterialsActiveModal } from '@/ui/containers/MaterialsContainer/Components/MaterialsActiveModal';
import { MaterialsFilter } from '@/ui/containers/MaterialsContainer/Components/MaterialsFilter';
import {
  useGetMaterialsList,
  useMaterialsTableData,
} from '@/ui/containers/MaterialsContainer/Materials.hooks';

import styles from './Materials.module.scss'

import {
  getMaterialsRequest,
  materialsFilterSelector,
  setMaterialsFilter,
} from '@/store/slices/materials/materials';

export const Materials = () => {
  const canCreate = usePermission('ARTICLE_CREATE');
  const dispatch = useAppDispatch();
  const { tableData, columns, status, total } = useMaterialsTableData();
  const { limit, page } = useAppSelector(materialsFilterSelector);
  const navigate = useNavigate();
  const getMaterialsList = useGetMaterialsList(0);

  const onPageChange = (value: number) => {
    dispatch(setMaterialsFilter({ offset: (value - 1) * limit, page: value }));
    getMaterialsList();
  };

  const onChangeLimit = (value: number) => {
    if (!value) return;
    dispatch(setMaterialsFilter({ limit: value }));
    getMaterialsList();
  };

  useEffect(() => {
    dispatch(getMaterialsRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '96vh',
        overflowY: 'hidden',
      }}
    >
      <Container fluid mb={16} style={{ marginLeft: 'initial', marginRight: 'initial' }}>
        <Group justify='space-between'>
          <Title order={1}>Материалы</Title>
          {canCreate && (
            <Button style={{ width: '200px' }} onClick={() => navigate('/materials/new')}>
              Создать
            </Button>
          )}
        </Group>
      </Container>
      <Paper
        radius={8}
        p={24}
        pb={12}
        shadow='xs'
        className={styles.tableWrapper}
      >
        <MaterialsFilter />
        <CustomTable
          onChangePagination={onPageChange}
          onChangeLimit={onChangeLimit}
          limit={limit}
          noData='У вас нет новостных шаблонов'
          total={total}
          currentPage={page}
          status={status}
          data={tableData}
          columns={columns}
        />
      </Paper>
      <MaterialsActiveModal />
    </Box>
  );
};
