import { FC } from 'react';
import { Box, Button, Modal, NumberInput, Text, Title } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { useModifiedViews } from '@/api/news/hooks/useModifiedViews';

import { INewsFormFields } from '@/ui/containers/NewsDetailInfo';
import { ENewsFormFields } from '@/ui/containers/NewsDetailInfo/NewsForm/NewsForm.constants';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  form: UseFormReturnType<INewsFormFields>;
  isNewNews: boolean;
  newsUuid?: string;
}
export const EditViewsPopup: FC<IProps> = ({ isOpen, onClose, form, isNewNews, newsUuid }) => {
  const { isLoading, modifiedViews } = useModifiedViews();

  const handleClick = () => {
    return isNewNews
      ? onClose()
      : modifiedViews({
          uuid: newsUuid || '',
          modifiedViewsCount: form.values[ENewsFormFields.MODIFIED_VIEWS_COUNT],
        }).then(() => onClose());
  };

  return (
    <Modal
      opened={isOpen}
      centered
      onClose={onClose}
      styles={{
        header: {
          marginBottom: 0,
        },
      }}
      size={640}
      title={<Title order={4}>Редактировать просмотры</Title>}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      <Text color='darkest' pt={16} style={{ fontSize: '14px' }}>
        Введите необходимое количество просмотров. Отредактированные просмотры будут отображаться в
        строке “Отредактированные” в информации о новости. Если вы хотите вернуться к прежнему
        количеству просмотров, очиститите поле или нажмите 0
      </Text>

      <NumberInput
        pt={24}
        label='Введите количество'
        style={{ width: '335px' }}
        placeholder='Количество просмотров'
        {...form.getInputProps(ENewsFormFields.MODIFIED_VIEWS_COUNT)}
      />

      <Box pt={64}>
        <Button loading={isLoading} onClick={handleClick} style={{ width: '143px' }}>
          Сохранить
        </Button>
      </Box>
    </Modal>
  );
};
