import { useRef } from 'react';
import { Input, InputBase, Textarea } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { v4 as uuid_v4 } from 'uuid';

import { replaceTempFile } from '@/api';

import { S3_STORAGE_LINK } from '@/constants/common';

import { FileLoader } from '@/ui/components/FileLoader';
import { IFileLoaderOnChangeParams } from '@/ui/components/FileLoader/FileLoader.types';
import { TooltipTextWrapper } from '@/ui/components/TooltipFormWrapper';
import {
  getImageData,
  handleAddSnippetData,
} from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper/SelectSnippetModalWrapper.helpers';
import { useGetSnippetData } from '@/ui/containers/ContentEditor/snippets/hooks/useGetSnippetData';
import { EQuoteSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/QuoteSnippetModal/QuoteSnippetModal.constants';
import { getQuoteSnippetInitialValues } from '@/ui/containers/ContentEditor/snippets/QuoteSnippetModal/QuoteSnippetModal.helpers';
import { quoteSnippetSchema } from '@/ui/containers/ContentEditor/snippets/QuoteSnippetModal/QuoteSnippetModal.schema';
import { SnippetModal } from '@/ui/containers/ContentEditor/snippets/SnippetModal';

import styles from './QuoteSnippetModal.module.scss';

export const QuoteSnippetModal = () => {
  const { defaultValues, snippetData, handleClose } = useGetSnippetData('quote');
  const sessionID = useRef(uuid_v4());

  const form = useForm({
    initialValues: getQuoteSnippetInitialValues(defaultValues),
    validate: yupResolver(quoteSnippetSchema),
  });

  const handleFileLoaderChange = ({ id, path, name }: IFileLoaderOnChangeParams) => {
    form.values[EQuoteSnippetFormFields.PHOTO] = {
      id,
      path,
      name,
    };
  };

  const handleFileLoaderRemove = () => {
    form.values[EQuoteSnippetFormFields.PHOTO] = {
      id: '',
      path: '',
      name: '',
    };
  };

  const imgData = getImageData({
    id: form.values[EQuoteSnippetFormFields.PHOTO]?.id,
    path: form.values[EQuoteSnippetFormFields.PHOTO]?.path.replace(S3_STORAGE_LINK as string, ''),
    name: form.values[EQuoteSnippetFormFields.PHOTO]?.name,
  });

  return (
    <SnippetModal
      isOpen
      isNew={snippetData?.isNew}
      onClose={handleClose}
      title='Цитата'
      handleClickBackBtn={handleClose}
      onSubmit={form.onSubmit(async (values) => {
        values[EQuoteSnippetFormFields.ID] = defaultValues?.id || uuid_v4();
        if (values[EQuoteSnippetFormFields.PHOTO]?.id) {
          await replaceTempFile({
            query: {
              sessionId: sessionID.current,
              ids: [values[EQuoteSnippetFormFields.PHOTO].id],
            },
          });
        }
        return handleAddSnippetData('quote', values, handleClose, snippetData);
      })}
    >
      <InputBase
        label={
          <TooltipTextWrapper
            label='Автор цитаты'
            text='Используется также для заполнения SEO-тега name у изображения'
          />
        }
        placeholder='Введите автора цитаты'
        style={{ gridColumn: 'span 6' }}
        {...form.getInputProps(EQuoteSnippetFormFields.AUTHOR)}
      />

      <InputBase
        label='Описание автора'
        placeholder='Введите описание автора'
        style={{ gridColumn: 'span 6' }}
        {...form.getInputProps(EQuoteSnippetFormFields.ABOUT)}
      />

      <Textarea
        label={
          <TooltipTextWrapper
            label='Текст цитаты'
            text='Используется также для заполнения SEO-тега name у изображения'
          />
        }
        placeholder='Введите текст цитаты'
        className={styles.textarea}
        {...form.getInputProps(EQuoteSnippetFormFields.TEXT)}
      />

      <Input.Wrapper label='Фото' style={{ gridColumn: '1 / 7', gridRow: 'span 2' }}>
        <FileLoader
          savedFormFiles={imgData}
          sessionID={sessionID.current}
          onChange={handleFileLoaderChange}
          onRemove={handleFileLoaderRemove}
          placeholder='Перетащите или добавьте изображение. Изображение должно быть не более 1000 пикс. в высоту'
        />
      </Input.Wrapper>

      <InputBase
        label={
          <TooltipTextWrapper
            label='Описание'
            text='Используется также для заполнения SEO-тега name у изображения'
          />
        }
        placeholder='Введите alt'
        style={{ gridColumn: 'span 6' }}
        {...form.getInputProps(EQuoteSnippetFormFields.ALT)}
      />

      <InputBase
        label={
          <TooltipTextWrapper
            label='Название'
            text='Используется также для заполнения SEO-тега name у изображения'
          />
        }
        placeholder='Введите title'
        style={{ gridColumn: 'span 6' }}
        {...form.getInputProps(EQuoteSnippetFormFields.TITLE)}
      />
    </SnippetModal>
  );
};
