import React, { FC, useMemo } from 'react';
import { Box, Button, InputBase } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useInputState } from '@mantine/hooks';
import { NewsSnippet, SnippetValues } from '@prosv/core/types/snippets';

import { tryGetNewsByLink } from '@/api/news/news';

import { formatDate } from '@/utils/formatDate';

import { CustomTable } from '@/ui/components/CustomTable/CustomTable';
import { useNewsTableData } from '@/ui/containers/ContentEditor/snippets/NewsSnippetModal/hooks/News.hooks';

interface IProps {
  form: UseFormReturnType<SnippetValues<NewsSnippet>>;
}

const CustomNewsUI: FC<IProps> = ({ form }) => {
  const { columns } = useNewsTableData();
  const [value, setValue] = useInputState('');

  const setNewsToTable = async () => {
    const parsedLink = value.replace(/\/$/, '').split('/');
    const linkSlug = parsedLink[parsedLink.length - 1];
    const news = await tryGetNewsByLink('user', linkSlug);
    if (form.values.news && !form.values.news.find((item) => item.uuid === news.uuid)) {
      form.setValues({ ...form.values, news: [...form.values.news, news] });
      setValue('');
    }
  };

  const deleteNews = (data: {
    header: string;
    category: string;
    theme: string;
    company: string;
    create_date: string;
    public_date: string;
    id: string;
  }) => {
    if (form.values.news) {
      form.setValues({
        ...form.values,
        news: form.values.news.filter((item) => item.uuid !== data.id),
      });
    }
  };

  const tableNews = useMemo(() => {
    return form.values.news?.map((news) => ({
      header: news.title,
      category: news.category?.name,
      theme: news.theme?.name,
      company: news.company?.name,
      create_date: formatDate(news.dateActiveFrom.value),
      public_date: formatDate(news.dateActiveTo?.value),
      id: news.uuid,
    }));
  }, [form.values.news]);

  return (
    <div style={{ gridColumn: 'span 12' }}>
      <Box style={{ display: 'flex', alignItems: 'flex-end', gap: '16px' }}>
        <InputBase
          value={value}
          onChange={setValue}
          withAsterisk
          label='Ссылка'
          placeholder='Ссылка на новость'
          style={{ width: '100%' }}
        />
        <Button px={60} style={{ fontSize: 16 }} onClick={setNewsToTable}>
          Добавить
        </Button>
      </Box>
      {tableNews && tableNews.length > 0 && (
        <CustomTable
          status='loaded'
          data={tableNews}
          columns={columns}
          noFooter
          onRenderClick={deleteNews}
        />
      )}
    </div>
  );
};

export const CustomNews = React.memo(CustomNewsUI);
