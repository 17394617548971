import React from 'react';
import { Box, useMantineTheme } from '@mantine/core';

type TGridProps = {
  size: 1 | 2 | 3 | 4;
};

export const GridContent: React.FC<TGridProps> = ({ size }) => {
  const theme = useMantineTheme();

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: 78,
        padding: 8,
        width: '100%',
        gap: 8,
        borderRadius: 4,
        backgroundColor: theme.colors.greyBG[9],
      }}
    >
      {new Array(size).fill(null).map((item, index) => {
        return (
          <Box
            key={index}
            style={{
              width: '100%',
              height: '100%',
              borderRadius: 4,
              backgroundColor: theme.colors.greyLight[9],
            }}
          />
        );
      })}
    </Box>
  );
};
