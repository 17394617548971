import { useMemo } from 'react';
import { DictionaryItem } from '@prosv/core/types';

import { useAppSelector } from '@/hooks/useAppSelector';

import { selectDictionaryOptions } from './dictionary.selectors';
import { dictionaryApiSlice } from './dictionary.slices';
import { DictionaryItemIdField } from './dictionary.types';

export const {
  useGetDictionariesQuery: useDictionaries,
  useGetDictionaryItemsQuery: useDictionaryItems,
  useGetDictionaryItemQuery: useDictionaryItem,
  useCreateDictionaryItemMutation: useCreateDictionaryItem,
  useUpdateDictionaryItemMutation: useUpdateDictionaryItem,
} = dictionaryApiSlice;

export const useDictionary = (code?: string) => {
  const { data: items, ...rest } = useDictionaries();

  const data = useMemo(
    () => (code ? items?.find((item) => item.code === code) : undefined),
    [code, items],
  );

  return { data, ...rest };
};

export function useDictionaryOptions(
  code: string,
  idFieldOrSelected: DictionaryItemIdField | DictionaryItem | DictionaryItem[] = 'id',
  selected?: DictionaryItem | DictionaryItem[],
) {
  let idField: DictionaryItemIdField = 'id';

  if (typeof idFieldOrSelected === 'string') {
    idField = idFieldOrSelected;
  } else {
    selected = idFieldOrSelected;
  }

  const apiData = useAppSelector(selectDictionaryOptions(code, idField));

  const data = useMemo(() => {
    const res = [...apiData];

    if (selected) {
      (Array.isArray(selected) ? selected : [selected]).forEach((item) => {
        if (!res.some(({ value }) => value === item.id)) {
          res.push({ value: item.id, label: item.name });
        }
      });
    }

    return res;
  }, [apiData, selected]);

  return {
    ...useDictionaryItems({ code }),
    data,
  };
}
