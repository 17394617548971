import { FC, useState } from 'react';
import { ActionIcon, Box, Checkbox, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { NewsDetailsPayload } from '@prosv/core/types';
import { Pencil } from 'tabler-icons-react';

import { INewsCreationState } from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.types';
import { INewsFormFields } from '@/ui/containers/NewsDetailInfo';
import { EditViewsPopup } from '@/ui/containers/NewsDetailInfo/EditViewsPopup';
import { ENewsFormFields } from '@/ui/containers/NewsDetailInfo/NewsForm/NewsForm.constants';
import { getDate } from '@/ui/containers/NewsDetailInfo/NewsGeneralInfo/NewsGeneralInfo.helpers';

interface IProps {
  isNewNews: boolean;
  form: UseFormReturnType<INewsFormFields>;
  news: NewsDetailsPayload | null;
  permissions: INewsCreationState['permissions'];
}
export const NewsGeneralInfo: FC<IProps> = ({
  isNewNews,
  form,
  news,
  permissions: { canUpdateViewsCount: canUdateViewsCount },
}) => {
  const [showModalWindow, setShowModalWindow] = useState(false);
  const numberOfViews = isNewNews ? 0 : news?.viewsCount;

  return (
    <Box
      style={{
        borderRadius: '6px',
        backgroundColor: '#F6F8F8',
        border: '1px solid #E8ECF0',
        padding: '16px 24px',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        rowGap: '14px',
        columnGap: '4px',
        width: '320px',
      }}
    >
      {!isNewNews && (
        <>
          <Text color='greyDark' style={{ gridColumn: 'span 2' }}>
            ID: {news?.id}
          </Text>

          <Text color='black' style={{ gridColumn: 'span 2', fontWeight: 600, paddingTop: '14px' }}>
            Основная информация
          </Text>

          <Text color='greyDark' style={{ paddingTop: '6px' }}>
            Дата создания:
          </Text>
          <Text style={{ textAlign: 'right' }}>{getDate(news?.meta.createDate?.value || '')}</Text>

          <Text color='greyDark'>Автор:</Text>
          <Text style={{ textAlign: 'right' }}>Панкина Валентина Александровна</Text>
        </>
      )}

      <Text color='black' style={{ fontWeight: 600 }}>
        Просмотры
      </Text>
      <Text style={{ textAlign: 'right' }}>{numberOfViews}</Text>

      <Text color='greyDark' style={{ paddingTop: '6px' }}>
        Отредактированные:
      </Text>
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '4px' }}>
        {form.values[ENewsFormFields.MODIFIED_VIEWS_COUNT]}
        {canUdateViewsCount && (
          <ActionIcon>
            <Pencil width={18} onClick={() => setShowModalWindow(true)} />
          </ActionIcon>
        )}
      </Box>

      <Checkbox
        label='Показать просмотры'
        checked={form.values[ENewsFormFields.IS_DISPLAY_VIEWS]}
        style={{ gridColumn: 'span 2' }}
        {...form.getInputProps(ENewsFormFields.IS_DISPLAY_VIEWS)}
      />

      <EditViewsPopup
        newsUuid={news?.uuid}
        isNewNews={isNewNews}
        form={form}
        isOpen={showModalWindow}
        onClose={() => setShowModalWindow(false)}
      />
    </Box>
  );
};
