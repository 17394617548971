import React from 'react';
import { ActionIcon, Box, Popover } from '@mantine/core';
import { Edit } from 'tabler-icons-react';

import { useOutsideClick } from '@/hooks/useOutsideClick';

import { useHandlersMoveRowButton } from './EditRowBtn.handlers';
import { useStateEditRowBtn } from './EditRowBtn.state';
import { DropDownItem } from './EditRowBtn.styles';
import { TMoveRowButtonProps } from './EditRowBtn.types';

import styles from './EditRowBtn.module.scss';

export const EditRowBtn: React.FC<TMoveRowButtonProps> = ({ rowId }) => {
  const target = React.useRef<HTMLDivElement | null>(null);

  const state = useStateEditRowBtn({ rowId });
  const handlers = useHandlersMoveRowButton({ rowId });

  useOutsideClick(target, () => state.setOpened(false));

  return (
    <div className={styles.root}>
      <Popover withArrow shadow='md' position='right-start' opened={state.opened}>
        <Popover.Target>
          <ActionIcon
            size={20}
            color='grayDefault'
            variant='transparent'
            onClick={() => state.setOpened(true)}
          >
            <Edit />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown p={4}>
          <Box
            style={{ display: 'flex', flexDirection: 'column', width: 224, gap: 4 }}
            onClick={() => state.setOpened(false)}
            ref={target}
          >
            <DropDownItem onClick={handlers.handleRemoveRow}>Удалить</DropDownItem>
            <DropDownItem onClick={handlers.handleEditRow}>Редактировать</DropDownItem>
          </Box>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};
