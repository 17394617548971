import React from 'react';
import { Input, InputBase, Text } from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form';
import { DownloadFile, DownloadSnippet, SnippetValues } from '@prosv/core/types/snippets';
import { Trash } from 'tabler-icons-react';

import { FileLoader } from '@/ui/components/FileLoader';
import { IFileLoaderOnChangeParams } from '@/ui/components/FileLoader/FileLoader.types';
import { getImageData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper/SelectSnippetModalWrapper.helpers';
import { EDownloadsSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/DownloadsSnippetModal/DownloadsSnippetModal.constants';

type TDownloadsSnippetFormItemProps = {
  index: number;
  form: UseFormReturnType<SnippetValues<DownloadSnippet>>;
  file: DownloadFile;
  sessionID: string;
};

const DownloadsSnippetFormItemUI: React.FC<TDownloadsSnippetFormItemProps> = ({
  index,
  sessionID,
  form,
}) => {
  const fileData = form.values.files?.[index].file;
  const imgData = getImageData({
    id: fileData?.id,
    name: fileData?.fileName,
    path: fileData?.filePath.replace(process.env.MEDIA_STORAGE_URL as string, ''),
  });
  const handleChange = ({ id, size, path, type, name }: IFileLoaderOnChangeParams) => {
    if (form.values.files?.[index]) {
      form.values.files[index] = {
        name: form.values.files[index].name,
        file: {
          id: id,
          fileSize: size,
          fileType: type,
          filePath: path,
          fileName: name,
        },
      };
    }
  };

  const handleRemove = () => {
    if (form.values.files?.[index]) {
      form.values.files[index] = {
        name: form.values.files[index].name,
        file: {
          id: '',
          fileSize: '',
          fileType: '',
          filePath: '',
          fileName: '',
        },
      };
    }
  };

  const handleRemoveFile = () => {
    form.values.files?.splice(index, 1);
    form.setValues(form.values);
  };

  return (
    <div
      style={{
        gridColumn: 'span 12',
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gap: '24px',
      }}
    >
      <Text
        size='sm'
        c='black'
        ta='center'
        style={{
          textTransform: 'none',
          fontWeight: 500,
          gridColumn: 'span 12',
          textAlign: 'start',
          marginTop: 20,
          alignItems: 'center',
          display: 'flex',
          gap: 10,
        }}
      >
        Файл {index + 1}
        {index !== 0 && (
          <Trash size={20} color='gray' onClick={handleRemoveFile} style={{ cursor: 'pointer' }} />
        )}
      </Text>

      <InputBase
        withAsterisk
        label='Название для отображения на сайте'
        placeholder='Введите название материала'
        style={{ gridColumn: '1 / 7' }}
        {...form.getInputProps(`files.${index}.${EDownloadsSnippetFormFields.NAME}`)}
      />

      <Input.Wrapper
        withAsterisk
        label='Поле загрузки одного файла'
        style={{ gridColumn: 'span 12', gridRow: 'span 2' }}
        {...form.getInputProps(`files.${index}.file.id`)}
      >
        <FileLoader
          savedFormFiles={imgData}
          sessionID={sessionID}
          accept='docs'
          placeholder='Перетащите или добавьте файл'
          onChange={handleChange}
          onRemove={handleRemove}
        />
      </Input.Wrapper>
    </div>
  );
};

export const DownloadsSnippetFormItem = React.memo(DownloadsSnippetFormItemUI, (prev, next) => {
  return prev.file.name === next.file.name && prev.file.file === next.file.file;
});
