import React from 'react';
import { Badge, Text } from '@mantine/core';

type TActivityProps = {
  status: 0 | 1 | 2;
  onChangeStatus?: () => void;
  canUpdate?: boolean;
};

export const ActiveStatus: React.FC<TActivityProps> = ({
  status,
  onChangeStatus,
  canUpdate = true,
}) => {
  return (
    <>
      <Badge
        color={{ 0: 'greyLine', 1: 'green', 2: 'yellow' }[status]}
        size='lg'
        radius='sm'
        variant='filled'
        py={0}
        px={10}
        style={{ cursor: canUpdate ? 'pointer' : 'default', width: 98 }}
        onClick={canUpdate ? onChangeStatus : undefined}
      >
        <Text size='sm' c='black' ta='center' style={{ textTransform: 'none', fontWeight: 400 }}>
          {{ 0: 'Неактивно', 1: 'Активно', 2: 'Частично' }[status]}
        </Text>
      </Badge>
    </>
  );
};
