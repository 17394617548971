import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '@mantine/core';
import { Dictionary } from '@prosv/core/types/bff/dictionary';

interface IProps {
  items: Dictionary[];
}

const TableBody: FC<IProps> = ({ items }) => {
  const navigate = useNavigate();

  return (
    <Table.Tbody>
      {items.map((item, index) => {
        return (
          <Table.Tr
            key={index}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`/dictionary/${item.code}`)}
          >
            <Table.Td>{item.code}</Table.Td>
            <Table.Td>{item.name}</Table.Td>
          </Table.Tr>
        );
      })}
    </Table.Tbody>
  );
};

export default TableBody;
