import { FC, PropsWithChildren } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Box, Container } from '@mantine/core';
import { IconGripVertical } from '@tabler/icons';

import {
  IDragCollectProps,
  IDraggableItem,
  IDropHandler,
} from '@/ui/components/DnDBlock/DnDBlock.types';

import styles from './DnDBlock.module.scss';

interface IProps {
  id: string;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
}

export const DnDBlock: FC<PropsWithChildren<IProps>> = ({ id, index, moveItem, children }) => {
  const [{ isDragging }, drag] = useDrag<IDraggableItem, IDragCollectProps, IDragCollectProps>({
    type: 'DRAGGABLE_COMPONENT',
    item: { type: 'DRAGGABLE_COMPONENT', id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop<IDraggableItem, IDropHandler, unknown>({
    accept: 'DRAGGABLE_COMPONENT',
    hover(item) {
      moveItem(item.index, index);
      item.index = index;
    },
  });

  const opacity = isDragging ? 0.5 : 1;

  return (
    <Box
      style={{
        gridColumn: 'span 12',
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gap: '24px',
        padding: 16,
        position: 'relative',
        opacity: opacity,
        border: '2px solid #EDF1F3',
      }}
    >
      <Container ref={(node) => drag(drop(node))} className={styles.container}>
        <IconGripVertical size='16px' color='#8DA2B5' />
      </Container>
      {children}
    </Box>
  );
};
