import React from 'react';
import { Box, Button, Group, Modal, Text } from '@mantine/core';

import { TextEditor } from './TextEditor';
import { useHandlersTextModal } from './TextSnippetModal.handlers';
import { useStateTextModal } from './TextSnippetModal.state';

import { ReactComponent as ArrowBack } from '@/assets/icons/arrow-back.svg';

const TextModalUI: React.FC = () => {
  const state = useStateTextModal();
  const handlers = useHandlersTextModal(state);

  return (
    <Modal
      opened
      onClose={handlers.handleClose}
      title={
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ cursor: 'pointer' }} pt={6} pl={6} pr={12} onClick={handlers.handleClose}>
            <ArrowBack width={16} height={18} />
          </Box>

          <Text fz={24} ta='left' fw={600}>
            Текст
          </Text>
        </Box>
      }
      size={1300}
      withCloseButton
    >
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexDirection: 'column',
          gap: 24,
          minHeight: 600,
          position: 'relative',
        }}
      >
        <TextEditor defaultTextValue={state.defaultTextValue} editorRef={state.editorRef} />
      </Box>
      <Group justify='flex-start' pt={32}>
        <Button onClick={handlers.handleClickAddBtn} px={60} style={{ fontSize: 16 }}>
          Добавить
        </Button>
      </Group>
    </Modal>
  );
};

export const TextSnippetModal = React.memo(TextModalUI);
