import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, useMantineTheme } from '@mantine/core';
import { Pencil } from 'tabler-icons-react';

import { getItemPermissions } from '@/utils/permissions';
import { setFormatDate } from '@/utils/setFormatDate';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import { setSortDirection } from '@/ui/components/CustomTable/CustomTable.helppers';
import { ITableColumns } from '@/ui/components/CustomTable/CustomTable.types';

import {
  locationOptions,
  pageOptions,
  siteOptions,
} from '@/pages/Banners/BannersListing/BannerListing.constants';
import { bannerFiltersSelector } from '@/store/slices/banners/bannersSelectors';
import {
  bannersFilterActions,
  CreateFilterSlice,
  IBannerResponse,
} from '@/store/slices/banners/bannersSlice';

const ActiveCell = ({ isActive }: IBannerResponse) => {
  return (
    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
      <div
        style={{
          width: 6,
          height: 6,
          backgroundColor: !isActive ? 'red' : '#02C39A',
          borderRadius: 999,
        }}
      />
      {isActive ? 'Да' : 'Нет'}
    </div>
  );
};

export const useColumns = (getBannersListing: (params: CreateFilterSlice) => void) => {
  const navigate = useNavigate();
  const filters = useAppSelector(bannerFiltersSelector);
  const theme = useMantineTheme();
  const { setFilter } = bannersFilterActions;
  const dispatch = useAppDispatch();

  const sortDirection = setSortDirection(filters.sortDirection);

  const onSortActive = useCallback(() => {
    dispatch(
      setFilter({
        sortField: 'isActive',
        sortDirection,
      }),
    );

    getBannersListing({ ...filters, sortDirection, sortField: 'isActive' });
  }, [getBannersListing, sortDirection]);

  const columns: ITableColumns<IBannerResponse>[] = [
    {
      title: 'Заголовок',
      field: 'title',
    },
    {
      title: 'Страница/Раздел',
      field: 'page',
      render: ({ positions }) => {
        return (
          pageOptions
            .filter((el) => positions.map(({ page }) => page).includes(el.value))
            .map(({ label }) => label)
            .join(', ') || ' - '
        );
      },
    },
    {
      title: 'Расположение',
      field: 'place',
      render: ({ positions }) => {
        const flatPositions = positions.map(({ places }) => places).flat();
        return (
          locationOptions
            .filter((el) => flatPositions.includes(el.value))
            .map(({ label }) => label)
            .join(', ') || ' - '
        );
      },
    },
    {
      title: 'Сайт',
      field: 'site',
      render: ({ site }) => siteOptions.find((el) => el.value === site)?.label ?? ' - ',
    },
    {
      title: 'Включен',
      sort: filters?.sortDirection,
      sortAction: onSortActive,
      field: 'isActive',
      render: ActiveCell,
    },
    {
      title: 'Активен  с',
      field: 'active',
      render: ({ dateActiveFrom }) => {
        if (!dateActiveFrom) return ' - ';
        return setFormatDate(dateActiveFrom.value, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      },
    },
    {
      title: 'Активен по',
      field: 'public_date',
      render: ({ dateActiveTo }) => {
        if (!dateActiveTo) return ' - ';
        return setFormatDate(dateActiveTo.value, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      },
    },
    {
      title: 'Позиция',
      field: 'header',
      render: ({ sort }) => sort ?? ' - ',
    },
    {
      title: '',
      style: { width: 50 },
      field: 'actions',
      render: ({ uuid: id, permissions }) => {
        const { canGet, canUpdate, canUpdateActivity } = getItemPermissions('BANNER_', permissions);
        return canGet || canUpdate || canUpdateActivity ? (
          <Button
            onClick={() => {
              navigate(`/banners/${id}`);
            }}
            name='Редактировать'
            title='Редактировать'
            variant='subtle'
            size='compact-sm'
            style={{ position: 'static' }}
          >
            <Pencil size={22} color={theme.colors.greyLight[9]} />
          </Button>
        ) : null;
      },
    },
  ];
  return columns;
};
