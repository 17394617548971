import { FC, useEffect } from 'react';
import { Box } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { NewsDetailsPayload } from '@prosv/core/types';
import { isEqual } from 'lodash';

import {
  setSelectorDefaultFormData,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';
import { INewsCreationState } from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.types';
import { newsFormSchema } from '@/ui/containers/NewsDetailInfo/NewsDetailInfo.schema';
import { INewsFormFields, NewsForm } from '@/ui/containers/NewsDetailInfo/NewsForm';
import { NEWS_CREATION_FORM_ID } from '@/ui/containers/NewsDetailInfo/NewsForm/NewsForm.constants';
import { getDefaultValues } from '@/ui/containers/NewsDetailInfo/NewsForm/NewsForm.helpers';
import { NewsGeneralInfo } from '@/ui/containers/NewsDetailInfo/NewsGeneralInfo';

interface IProps {
  handleSubmit: (values: INewsFormFields) => void;
  isNewNews: boolean;
  news: NewsDetailsPayload | null;
  sessionID: string;
  setIsShouldBlock: (isShouldBlock: boolean) => void;
  defaultFormData: INewsFormFields | null;
  setTrySave: (value: boolean) => void;
  trySaveNews: boolean;
  permissions: INewsCreationState['permissions'];
}

export const NewsDetailInfo: FC<IProps> = ({
  permissions,
  handleSubmit,
  isNewNews,
  news,
  sessionID,
  setIsShouldBlock,
  defaultFormData,
  trySaveNews,
  setTrySave,
}) => {
  const form = useForm({
    initialValues: getDefaultValues(news),
    validate: yupResolver(newsFormSchema),
  });

  const setDefaultFormData = useStoreContentEditor(setSelectorDefaultFormData);

  useEffect(() => {
    setDefaultFormData(form.values);
    return () => {
      setDefaultFormData(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultFormData && !isEqual(form.values, defaultFormData)) {
      setIsShouldBlock(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        gap: '24px',
      }}
    >
      <form
        id={NEWS_CREATION_FORM_ID}
        style={{
          border: '1px solid #EDF1F3',
          borderRadius: '6px',
          padding: '16px 12px',
          overflow: 'scroll',
        }}
        onSubmit={form.onSubmit(handleSubmit)}
      >
        <NewsForm
          permissions={permissions}
          isNewNews={isNewNews}
          form={form}
          news={news}
          handleSubmit={handleSubmit}
          sessionID={sessionID}
          setTrySave={setTrySave}
          trySaveNews={trySaveNews}
        />
      </form>
      <Box>
        <NewsGeneralInfo {...{ news, form, isNewNews, permissions }} />
      </Box>
    </Box>
  );
};
