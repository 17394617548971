import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Button, Text, useMantineTheme } from '@mantine/core';
import { CommonUmkItem } from '@prosv/core/types/bff/umk/admin';
import dayjs from 'dayjs';
import { Pencil } from 'tabler-icons-react';
import { useDebouncedCallback } from 'use-debounce';

import { getItemPermissions } from '@/utils/permissions';

import { formatDate } from '@/constants/common';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import { setSortDirection } from '@/ui/components/CustomTable/CustomTable.helppers';
import { ITableColumns } from '@/ui/components/CustomTable/CustomTable.types';

import { umkListDefaultFilter } from '@/store/slices/umkList/umkList.constants';
import { ReturnedUmkListConfig, UmkListFilterSlice } from '@/store/slices/umkList/umkList.types';
import {
  isUmkListDirtySelector,
  umkListFilterActions,
  umkListFiltersSelector,
} from '@/store/slices/umkList/umkListSlice';

export const useUmkListConfig = (fetch: (params: UmkListFilterSlice) => void) => {
  const dispatch = useAppDispatch();

  const filters = useAppSelector(umkListFiltersSelector);
  const { resetFilters, setFilter, setFilterWithResetCurrentPage } = umkListFilterActions;

  const handleSearchDebounced = useDebouncedCallback((params: UmkListFilterSlice) => {
    fetch({ ...filters, ...params });
  }, 500);

  const actionWithDebounce = (params: UmkListFilterSlice) => {
    dispatch(setFilterWithResetCurrentPage(params));
    handleSearchDebounced(params);
  };
  const isDirty = useAppSelector(isUmkListDirtySelector);

  const onChange = (params: UmkListFilterSlice) => {
    dispatch(setFilter(params));
    fetch({ ...filters, ...params });
  };

  const onReset = () => {
    dispatch(resetFilters());
    fetch(umkListDefaultFilter);
  };

  const onChangeSort = (sortField: string) => {
    const params = {
      sortField,
      sortDirection: setSortDirection(filters.sortDirection),
    };
    onChange({ ...filters, ...params });
  };

  useEffect(() => {
    fetch(filters);
    return () => {
      dispatch(setFilter(umkListDefaultFilter));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { actionWithDebounce, isDirty, onChange, onReset, filters, onChangeSort };
};

export const useUmkListColumns = ({ onChangeSort, filters }: ReturnedUmkListConfig) => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const getSort = useCallback(
    (title: string) => (filters.sortField === title ? filters.sortDirection : undefined),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters.sortDirection],
  );

  const columns: ITableColumns<CommonUmkItem>[] = [
    {
      title: 'ID',
      field: 'id',
      style: { width: '30%' },
    },
    {
      title: 'Название',
      field: 'title',
      style: { width: '30%' },
      sortAction: () => {
        onChangeSort('title');
      },
      sort: getSort('title'),
      render: ({ umk }) => umk.name || ' - ',
    },
    {
      title: 'Активность',
      field: 'isActive',
      style: { minWidth: 120 },

      sortAction: () => {
        onChangeSort('isActive');
      },
      sort: getSort('isActive'),
      render: ({ isActive }) => (
        <Badge
          color={isActive ? 'green' : 'greyLine'}
          size='lg'
          radius='sm'
          variant='filled'
          p={0}
          style={{ width: 88, marginLeft: 8 }}
        >
          <Text size='sm' c='black' ta='center' fw={400} style={{ textTransform: 'none' }}>
            {isActive ? 'Активно' : 'Не активно'}
          </Text>
        </Badge>
      ),
    },
    {
      title: 'Дата создания',
      field: 'createdAt',
      style: { minWidth: 120 },
      position: 'right',
      sortAction: () => {
        onChangeSort('createdAt');
      },
      sort: getSort('createdAt'),
      render: ({ createdAt }) => (createdAt ? dayjs(createdAt).format(formatDate) : ' - '),
    },
    {
      title: 'Дата редактирования',
      field: 'updatedAt',
      position: 'right',
      style: { minWidth: 140 },
      sortAction: () => {
        onChangeSort('updatedAt');
      },
      sort: getSort('updatedAt'),
      render: ({ updatedAt }) => (updatedAt ? dayjs(updatedAt).format(formatDate) : ' - '),
    },

    {
      title: '',
      gridWidth: 'auto',
      field: 'actions',
      render: ({ id, permissions }) => {
        const { canGet, canUpdate, canUpdateActivity } = getItemPermissions(
          'UMK_PAGE_',
          permissions,
        );
        return canGet || canUpdate || canUpdateActivity ? (
          <Button
            onClick={() => {
              navigate(`/umk/${id}`);
            }}
            name='Редактировать'
            title='Редактировать'
            variant='subtle'
            size='compact-sm'
            style={{ position: 'static' }}
          >
            <Pencil size={22} color={theme.colors.greyLight[9]} />
          </Button>
        ) : null;
      },
    },
  ];
  return columns;
};
