import React from 'react';
import { Box, useMantineTheme } from '@mantine/core';
import { SnippetVariant } from '@prosv/core/types/snippets';

import {
  setSelectContentBlockDataSelector,
  setSnippetDataSelector,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';

import { ContentTypeIcon } from './ContentTypeIcon';

type TContentTypeCardProps = {
  type: 'grid' | 'card';
  variant: SnippetVariant;
  cardId: number | null;
  rowId: number | null;
  disabled?: boolean;
};

export const ContentTypeCard: React.FC<TContentTypeCardProps> = ({
  type,
  variant,
  cardId,
  rowId,
  disabled,
}) => {
  const theme = useMantineTheme();

  const setSelectContentBlockData = useStoreContentEditor(setSelectContentBlockDataSelector);
  const setSnippetData = useStoreContentEditor(setSnippetDataSelector);

  const isEmpty = variant === 'empty' || !variant;
  const isGridCardType = type === 'grid';

  const cursor = disabled ? 'not-allowed' : 'pointer';

  const handleClickCard = () => {
    const idIsNotNull = cardId !== null && rowId !== null;

    if (!idIsNotNull || disabled) return;

    if (isGridCardType && isEmpty) {
      setSelectContentBlockData({ cardId, rowId });
    } else {
      setSnippetData({ variant, cardId, rowId });
    }
  };

  return (
    <Box
      onClick={handleClickCard}
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 122,
        minHeight: 122,
        border: isEmpty ? `1px solid ${theme.colors.greyLine[9]}` : 'none',
        borderRadius: 4,
        backgroundColor: isEmpty ? 'white' : theme.colors.greyBG[9],
        cursor,
      }}
    >
      <Box style={{ cursor }}>
        <ContentTypeIcon variant={variant} />
      </Box>
    </Box>
  );
};
