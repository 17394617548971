import { FC } from 'react';
import { Modal, Text } from '@mantine/core';

import { DictionaryItemForm } from '../DictionaryItemForm';

import { DictionaryItemModalProps } from './DictionaryItemModal.types';

export const DictionaryItemModal: FC<DictionaryItemModalProps> = ({
  dictionary,
  item,
  title = item ? 'Редактирование записи' : 'Добавление записи',
  onSuccessSubmit,
  ...rest
}) => (
  <Modal centered size={640} withCloseButton title={<Text fz={24}>{title}</Text>} {...rest}>
    <DictionaryItemForm
      {...{ dictionary, item }}
      onSuccessSubmit={() => {
        onSuccessSubmit?.();
        rest.onClose();
      }}
    />
  </Modal>
);
