import { FC, ReactNode } from 'react';
import { Link, useLocation, useMatch, useResolvedPath } from 'react-router-dom';
import { Group, Text, UnstyledButton, UnstyledButtonProps } from '@mantine/core';

import { setLastRoute } from '@/utils/setLastRoute';

import styles from './LayoutNavbarButton.module.scss';

interface ILayoutNavbarButton extends UnstyledButtonProps {
  label: string;
  to?: string;
  opened?: boolean;
  icon?: ReactNode;
  onClick?: () => void;
}

export const LayoutNavbarButton: FC<ILayoutNavbarButton> = ({
  label,
  to,
  opened = true,
  icon,
  onClick = () => {},
}) => {
  let resolved = useResolvedPath(to ? to : '');
  let match = useMatch({ path: resolved.pathname, end: false });
  const { pathname } = useLocation();

  const onClickHandler = () => {
    if (pathname) {
      setLastRoute(pathname);
    }
    onClick();
  };

  return (
    <>
      {to ? (
        <UnstyledButton
          title={label}
          component={Link}
          to={to}
          className={cn(styles.root, { [styles.active]: match })}
          py={12}
          onClick={onClickHandler}
        >
          <Group gap={10} wrap='nowrap'>
            {icon}
            {opened && (
              <Text className={styles.label} lineClamp={1}>
                {label}
              </Text>
            )}
          </Group>
        </UnstyledButton>
      ) : (
        <UnstyledButton
          title={label}
          className={cn(styles.root, { [styles.active]: match && to })}
          py={12}
          onClick={onClick}
        >
          <Group gap={10} wrap='nowrap'>
            {icon}
            {opened && (
              <Text lineClamp={1} style={{ lineHeight: 1.125, paddingBottom: '2px' }}>
                {label}
              </Text>
            )}
          </Group>
        </UnstyledButton>
      )}
    </>
  );
};

export default LayoutNavbarButton;
