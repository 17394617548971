import { FC } from 'react';
import { Box, Button, Center, Container, LoadingOverlay, Text } from '@mantine/core';
import { LayoutGrid } from 'tabler-icons-react';

import { isLoadingMenusMock } from '@/ui/containers/MenusContainer/MenusContainer.mock';

import { ReactComponent as SearchIcon } from '@/assets/icons/search.svg';

interface IProps {
  addNewItem: () => void;
}
export const MenusContainerNoMenus: FC<IProps> = ({ addNewItem }) => {
  return (
    <Box style={{ position: 'relative', height: '100%' }}>
      <LoadingOverlay visible={isLoadingMenusMock} />
      {!isLoadingMenusMock && (
        <Center style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Container style={{ position: 'relative', width: '100px', height: '80px' }}>
            <SearchIcon style={{ position: 'absolute', top: '-16px', right: '-20px' }} />
            <LayoutGrid size={80} color='#BFCEDB' fill='#BFCEDB' />
          </Container>
          <Text ta='center' color='#66788A'>
            У вас пока нет меню
          </Text>
          <Button onClick={addNewItem} style={{ marginTop: '8px', width: '198px' }}>
            Новый тип
          </Button>
        </Center>
      )}
    </Box>
  );
};
