import { FC } from 'react';
import { Box, Button, Modal, Text, Title } from '@mantine/core';

import { AsyncJobStatus, CatalogTagsAsyncJobStatusResponse } from '@/modules/asyncJob';
import {
  MODAL_DESCRIPTION,
  MODAL_TITLE,
} from '@/modules/catalogTags/components/CatalogTagsModal/CatalogTagsModal.constants';

import styles from './CatalogTagsModal.module.scss';

interface Props {
  isOpen: boolean;
  jobData: CatalogTagsAsyncJobStatusResponse['payload'];
  onClose: () => void;
}

export const CatalogTagsModal: FC<Props> = ({ isOpen, jobData, onClose }) => {
  const status: AsyncJobStatus = jobData.result?.status || jobData.status;
  const title = MODAL_TITLE[status];
  const description = MODAL_DESCRIPTION[status];
  const downloadUrl = `${process.env.MEDIA_STORAGE_URL}${jobData.result?.file?.path}`;

  const isFailStatus = jobData.result?.status === 'fail';

  return (
    <Modal
      centered
      size='sm'
      opened={isOpen}
      onClose={onClose}
      title={
        <Title className={styles.title} ta='center' order={3}>
          {title}
        </Title>
      }
      closeOnEscape
    >
      <Box className={styles.content}>
        {description && <Text ta='center'>{description}</Text>}
        <Box className={styles.btnWrapper}>
          {isFailStatus && (
            <Button className={styles.btn} variant='default' onClick={onClose}>
              Вернуться к загрузке
            </Button>
          )}
          {isFailStatus && (
            <Button
              className={styles.btn}
              component='a'
              target='_blank'
              href={downloadUrl}
              onClick={onClose}
            >
              Скачать файл с ошибками
            </Button>
          )}
          {!isFailStatus && (
            <Button className={styles.btn} onClick={onClose}>
              Ок
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
