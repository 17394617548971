import { FC } from 'react';

import { useDnd } from '@/hooks/useDnd';

import { TRow } from '@/ui/containers/ContentEditor/ContentEditor.types';
import { RowConfiguration } from '@/ui/containers/ContentEditor/Rows/RowConfiguration';
import { useStoreContentEditor } from '@/ui/containers/ContentEditor/store';

import { debouncedUpdateContentData } from './DndRowConfiguration.helpers';

import styles from './DndRowConfiguration.module.scss';

interface Props {
  rowData?: TRow;
  rowId: number;
  index: number;
}

export const DndRowConfiguration: FC<Props> = ({ index, rowData, rowId }) => {
  const move = async (dragIndex: number, hoverIndex: number) => {
    useStoreContentEditor.setState((state) => {
      const grid = state?.savedContentData?.grid;

      if (grid && dragIndex >= 0 && hoverIndex >= 0 && dragIndex !== hoverIndex) {
        const updatedGrid = [...grid];

        const [movedItem] = updatedGrid.splice(dragIndex, 1);

        updatedGrid.splice(hoverIndex, 0, movedItem);

        return {
          savedContentData: {
            ...state.savedContentData,
            grid: updatedGrid,
          },
        };
      }

      return state;
    });
    debouncedUpdateContentData();
  };

  const { ref, handlerId, preview, isDragging } = useDnd({
    type: 'editor-item',
    index,
    move,
    margin: 40,
  });

  return (
    <section
      ref={preview}
      data-handler-id={handlerId}
      className={cn({ [styles.rootDrag]: isDragging })}
    >
      <RowConfiguration rowData={rowData} rowId={rowId} ref={ref} />
    </section>
  );
};
