import { Box, Button, Select, Text, TextInput } from '@mantine/core';
import { DatePickerInput, DatesRangeValue } from '@mantine/dates';
import { IconCalendar, IconSearch } from '@tabler/icons';
import { RotateClockwise } from 'tabler-icons-react';

import { endOfDay } from '@/utils/datetime';

import { formatDate, PAGE_ELEMENTS } from '@/constants/common';

import {
  activeStatus,
  inputStyles,
} from '@/ui/containers/PromotionsContainer/components/PromotionsFilters/PromotionsFilters.constants';

import styles from './PromotionsFilters.module.scss';

import { ReturnedPromotionConfig } from '@/store/slices/promotions/promotions.types';

export const PromotionsFilters = ({
  actionWithDebounce,
  isDirty,
  onChange,
  onReset,
  filters,
}: ReturnedPromotionConfig) => {
  const { title, isActive, dateActiveEnd, dateActiveStart, limit } = filters;
  const onChangeDate = (v: DatesRangeValue, key: 'dateActiveEnd' | 'dateActiveStart') => {
    if (!v[0] && !v[1]) {
      return onChange({ [key]: [null, null] });
    } else if (v[0] && v[1]) {
      return onChange({ [key]: [v[0], endOfDay(v[1])] });
    }
  };

  return (
    <>
      <Box style={{ width: '100%', marginBottom: '16px' }}>
        <TextInput
          value={title}
          leftSection={<IconSearch size={20} />}
          styles={inputStyles}
          onChange={(e) => actionWithDebounce({ title: e.target.value })}
          placeholder='Начните вводить название акции'
        />
      </Box>
      <Box
        mb={24}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <Box style={{ display: 'flex', gap: '24px', flexWrap: 'wrap' }}>
          <Box className={styles.field}>
            <Text size='sm' color='grayDefault'>
              Активность
            </Text>
            <Select
              data={activeStatus}
              clearable
              styles={{ input: { height: 39 } }}
              placeholder='Активность'
              value={typeof isActive === 'undefined' ? null : isActive}
              onChange={(value) => {
                actionWithDebounce({ isActive: value });
              }}
            />
          </Box>
          <Box className={styles.field}>
            <Text size='sm' color='grayDefault'>
              Период начала
            </Text>
            <DatePickerInput
              type='range'
              className={styles.datePicker}
              valueFormat={formatDate}
              locale='ru'
              allowSingleDateInRange
              leftSection={<IconCalendar size={16} />}
              placeholder='Период начала'
              value={dateActiveStart}
              onChange={(e) => onChangeDate(e, 'dateActiveStart')}
            />
          </Box>
          <Box className={styles.field}>
            <Text size='sm' color='grayDefault'>
              Период окончания
            </Text>
            <DatePickerInput
              type='range'
              className={styles.datePicker}
              valueFormat={formatDate}
              locale='ru'
              allowSingleDateInRange
              leftSection={<IconCalendar size={16} />}
              placeholder='Период окончания'
              value={dateActiveEnd}
              onChange={(e) => onChangeDate(e, 'dateActiveEnd')}
            />
          </Box>
        </Box>

        {isDirty && (
          <Box
            style={{ display: 'flex', alignItems: 'center', height: 38, maxWidth: 'fit-content' }}
          >
            <Button
              className={styles.resetBtn}
              variant='subtle'
              radius='xs'
              size='compact-sm'
              rightSection={<RotateClockwise size={20} />}
              onClick={onReset}
            >
              Сбросить
            </Button>
          </Box>
        )}

        <Select
          className={styles.select}
          size='sm'
          value={limit || PAGE_ELEMENTS[0].value}
          onChange={(v) => {
            if (v === limit) return;
            actionWithDebounce({ limit: v as string });
          }}
          label=''
          data={PAGE_ELEMENTS}
        />
      </Box>
    </>
  );
};
