import { FC } from 'react';
import { Box } from '@mantine/core';
import { IconGripVertical } from '@tabler/icons';

import { useDnd } from '@/hooks/useDnd';

import { margin } from './DndListItem.constants';
import { DndListItemProps } from './DndListItem.types';

import styles from './DndListItem.module.scss';

export const DndListItem: FC<DndListItemProps> = ({ type, index, children, move }) => {
  const { ref, handlerId, preview } = useDnd({
    type,
    index,
    move,
    margin,
  });

  return (
    <Box ref={preview} className={styles.root} data-handler-id={handlerId}>
      <Box ref={ref} className={styles.handle}>
        <IconGripVertical size={20} color='#8DA2B5' />
      </Box>

      <Box className={styles.content}>{children}</Box>
    </Box>
  );
};
