import React, { FC, useEffect } from 'react';

import { ContentEditor } from '@/ui/containers/ContentEditor/ContentEditor';
import {
  hasChanges,
  setSelectorDefaultFormData,
  setSelectorDraftId,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';

import { EditorsState } from '@/pages/UmkDetail/UmkDetail.types';

interface Props {
  draftId: string;
  editorName: keyof EditorsState;
  updateEditorModified: (editorName: keyof EditorsState, isModified: boolean) => void;
}

export const ContentEditorWrapper: FC<Props> = ({ draftId, editorName, updateEditorModified }) => {
  const setDraftIde = useStoreContentEditor(setSelectorDraftId);
  const setDefaultFormData = useStoreContentEditor(setSelectorDefaultFormData);
  const isModified = useStoreContentEditor(hasChanges);

  useEffect(() => {
    setDraftIde(draftId);
    return () => {
      setDefaultFormData(null);
      setDraftIde(null);
    };
  }, [draftId, setDefaultFormData, setDraftIde]);

  useEffect(() => {
    updateEditorModified(editorName, isModified);
  }, [editorName, isModified, updateEditorModified]);

  return <ContentEditor />;
};
