import { TBreadcrumbsExtItem } from '@/types';

import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Anchor, AnchorProps } from '@mantine/core';
import { nanoid } from 'nanoid';

interface IBreadcrumbsExtItem extends AnchorProps {
  item: TBreadcrumbsExtItem;
}

const BreadcrumbsExtItem: FC<IBreadcrumbsExtItem> = ({ item, ...props }) => {
  return (
    <>
      {item.url && (
        <Anchor key={nanoid()} c='dimmed' {...props} component={Link} to={item.url}>
          {item.name}
        </Anchor>
      )}
      {!item.url && (
        <Anchor key={nanoid()} c='dimmed' {...props} style={{ pointerEvents: 'none' }}>
          {item.name}
        </Anchor>
      )}
    </>
  );
};

export default BreadcrumbsExtItem;
