import React, { useRef } from 'react';
import { Checkbox, Input, InputBase } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { v4 as uuid_v4 } from 'uuid';

import { replaceTempFile } from '@/api';

import { FileLoader } from '@/ui/components/FileLoader';
import { IFileLoaderOnChangeParams } from '@/ui/components/FileLoader/FileLoader.types';
import { TooltipTextWrapper } from '@/ui/components/TooltipFormWrapper';
import {
  getImageData,
  handleAddSnippetData,
} from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper/SelectSnippetModalWrapper.helpers';
import { useGetSnippetData } from '@/ui/containers/ContentEditor/snippets/hooks/useGetSnippetData';
import { EImageModalFormFields } from '@/ui/containers/ContentEditor/snippets/ImageSnippetModal/ImageSnippetModal.constants';
import { getInitialValues } from '@/ui/containers/ContentEditor/snippets/ImageSnippetModal/ImageSnippetModal.helpers';
import { imageSnippetSchema } from '@/ui/containers/ContentEditor/snippets/ImageSnippetModal/ImageSnippetModal.schema';
import { SnippetModal } from '@/ui/containers/ContentEditor/snippets/SnippetModal';

export const ImageModalUI = () => {
  const { defaultValues, snippetData, handleClose } = useGetSnippetData('image');
  const sessionID = useRef(uuid_v4());

  const form = useForm({
    initialValues: getInitialValues(defaultValues),
    validate: yupResolver(imageSnippetSchema),
  });

  const handleChange = ({ id, path, name }: IFileLoaderOnChangeParams) => {
    form.values[EImageModalFormFields.FILE] = {
      id,
      path,
      name,
    };
  };
  const handleRemove = () => {
    form.values[EImageModalFormFields.FILE] = {
      id: '',
      path: '',
      name: '',
    };
  };

  const imgData = getImageData({
    id: form.values[EImageModalFormFields.FILE].id,
    name: form.values[EImageModalFormFields.FILE].name,
    path: form.values[EImageModalFormFields.FILE].path.replace(
      process.env.MEDIA_STORAGE_URL as string,
      '',
    ),
  });

  return (
    <SnippetModal
      isOpen
      isNew={snippetData?.isNew}
      onClose={handleClose}
      title='Изображение'
      handleClickBackBtn={handleClose}
      onSubmit={form.onSubmit(async (values) => {
        if (values[EImageModalFormFields.FILE].id) {
          await replaceTempFile({
            query: {
              sessionId: sessionID.current,
              ids: [values[EImageModalFormFields.FILE].id],
            },
          });
        }
        return handleAddSnippetData('image', values, handleClose, snippetData);
      })}
    >
      <Input.Wrapper
        label='Изображение'
        style={{ gridRow: 'span 2', gridColumn: 'span 6' }}
        withAsterisk
        {...form.getInputProps('file.id')}
      >
        <FileLoader
          savedFormFiles={imgData}
          onChange={handleChange}
          onRemove={handleRemove}
          sessionID={sessionID.current}
          placeholder='Перетащите или добавьте изображение. Изображение должно быть не более 1000 пикс. в высоту'
        />
      </Input.Wrapper>

      <InputBase
        style={{ gridColumn: 'span 6' }}
        label='Источник изображения'
        placeholder='Ссылка на источник изображения'
        {...form.getInputProps(EImageModalFormFields.IMAGE_SOURCE)}
      />

      <InputBase
        style={{ gridColumn: 'span 6' }}
        label={
          <TooltipTextWrapper
            label='Подпись изображения'
            text='Используется также для заполнения SEO-тега name у изображения'
          />
        }
        placeholder='Введите подпись изображения'
        {...form.getInputProps(EImageModalFormFields.IMAGE_CAPTION)}
      />
      <Checkbox
        style={{ gridColumn: 'span 6' }}
        label='Фиксированная высота'
        defaultChecked={true}
        checked={form.values[EImageModalFormFields.IS_FIXED_HEIGHT]}
        {...form.getInputProps(EImageModalFormFields.IS_FIXED_HEIGHT)}
      />
    </SnippetModal>
  );
};

export const ImageSnippetModal = React.memo(ImageModalUI);
