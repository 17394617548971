import { FC } from 'react';
import { Box, Image, Text } from '@mantine/core';

import { LabelBlockProps } from './LabelBlock.types';

import styles from './LabelBlock.module.scss';

export const LabelBlock: FC<LabelBlockProps> = ({ label, description, image }) => {
  return (
    <Box className={styles.root}>
      <Text className={styles.label} size='sm' fw='bold'>
        {label} <span className={styles.required}>*</span>
      </Text>

      <Text size='sm' color='gray'>
        {description}
      </Text>

      {!!image && <Image className={styles.image} src={`/images/cube/${image}`} />}
    </Box>
  );
};
