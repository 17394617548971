import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Center,
  Group,
  LoadingOverlay,
  MultiSelect,
  Pagination,
  Paper,
  Text,
} from '@mantine/core';

import { getOffset } from '@/utils/getOffset';
import { getPages } from '@/utils/getPages';

import { OFFSET } from '@/constants/common';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import { Loader } from '@/ui/containers/ContentEditor/Loader';
import { TemplatesTable } from '@/ui/containers/TemplatesContainer/components/TemplatesTable/TemplatesTable';
import {
  pageTypeOptions,
  siteOptions,
} from '@/ui/containers/TemplatesContainer/TemplatesContainer.constants';

import { ReactComponent as SearchIcon } from '@/assets/icons/search-filter.svg';
import {
  fetchTemplateListAction,
  loadingTemplateListSelector,
  setSearchPageType,
  setSearchSiteName,
  setTemplatesOffset,
  templatesLimitSelector,
  templatesListSelector,
  templatesOffsetSelector,
  templatesPageTypeFilterSelector,
  templatesSearchMessageSelector,
  templatesSiteNameFilterSelector,
  templatesTotalSelector,
} from '@/store/slices/templates/templates';

export const TemplatesContainer = () => {
  const dispatch = useAppDispatch();

  const templatesList = useSelector(templatesListSelector);
  const templatesTotal = useSelector(templatesTotalSelector);
  const templatesLimit = useSelector(templatesLimitSelector);
  const templatesOffset = useSelector(templatesOffsetSelector);
  const isFetchingTemplateList = useSelector(loadingTemplateListSelector);
  const filterMessage = useSelector(templatesSearchMessageSelector);
  const siteNameFilters = useSelector(templatesSiteNameFilterSelector);
  const pageTypeFilters = useSelector(templatesPageTypeFilterSelector);

  const onApplyFilters = () => {
    let siteName;
    let pageType;

    if (siteNameFilters) {
      siteName = siteNameFilters;
    }
    if (pageTypeFilters) {
      pageType = pageTypeFilters;
    }

    dispatch(
      fetchTemplateListAction({
        page: OFFSET,
        perPage: templatesLimit,
        filter: {
          site: siteName || null,
          type: pageType || null,
        },
      }),
    );
  };

  const onChangeSiteNameHandler = (value: string[]) => {
    dispatch(setSearchSiteName(value));
  };

  const onChangePageTypeHandler = (value: string[]) => {
    dispatch(setSearchPageType(value));
  };

  useEffect(() => {
    dispatch(fetchTemplateListAction({ page: templatesOffset, perPage: templatesLimit }));
  }, [templatesOffset]);

  return (
    <Box
      style={{
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Loader loading={false} blur />

      <PageHeader title='Шаблоны мета-тегов' />

      <Paper
        radius={8}
        p={24}
        shadow='xs'
        style={{
          overflow: 'hidden',
          gap: '32px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 40px)',
          maxHeight: '100%',
        }}
      >
        <Group mb={24} justify='space-between' align='flex-end'>
          <Group justify='space-between' align='flex-end' gap='xs' grow>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                minWidth: 190,
              }}
            >
              <MultiSelect
                clearable
                label='Сайт'
                placeholder='Сайт'
                data={siteOptions}
                onChange={(value) => onChangeSiteNameHandler(value)}
              />
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                minWidth: 190,
              }}
            >
              <Text size='sm' color='grayDefault'>
                Тип страницы
              </Text>
              <MultiSelect
                label='Тип страницы'
                placeholder='Тип страницы'
                data={pageTypeOptions}
                onChange={(value) => onChangePageTypeHandler(value)}
              />
            </Box>
          </Group>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 38,
              maxWidth: 'fit-content',
            }}
          >
            <Button radius='xs' size='sm' onClick={onApplyFilters}>
              Применить фильтр
            </Button>
          </Box>
        </Group>

        {templatesList && templatesList?.length > 0 && (
          <>
            <Box style={{ position: 'relative', overflow: 'scroll' }}>
              <TemplatesTable items={templatesList} />
              <LoadingOverlay visible={isFetchingTemplateList} />
            </Box>
          </>
        )}

        {!templatesList?.length && (
          <Box style={{ position: 'relative', height: '100%' }}>
            <LoadingOverlay visible={false} />

            {filterMessage && (
              <Center style={{ height: '100%' }}>
                {filterMessage ? (
                  <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <SearchIcon />
                    <Text ta={'center'} style={{ maxWidth: 380 }}>
                      {filterMessage}
                    </Text>
                  </Box>
                ) : (
                  <Text ta={'center'}>У вас нет шаблонов</Text>
                )}
              </Center>
            )}
          </Box>
        )}
        <Box
          style={{
            marginTop: 'auto',
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            minHeight: '38px',
          }}
        >
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Text size='md' c='greyDefault'>
              Всего:
            </Text>
            &nbsp;
            <Text size='md'>{templatesTotal}</Text>
          </Box>
          {templatesTotal && getPages(templatesTotal, templatesLimit) > 1 && (
            <Pagination
              style={{ right: '0', position: 'absolute', justifyContent: 'flex-end' }}
              total={getPages(templatesTotal, templatesLimit)}
              onChange={(page) => dispatch(setTemplatesOffset(getOffset(page, templatesLimit)))}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
};
