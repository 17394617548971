import { FC } from 'react';
import { Box, Group, Text } from '@mantine/core';

import { CustomLabel } from '@/ui/containers/BannerCreationContainer/components/CustomLabel';

import styles from './Constructor.module.scss';

export const Constructor: FC = () => {
  return (
    <Box
      style={{
        gridColumn: 'span 12',
        backgroundColor: '#F6F8F8',
        width: '100%',
        padding: '24px',
        display: 'flex',
        borderRadius: '6px',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <CustomLabel title='Дата' className={styles.label} styles={{ marginBottom: '8px' }} />
        <Group style={{ gap: '8px', marginBottom: '25px' }}>
          {Array.from({ length: 3 }).map((_, index) => (
            <Text className={styles.block} key={index}>
              Лого
            </Text>
          ))}
        </Group>
        <CustomLabel title='Заголовок' isRequire className={styles.label} />
        <Group style={{ marginBottom: '24px' }}>
          <CustomLabel title='Подзаголовок' isRequire className={styles.label} />
          <Text className={styles.block}>поле для промокода</Text>
        </Group>
        <CustomLabel title='Текст' isRequire className={styles.label} />

        <Text className={cn(styles.block, styles.btnText)}>Кнопка </Text>
      </Box>
      <Box
        style={{
          minWidth: '50%',
          minHeight: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#E8ECF0',
          borderRadius: '7px',
        }}
      >
        <CustomLabel title='Изображение' isRequire className={styles.label} />
      </Box>
    </Box>
  );
};
