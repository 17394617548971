import React, { FC } from 'react';
import { Input, InputBase, Text, Textarea } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { SnippetValues, Video, VideoSnippet } from '@prosv/core/types/snippets';
import { Trash } from 'tabler-icons-react';

import { S3_STORAGE_LINK } from '@/constants/common';

import { FileLoader } from '@/ui/components/FileLoader';
import { IFileLoaderOnChangeParams } from '@/ui/components/FileLoader/FileLoader.types';
import { TooltipTextWrapper } from '@/ui/components/TooltipFormWrapper/TooltipTextWrapper';
import { getImageData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper/SelectSnippetModalWrapper.helpers';

import { EVideoSnippetFormFields } from './VideoSnippetModal.constants';

import styles from './VideoItemUI.module.scss';

interface IProps {
  index: number;
  form: UseFormReturnType<SnippetValues<VideoSnippet>>;
  video: Video;
  sessionID: string;
}

export const VideoItemUI: FC<IProps> = ({ index, form, sessionID }) => {
  const handleRemoveVideo = () => {
    form.values.videos?.splice(index, 1);
    form.setValues(form.values);
  };

  const handleChange = ({ id, path, name }: IFileLoaderOnChangeParams) => {
    if (form.values.videos?.[index]) {
      form.values.videos[index] = {
        ...form.values.videos[index],
        slide: {
          id: id,
          path,
          name,
        },
      };
    }
  };

  const handleRemove = () => {
    if (form.values.videos?.[index]) {
      form.values.videos[index] = {
        ...form.values.videos[index],
        slide: {
          id: '',
          path: '',
          name: '',
        },
      };
    }
  };

  const imgData = getImageData({
    id: form.values.videos?.[index].slide?.id,
    name: form.values.videos?.[index].slide?.name,
    path: form.values.videos?.[index].slide?.path.replace(S3_STORAGE_LINK as string, ''),
  });

  return (
    <div
      key={index}
      style={{
        gridColumn: 'span 12',
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gap: '24px',
        padding: '0 16px',
      }}
    >
      <Text
        size='sm'
        c='black'
        ta='center'
        style={{
          textTransform: 'none',
          fontWeight: 500,
          gridColumn: 'span 12',
          textAlign: 'start',
          marginTop: 20,
          display: 'flex',
          alignItems: 'center',
          gap: 10,
        }}
      >
        Слайд {index + 1}
        {index !== 0 && (
          <Trash size={20} color='gray' onClick={handleRemoveVideo} style={{ cursor: 'pointer' }} />
        )}
      </Text>
      <Input.Wrapper
        label='Обложка видео'
        style={{ gridColumn: 'span 6', gridRow: 'span 2' }}
        {...form.getInputProps(`videos.${index}.${EVideoSnippetFormFields.SLIDE}`)}
      >
        <FileLoader
          sessionID={sessionID}
          onRemove={handleRemove}
          onChange={handleChange}
          savedFormFiles={imgData}
          placeholder='Перетащите или добавьте изображение. Изображение должно быть не более 1000 пикс. в высоту'
        />
      </Input.Wrapper>
      <Textarea
        label={
          <TooltipTextWrapper
            label='Описание видео'
            text='Используется также для заполнения SEO-тега name у изображения'
          />
        }
        placeholder='Описание видео'
        className={styles.textarea}
        {...form.getInputProps(`videos.${index}.${EVideoSnippetFormFields.DESCRIPTION}`)}
      />
      <InputBase
        withAsterisk
        style={{ gridColumn: 'span 6' }}
        label='Видео'
        placeholder='Вставьте ссылку на видео'
        {...form.getInputProps(`videos.${index}.${EVideoSnippetFormFields.VIDEO_URl}`)}
      />
      <InputBase
        style={{ gridColumn: 'span 6' }}
        label={
          <TooltipTextWrapper
            label='Название видео'
            text='Используется также для заполнения SEO-тега name у изображения'
          />
        }
        placeholder='Введите название видео'
        {...form.getInputProps(`videos.${index}.${EVideoSnippetFormFields.TITLE}`)}
      />
    </div>
  );
};
