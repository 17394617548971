import { ITemplate } from '@/types/templates/templates';

import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, useMantineTheme } from '@mantine/core';
import { Pencil } from 'tabler-icons-react';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { setEditTemplateItem } from '@/store/slices/templates/templates';

interface IProps {
  items: ITemplate[];
}

export const TemplatesTable: FC<IProps> = ({ items }) => {
  const dispatch = useAppDispatch();

  const theme = useMantineTheme();

  const navigate = useNavigate();

  const handleEditTemplate = (templateId: string) => {
    navigate(`/templates-meta-tags/${templateId}`);
    dispatch(setEditTemplateItem(templateId));
  };

  return (
    <>
      <Table highlightOnHover verticalSpacing='xs' fz='sm'>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Сайт</Table.Th>
            <Table.Th>Название</Table.Th>
            <Table.Th>Тип страницы</Table.Th>
            <Table.Th></Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {items.map(({ id, site, title, pageType }) => {
            return (
              <Table.Tr key={id}>
                <Table.Td>{site}</Table.Td>
                <Table.Td>{title}</Table.Td>
                <Table.Td>{pageType}</Table.Td>
                <Table.Td />
                <Table.Td style={{ maxWidth: '20px' }}>
                  <Button
                    name='Редактировать'
                    title='Редактировать'
                    size='compact-sm'
                    variant='subtle'
                    style={{ position: 'static' }}
                    onClick={() => handleEditTemplate(id)}
                  >
                    <Pencil size={22} color={theme.colors.greyLight[9]} />
                  </Button>
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </>
  );
};
