import { ItemPermissions } from '@/types/auth/permissions';
import { IBanner, TNullable } from '@/types/banner/banner';
import { IAudioAttributes } from '@/types/banner/bannerDetail';

import { FC, FormEvent, useMemo } from 'react';
import {
  Box,
  Group,
  Input,
  MultiSelect,
  NumberInput,
  Radio,
  Select,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { DatePickerInput, TimeInput } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form';
import { IconCalendar } from '@tabler/icons';
import { GradientSelectItem } from 'src/ui/containers/BannerCreationContainer/components/GradientSelectItem';

import { FileLoader } from '@/ui/components/FileLoader';
import {
  BACKGROUND_BREAKPOINTS,
  bgFields,
  BUTTON_TEXT_MAX_LENGTH,
  colorsOptions,
  DEFAULT_MIN_CLICKS_VALUE,
  DEFAULT_MIN_VIEWS_VALUE,
  dependentOptions,
  DESCRIPTION_MAX_LENGTH,
  disabledOptions,
  EActivityFieldValues,
  EBannerFormFields,
  ERID_MAX_LENGTH,
  LOGO_BREAKPOINTS,
  logoFields,
  MAX_IMAGE_SIZE,
  ORGANIZATION_MAX_LENGTH,
  PROMOCODE_MAX_LENGTH,
  SUB_TITLE_MAX_LENGTH,
  TITLE_MAX_LENGTH,
} from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerForm.constants';
import {
  addDisableStatus,
  getPlacesByPages,
} from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerForm.helpers';
import { useImageFiles } from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerForm.hooks';
import { IBannerFormFields } from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerForm.types';
import { Constructor } from '@/ui/containers/BannerCreationContainer/components/Constructor';
import { CustomLabel } from '@/ui/containers/BannerCreationContainer/components/CustomLabel';
import { LineLengthDisplay } from '@/ui/containers/BannerCreationContainer/components/LineLengthDisplay';

import { pageOptions, siteOptions } from '@/pages/Banners/BannersListing/BannerListing.constants';

interface IProps {
  form: UseFormReturnType<IBannerFormFields>;
  banner: TNullable<IBanner>;
  sessionID: string;
  handleSubmit: (values: IBannerFormFields, event: FormEvent<HTMLFormElement>) => void;
  isNewBanner: boolean;
  setTrySave: (value: boolean) => void;
  trySaveBanner: boolean;
  permissions: ItemPermissions;
  audioAttributes?: {
    type: string;
    values: IAudioAttributes[];
  }[];
}

export const BannerForm: FC<IProps> = ({
  form,
  banner,
  sessionID,
  permissions: { canUpdate, canUpdateActivity },
  audioAttributes,
}) => {
  const logoImages = useImageFiles(LOGO_BREAKPOINTS, logoFields, banner);
  const backgroundImages = useImageFiles(BACKGROUND_BREAKPOINTS, bgFields, banner);

  const gradeOptions = useMemo(() => {
    return audioAttributes?.[0].values?.map((item) => {
      return { label: item.title, value: item.value };
    });
  }, [audioAttributes]);

  const subjectsOptions = useMemo(() => {
    return audioAttributes?.[1].values?.map((item) => {
      return { label: item.title, value: item.value };
    });
  }, [audioAttributes]);

  const placesData = useMemo(
    () => getPlacesByPages([...form.values[EBannerFormFields.PAGE]], dependentOptions),
    [[form.values[EBannerFormFields.PAGE]]],
  );

  return (
    <Box
      style={{
        gridTemplateColumns: 'repeat(12, 1fr)',
        display: 'grid',
        gap: '24px',
        alignItems: 'self-start',
        width: '100%',
      }}
    >
      <CustomLabel
        title='Отображение'
        numSpanInGrid='12'
        styles={{ fontSize: '16px', fontWeight: 500, color: '#66788A' }}
      />

      <CustomLabel title='Включён' isRequire numSpanInGrid='8' />
      <Radio.Group
        {...form.getInputProps(EBannerFormFields.IS_ACTIVE)}
        style={{ gridColumn: 'span 4', display: 'flex', gap: '24px' }}
      >
        <Group>
          <Radio
            value={EActivityFieldValues.ACTIVE}
            label='Да'
            disabled={!(canUpdate || canUpdateActivity)}
          />
          <Radio
            value={EActivityFieldValues.NOT_ACTIVE}
            label='Нет'
            disabled={!(canUpdate || canUpdateActivity)}
          />
        </Group>
      </Radio.Group>

      <CustomLabel title='Дата и время начала активности' isRequire numSpanInGrid='8' />
      <Box style={{ display: 'flex', gap: '5px', gridColumn: 'span 4' }}>
        <DatePickerInput
          locale='ru'
          leftSection={<IconCalendar size={16} />}
          placeholder='Начало активности'
          withAsterisk
          style={{ width: '50%' }}
          maxDate={form.values[EBannerFormFields.DATE_ACTIVE_TO_DAYS] || undefined}
          disabled={!canUpdate}
          {...form.getInputProps(EBannerFormFields.DATE_ACTIVE_FROM_DAYS)}
        />
        <TimeInput
          withAsterisk
          placeholder='чч:мм'
          style={{ width: '50%' }}
          disabled={!canUpdate}
          {...form.getInputProps(EBannerFormFields.DATE_ACTIVE_FROM_HOURS)}
        />
      </Box>

      <CustomLabel title='Дата и время окончания активности' isRequire numSpanInGrid='8' />
      <Box style={{ display: 'flex', gap: '5px', gridColumn: 'span 4' }}>
        <DatePickerInput
          locale='ru'
          leftSection={<IconCalendar size={16} />}
          placeholder='Окончание активности'
          withAsterisk
          style={{ width: '50%' }}
          minDate={form.values[EBannerFormFields.DATE_ACTIVE_FROM_DAYS] || undefined}
          disabled={!canUpdate}
          {...form.getInputProps(EBannerFormFields.DATE_ACTIVE_TO_DAYS)}
        />
        <TimeInput
          // clearable
          withAsterisk
          placeholder='чч:мм'
          style={{ width: '50%' }}
          disabled={!canUpdate}
          {...form.getInputProps(EBannerFormFields.DATE_ACTIVE_TO_HOURS)}
        />
      </Box>

      <CustomLabel title='Номер баннера в слайдере' numSpanInGrid='8' />
      <NumberInput
        min={1}
        style={{ gridColumn: 'span 4', width: '570px' }}
        disabled={!canUpdate}
        {...form.getInputProps(EBannerFormFields.SORT)}
      />

      <CustomLabel title='Сайт' isRequire numSpanInGrid='8' />
      <Select
        data={addDisableStatus(siteOptions, disabledOptions)}
        style={{ gridColumn: 'span 4' }}
        placeholder='Выберите сайт'
        withAsterisk
        clearable
        disabled={!canUpdate}
        {...form.getInputProps(EBannerFormFields.SITE)}
      />

      <CustomLabel title='Страница/Раздел' isRequire numSpanInGrid='8' />
      <MultiSelect
        data={addDisableStatus(pageOptions, disabledOptions)}
        style={{ gridColumn: 'span 4' }}
        placeholder='Выберите страницу'
        disabled={!canUpdate || !form.getInputProps(EBannerFormFields.SITE).value}
        withAsterisk
        clearable
        {...form.getInputProps(EBannerFormFields.PAGE)}
      />
      {form.values.page.includes('audio') && (
        <>
          <CustomLabel title='Класс' numSpanInGrid='8' />
          <Select
            data={gradeOptions || []}
            style={{ gridColumn: 'span 4' }}
            placeholder='Класс'
            disabled={!canUpdate || !form.getInputProps(EBannerFormFields.SITE).value}
            withAsterisk
            clearable
            {...form.getInputProps(EBannerFormFields.GRADE)}
          />
          <CustomLabel title='Предмет' numSpanInGrid='8' />
          <Select
            data={subjectsOptions || []}
            style={{ gridColumn: 'span 4' }}
            placeholder='Предмет'
            disabled={!canUpdate || !form.getInputProps(EBannerFormFields.SITE).value}
            withAsterisk
            clearable
            {...form.getInputProps(EBannerFormFields.SUBJECTS)}
          />
        </>
      )}
      <CustomLabel title='Расположение' isRequire numSpanInGrid='8' />
      <MultiSelect
        data={placesData || []}
        style={{ gridColumn: 'span 4' }}
        placeholder='Выберите место'
        disabled={!canUpdate || !form.getInputProps(EBannerFormFields.PAGE).value.length}
        withAsterisk
        clearable
        {...form.getInputProps(EBannerFormFields.PLACE)}
      />

      <CustomLabel
        title='Конструктор баннера'
        numSpanInGrid='12'
        styles={{ fontSize: '16px', fontWeight: 500, color: '#66788A' }}
      />

      <Constructor />

      <Group style={{ gridColumn: 'span 8', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Text>Дата</Text>
        <Text style={{ fontWeight: 400, fontSize: '14px', color: '#66788A', maxWidth: '266px' }}>
          Введите дату, которая будет отображаться на баннере
        </Text>
        <Text>
          Если в&nbsp;акции нет даты начала, то
          <br />
          заполнять только поле &rsquo;по&rsquo;
        </Text>
      </Group>
      <Group style={{ gridColumn: 'span 4', flexWrap: 'nowrap' }}>
        <Box style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Text>с</Text>
          <DatePickerInput
            style={{ width: '100%' }}
            locale='ru'
            leftSection={<IconCalendar size={16} />}
            placeholder='Введите дату с'
            disabled={!canUpdate || !form.values[EBannerFormFields.DATE_ACTIVE_FROM_DAYS]}
            minDate={form.values[EBannerFormFields.DATE_ACTIVE_FROM_DAYS] || undefined}
            maxDate={form.values[EBannerFormFields.DATE_ACTIVE_TO_DAYS] || undefined}
            {...form.getInputProps(EBannerFormFields.DATE_FROM)}
          />
        </Box>

        <Box style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Text>по</Text>
          <DatePickerInput
            style={{ width: '100%' }}
            locale='ru'
            leftSection={<IconCalendar size={16} />}
            placeholder='Введите дату по'
            disabled={!canUpdate || !form.values[EBannerFormFields.DATE_ACTIVE_FROM_DAYS]}
            minDate={
              form.values[EBannerFormFields.DATE_FROM] ||
              form.values[EBannerFormFields.DATE_ACTIVE_FROM_DAYS] ||
              undefined
            }
            maxDate={form.values[EBannerFormFields.DATE_ACTIVE_TO_DAYS] || undefined}
            {...form.getInputProps(EBannerFormFields.DATE_TO)}
          />
        </Box>
      </Group>

      <Group style={{ gridColumn: 'span 8', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Text>Логотипы</Text>
        <Text style={{ fontWeight: 400, fontSize: '14px', color: '#66788A', maxWidth: '266px' }}>
          Добавьте до трёх логотипов. Логотип отображается над заголовком баннера. Максимальный вес
          изображения - 5 мб, допустимые форматы: .webp, .png, .gif, .jpeg, .jpg
        </Text>
      </Group>

      <Group style={{ gridColumn: 'span 4' }}>
        {logoImages.map(({ title, field, image }, index) => {
          return (
            <Input.Wrapper
              key={index}
              label={title}
              style={{
                gridColumn: 'span 6',
                gridRow: 'span 2',
                width: '526px',
                position: 'relative',
              }}
            >
              <FileLoader
                sessionID={sessionID}
                isFullWidth
                savedFormFiles={image ? [image] : []}
                maxSize={MAX_IMAGE_SIZE}
                {...form.getInputProps(field)}
                onChange={(params) => {
                  form.setFieldValue(field, params.id);
                }}
                onRemove={(id?: string) => form.setFieldValue(field, id)}
                disabled={!canUpdate}
              />
            </Input.Wrapper>
          );
        })}
      </Group>

      <CustomLabel title='Заголовок' numSpanInGrid='8' />
      <Textarea
        style={{ gridColumn: 'span 4', position: 'relative' }}
        autosize
        {...form.getInputProps(EBannerFormFields.TITLE)}
        placeholder='Введите заголовок'
        disabled={!canUpdate}
        rightSection={
          <LineLengthDisplay
            currentLength={form.getInputProps(EBannerFormFields.TITLE).value.length}
            maxLength={TITLE_MAX_LENGTH}
          />
        }
      />

      <CustomLabel title='Подзаголовок' numSpanInGrid='8' />
      <Textarea
        style={{ gridColumn: 'span 4', position: 'relative' }}
        autosize
        minRows={4}
        {...form.getInputProps(EBannerFormFields.SUB_TITLE)}
        placeholder='Введите подзаголовок'
        disabled={!canUpdate}
        rightSection={
          <LineLengthDisplay
            currentLength={form.getInputProps(EBannerFormFields.SUB_TITLE).value.length}
            maxLength={SUB_TITLE_MAX_LENGTH}
          />
        }
      />

      <Text style={{ gridColumn: 'span 8' }}>Поле для промокода</Text>
      <TextInput
        style={{ gridColumn: 'span 4' }}
        {...form.getInputProps(EBannerFormFields.PROMOCODE)}
        placeholder='Введите промокод'
        disabled={!canUpdate}
        rightSection={
          <LineLengthDisplay
            currentLength={form.getInputProps(EBannerFormFields.PROMOCODE).value.length}
            maxLength={PROMOCODE_MAX_LENGTH}
          />
        }
      />

      <CustomLabel title='Текст' numSpanInGrid='8' />
      <Textarea
        style={{ gridColumn: 'span 4', position: 'relative' }}
        autosize
        minRows={6}
        {...form.getInputProps(EBannerFormFields.DESCRIPTION)}
        placeholder='Введите основной текст'
        disabled={!canUpdate}
        rightSection={
          <LineLengthDisplay
            currentLength={form.getInputProps(EBannerFormFields.DESCRIPTION).value.length}
            maxLength={DESCRIPTION_MAX_LENGTH}
          />
        }
      />

      <CustomLabel title='erid' numSpanInGrid='8' />
      <Group style={{ gridColumn: 'span 4', flexWrap: 'nowrap' }}>
        <TextInput
          style={{ width: '100%', position: 'relative' }}
          {...form.getInputProps(EBannerFormFields.ERID)}
          placeholder='Введите код'
          disabled={!canUpdate}
          rightSection={
            <LineLengthDisplay
              currentLength={form.getInputProps(EBannerFormFields.ERID).value.length}
              maxLength={ERID_MAX_LENGTH}
            />
          }
        />

        <TextInput
          style={{ width: '100%' }}
          {...form.getInputProps(EBannerFormFields.ORGANIZATION_NAME)}
          placeholder='Введите текст'
          disabled={!canUpdate}
          rightSection={
            <LineLengthDisplay
              currentLength={form.getInputProps(EBannerFormFields.ORGANIZATION_NAME).value.length}
              maxLength={ORGANIZATION_MAX_LENGTH}
            />
          }
        />
      </Group>

      <CustomLabel title='Кнопка' numSpanInGrid='8' />
      <Group style={{ gridColumn: 'span 4', flexWrap: 'nowrap' }}>
        <TextInput
          style={{ width: '100%', position: 'relative' }}
          label='Текст на кнопке'
          {...form.getInputProps(EBannerFormFields.BUTTON_TEXT)}
          placeholder='Введите текст кнопки'
          disabled={!canUpdate}
          rightSection={
            <LineLengthDisplay
              currentLength={form.getInputProps(EBannerFormFields.BUTTON_TEXT).value.length}
              maxLength={BUTTON_TEXT_MAX_LENGTH}
            />
          }
        />

        <TextInput
          label='Ссылка'
          style={{ width: '100%' }}
          {...form.getInputProps(EBannerFormFields.BUTTON_LINK)}
          placeholder='Введите ссылку'
          disabled={!canUpdate}
        />
      </Group>

      <Group style={{ gridColumn: 'span 8', flexDirection: 'column', alignItems: 'flex-start' }}>
        <CustomLabel title='Изображения для баннера' numSpanInGrid='8' />
        <Text
          style={{
            fontWeight: 400,
            fontSize: '14px',
            color: '#66788A',
            maxWidth: '266px',
            marginBottom: '24px',
          }}
        >
          Добавьте изображение для баннера в трёх размерах. Максимальный вес изображения - 5 мб,
          допустимые форматы: .webp, .png, .gif, .jpeg, .jpg
        </Text>
      </Group>
      <Group style={{ gridColumn: 'span 4' }}>
        {backgroundImages.map(({ title, field, image }, index) => (
          <Input.Wrapper
            key={index}
            label={title}
            style={{
              gridColumn: 'span 6',
              gridRow: 'span 2',
              width: '526px',
            }}
          >
            <FileLoader
              sessionID={sessionID}
              savedFormFiles={image ? [image] : []}
              maxSize={MAX_IMAGE_SIZE}
              isFullWidth
              {...form.getInputProps(field)}
              onChange={(params) => {
                form.setFieldValue(field, params.id);
              }}
              onRemove={(id?: string) => form.setFieldValue(field, id)}
              error={form.getInputProps(field).error}
              disabled={!canUpdate}
            />
            {form.getInputProps(field).error && (
              <Text
                style={{ fontWeight: 400, fontSize: '12px', color: '#fa5252', maxWidth: '266px' }}
              >
                {form.getInputProps(field).error}
              </Text>
            )}
          </Input.Wrapper>
        ))}
      </Group>

      <Group style={{ gridColumn: 'span 8', flexDirection: 'column', alignItems: 'flex-start' }}>
        <CustomLabel title='Фон' numSpanInGrid='8' />
        <Text style={{ fontWeight: 400, fontSize: '14px', color: '#66788A', maxWidth: '266px' }}>
          <a>Посмотреть гайд</a> по цветам для подложки баннеров
        </Text>
      </Group>

      <Box style={{ gridColumn: 'span 4' }}>
        <Select
          placeholder='Выберите цвет фона'
          renderOption={({ option }) => <GradientSelectItem color='blue' {...option} />}
          data={colorsOptions}
          clearable
          maxDropdownHeight={400}
          disabled={!canUpdate}
          {...form.getInputProps(EBannerFormFields.BACKGROUND)}
        />
      </Box>

      <CustomLabel title='Ссылка' numSpanInGrid='8' />
      <TextInput
        style={{ gridColumn: 'span 4' }}
        {...form.getInputProps(EBannerFormFields.BANNER_LINK)}
        disabled={!canUpdate}
        placeholder='Введите ссылку'
      />

      <Text style={{ gridColumn: 'span 8' }}>Количество переходов до скрытия</Text>
      <NumberInput
        min={DEFAULT_MIN_CLICKS_VALUE}
        style={{ gridColumn: 'span 4' }}
        {...form.getInputProps(EBannerFormFields.NUMBER_OF_CLICK_BEFORE_HIDING)}
        disabled={!canUpdate}
        placeholder='Введите количество'
      />

      <Text style={{ gridColumn: 'span 8' }}>Количество показов до скрытия</Text>
      <NumberInput
        min={DEFAULT_MIN_VIEWS_VALUE}
        style={{ gridColumn: 'span 4' }}
        {...form.getInputProps(EBannerFormFields.NUMBER_OF_VIEW_BEFORE_HIDING)}
        disabled={!canUpdate}
        placeholder='Введите количество'
      />
    </Box>
  );
};
