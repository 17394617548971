import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PublicationsDictionaryItem } from '@prosv/core/types/bff/base';
import { debounce } from 'lodash';

import { DictionarySelectOption } from '../dictionary/dictionary.types';

import { prepareUmkOptions, selectedUmkOptions } from './umk.helpers';
import { umkSlice } from './umk.slice';

export const {
  useCreateUmkPageMutation,
  useLazyGetUmkQuery,
  useGetUmkQuery,
  useSearchUmkMutation,
  useDeleteUmkPageMutation,
  useUpdateUmkPageMutation,
} = umkSlice;

export const useUmkOptions = (selectedItems?: PublicationsDictionaryItem[] | null) => {
  const [searchUmk] = useSearchUmkMutation();
  const [options, setOptions] = useState<DictionarySelectOption[]>([]);
  const [optionsInput, setOptionsInput] = useState('');
  const [input, setInput] = useState('');

  const inputRef = useRef(input);
  inputRef.current = input;

  const isLoading = input !== optionsInput;

  const selectedOptions = useMemo(() => selectedUmkOptions(selectedItems ?? []), [selectedItems]);

  const request = useCallback(
    (query: string) => {
      searchUmk({
        attributeCodeGroup: 'line',
        query: { query, isAutocorrectQuery: true },
      }).then((res) => {
        if (inputRef.current === query) {
          if ('data' in res) {
            setOptionsInput(query);
            setOptions(prepareUmkOptions(res.data.items));
          } else {
            console.error(res);
          }
        }
      });
    },
    [searchUmk],
  );

  const finalOptions = useMemo(() => {
    const res = [...options];
    selectedOptions.forEach((item) => {
      if (!res.some(({ value }) => value === item.value)) {
        res.push(item);
      }
    });
    return res;
  }, [options, selectedOptions]);

  const update = useMemo(() => debounce(request, 200), [request]);

  useEffect(() => {
    if (input.length >= 3) {
      update(input);
    }
  }, [input, update]);

  return { options: finalOptions, isLoading, input, setInput };
};
