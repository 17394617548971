import {
  setSelectGridDataSelector,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';

type TUseHandlersMoveRowButtonProps = {
  rowId: number;
};

export const useHandlersMoveRowButton = ({ rowId }: TUseHandlersMoveRowButtonProps) => {
  const setVisibleSelectGrid = useStoreContentEditor(setSelectGridDataSelector);
  const handleRemoveRow = async () => {
    useStoreContentEditor.setState((state) => {
      const grid = state?.savedContentData?.grid;
      if (grid) grid.splice(rowId, 1);
    });
    await useStoreContentEditor.getState().updateContentData({});
  };
  const handleEditRow = async () => {
    setVisibleSelectGrid({ rowId, isEdit: true });
  };

  return { handleRemoveRow, handleEditRow };
};
