import { FC } from 'react';
import { Box, Modal, RingProgress } from '@mantine/core';

interface IProps {
  isUpload: boolean;
  progress: number;
}
export const ProgressUpload: FC<IProps> = ({ isUpload, progress }) => {
  return (
    <Modal padding='lg' centered opened={isUpload} onClose={() => {}} withCloseButton={false}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '269px',
        }}
      >
        <RingProgress sections={[{ value: progress, color: 'blue' }]} />
      </Box>
    </Modal>
  );
};
