import { AttributesItem, IBanner, IPositionItem, TNullable } from '@/types/banner/banner';

import dayjs from 'dayjs';

import {
  DEFAULT_CLICKS_VALUE,
  DEFAULT_VIEWS_VALUE,
  EActivityFieldValues,
  EBannerFormFields,
} from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerForm.constants';
import {
  IBannerFormFields,
  IImage,
  IPlaceOptions,
  TPage,
} from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerForm.types';

const getDate = (date?: Date | string | null) => (date ? new Date(date) : null);

const getTime = (date?: Date | string | null) => {
  const dt = getDate(date);
  if (!dt) {
    return null;
  }

  return dayjs(date).format('HH:mm');
};

const getBannerStatus = (isActive?: boolean) => {
  return isActive ? EActivityFieldValues.ACTIVE : EActivityFieldValues.NOT_ACTIVE;
};

const preparePositionsBanner = (positions: IPositionItem[]) => {
  const pages = positions.map((item) => item.page);
  const places = positions
    .map((item) => item.places.map((place) => `${item.page}-${place}`))
    .flat();
  return {
    pages,
    places,
  };
};

const getAttributes = (items: AttributesItem[]) => {
  let grade = null;
  let subject = null;
  items.forEach(({ type, value }) => {
    if (type === 'class') {
      grade = value;
    } else if (type === 'subject') {
      subject = value;
    }
  });
  return { grade, subject };
};

export const getDefaultValues = (banner: TNullable<IBanner>): IBannerFormFields => {
  const { pages, places } = preparePositionsBanner(banner?.positions || []);
  const { grade, subject } = getAttributes(banner?.attributes || []);
  return {
    [EBannerFormFields.ATTRIBUTES]: banner?.attributes ? banner.attributes : [],
    [EBannerFormFields.IS_ACTIVE]: banner?.isActive
      ? getBannerStatus(banner?.isActive)
      : EActivityFieldValues.NOT_ACTIVE,
    [EBannerFormFields.DATE_ACTIVE_FROM_DAYS]: getDate(banner?.dateActiveFrom?.value),
    [EBannerFormFields.DATE_ACTIVE_FROM_HOURS]: getTime(banner?.dateActiveFrom?.value) ?? '',
    [EBannerFormFields.DATE_ACTIVE_TO_DAYS]: getDate(banner?.dateActiveTo?.value),
    [EBannerFormFields.DATE_ACTIVE_TO_HOURS]: getTime(banner?.dateActiveTo?.value) ?? '',
    [EBannerFormFields.DATE_FROM]: getDate(banner?.dateFrom?.value),
    [EBannerFormFields.DATE_TO]: getDate(banner?.dateTo?.value),
    [EBannerFormFields.SORT]: banner?.sort || 1,
    [EBannerFormFields.SITE]: banner?.site || '',
    [EBannerFormFields.PAGE]: pages as TPage[],
    [EBannerFormFields.PLACE]: places,
    [EBannerFormFields.GRADE]: grade,
    [EBannerFormFields.SUBJECTS]: subject,
    [EBannerFormFields.TITLE]: banner?.title || '',
    [EBannerFormFields.SUB_TITLE]: banner?.subTitle || '',
    [EBannerFormFields.PROMOCODE]: banner?.promocode || '',
    [EBannerFormFields.DESCRIPTION]: banner?.description || '',
    [EBannerFormFields.BUTTON_TEXT]: banner?.buttonText || '',
    [EBannerFormFields.BUTTON_LINK]: banner?.buttonLink || '',
    [EBannerFormFields.BANNER_LINK]: banner?.bannerLink || '',
    [EBannerFormFields.BACKGROUND]: banner?.background || null,
    [EBannerFormFields.NUMBER_OF_CLICK_BEFORE_HIDING]:
      banner?.numberOfClicksBeforeHiding || DEFAULT_CLICKS_VALUE,
    [EBannerFormFields.NUMBER_OF_VIEW_BEFORE_HIDING]:
      banner?.numberOfViewsBeforeHiding || DEFAULT_VIEWS_VALUE,
    [EBannerFormFields.LOGO1_IMAGE_ID]: (banner?.logo1Image && banner?.logo1Image.id) || '',
    [EBannerFormFields.LOGO2_IMAGE_ID]: (banner?.logo2Image && banner?.logo2Image.id) || '',
    [EBannerFormFields.LOGO3_IMAGE_ID]: (banner?.logo3Image && banner?.logo3Image.id) || '',
    [EBannerFormFields.DESKTOP_IMAGE_ID]: (banner?.desktopImage && banner?.desktopImage.id) || '',
    [EBannerFormFields.TABLET_IMAGE_ID]: (banner?.tabletImage && banner?.tabletImage.id) || '',
    [EBannerFormFields.MOBILE_IMAGE_ID]: (banner?.mobileImage && banner?.mobileImage.id) || '',
    [EBannerFormFields.ERID]: banner?.erid || '',
    [EBannerFormFields.ORGANIZATION_NAME]: banner?.organizationName || '',
  };
};

export const getImageData = (imageData: TNullable<IImage>) => {
  if (!imageData) return null;
  const { path, id } = imageData;
  const extension = 'webp';
  const preview = process.env.MEDIA_STORAGE_URL + (path ?? '');
  return { preview, name: `${id}.${extension}`, id };
};

export const addDisableStatus = (
  options: { label: string; value: string }[],
  disabledOptions: string[],
) => {
  return options.map((option) => ({
    ...option,
    disabled: disabledOptions.includes(option.value),
  }));
};

export const getPlacesByPages = (pages: TPage[], placeOptions: IPlaceOptions) => {
  if (!pages.length) return [];
  return pages
    .map((page) =>
      placeOptions[page].map((place) => ({ ...place, value: `${page}-${place.value}` })),
    )
    .flat();
};
