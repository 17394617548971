import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Group, Paper, Title } from '@mantine/core';

import { usePermission } from '@/hooks/usePermissions';

import { CustomTable } from '@/ui/components/CustomTable/CustomTable';
import { MaterialsActiveModal } from '@/ui/containers/MaterialsContainer/Components/MaterialsActiveModal';
import { SupportsFilter } from '@/ui/containers/SupportContainer/components/SupportsFilter';
import { useSupportFilterConfig } from '@/ui/containers/SupportContainer/components/SupportsFilter/useSupportFilterConfig';

import { useColumns } from '@/pages/Support/Suports.hooks';
import { useLazyGetSupportsListingQuery } from '@/store/slices/support/supportDetailSlice';

export const SupportContainer = () => {
  const canCreate = usePermission('FAQ_CREATE');
  const navigate = useNavigate();
  const [getSupportsListingRequest, data] = useLazyGetSupportsListingQuery();

  const filterProps = useSupportFilterConfig(getSupportsListingRequest);

  const columns = useColumns(filterProps.actionWithDebounce);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '96vh',
        overflowY: 'hidden',
      }}
    >
      <Container fluid mb={16} style={{ marginLeft: 'initial', marginRight: 'initial' }}>
        <Group justify='space-between'>
          <Title order={1}>Поддержка - F.A.Q.</Title>
          {canCreate && (
            <Button style={{ width: '200px' }} onClick={() => navigate('/support/new')}>
              Создать
            </Button>
          )}
        </Group>
      </Container>
      <Paper
        radius={8}
        p={24}
        pb={12}
        shadow='xs'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: '100%',
          overflow: 'hidden',
        }}
      >
        <SupportsFilter getSupportsListing={getSupportsListingRequest} />
        <CustomTable
          onChangePagination={(v) => {
            filterProps.onChangePage({ currentPage: v });
          }}
          limit={Number(filterProps.filter.limit)}
          noData='К сожалению, список вопросов пуст.'
          total={data.data?.payload.meta.total || 0}
          currentPage={filterProps.filter.currentPage}
          status={data.isFetching ? 'loading' : 'loaded'}
          data={data.data?.payload.items || []}
          columns={columns}
        />
      </Paper>
      <MaterialsActiveModal />
    </Box>
  );
};
