import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import * as api from '@/api/contentEditor/contentEditor';
import { apiUpdateContentDataEditor } from '@/api/contentEditor/contentEditor';

import notify from '@/utils/notify';

import {
  getUpdateContentDataParams,
  parseContentData,
} from '@/ui/containers/ContentEditor/ContentEditor.helpers';

import { TViewActions, TViewState } from './store.types';

export const useStoreContentEditor = create(
  devtools(
    immer<TViewState & TViewActions>((set, getState) => ({
      draftId: null,
      setDraftId: (data) => {
        set((state) => {
          state.draftId = data;
        });
      },

      templateSelectValue: null,
      setTemplateSelectValue: (value) => {
        set((state) => {
          state.templateSelectValue = value;
        });
      },
      getTemplateSelectValue: () => getState().templateSelectValue,

      getDefaultFormData: () => null,
      setDefaultFormData: (data) => {
        set((state) => {
          state.getDefaultFormData = (() => data) as typeof state.getDefaultFormData;
        });
      },

      defaultEditorData: null,
      savedContentData: null,

      selectContentBlockData: null,
      setSelectContentBlockData: (data) => {
        set((state) => {
          state.selectContentBlockData = data;
        });
      },

      selectGridData: null,
      setSelectGridData: (data) => {
        set((state) => {
          state.selectGridData = data;
        });
      },

      snippetData: null,
      setSnippetData: (value) => {
        set((state) => {
          state.snippetData = value;
        });
      },

      loadingContentData: false,
      fetchSavedContentData: async (draftId, template, isForceChangeTemplate) => {
        try {
          const draftIdFromState = getState().draftId;
          if (!draftIdFromState) {
            return;
          }
          set((state) => void (state.loadingContentData = true));

          const data = await api.tryGetContentDataEditor(draftId || draftIdFromState);

          const parsedData = parseContentData(data?.payload.content);

          if (template && (!parsedData?.grid.length || isForceChangeTemplate)) {
            set((state) => void (state.defaultEditorData = template));
            set((state) => void (state.savedContentData = template));
          } else {
            set((state) => void (state.defaultEditorData = parsedData));
            set(
              (state) =>
                void (draftId
                  ? (state.savedContentDataList[draftId] = parsedData)
                  : (state.savedContentData = parsedData)),
            );
          }
        } catch (error) {
          notify({ message: 'Ошибка получения данных', type: 'error' });
        } finally {
          set((state) => void (state.loadingContentData = false));
        }
      },

      updateContentData: async ({ draftId, withoutLoader } = {}) => {
        try {
          const state = getState();

          if (!withoutLoader) {
            set((prevState) => void (prevState.loadingContentData = true));
          }

          const data = await apiUpdateContentDataEditor(getUpdateContentDataParams(state, draftId));

          const parsedData = parseContentData(data.payload.content);

          if (draftId) {
            set((prev) => void (prev.drafts[data.payload.draftId] = data.payload.draftId));
            set((prev) => void (prev.savedContentDataList[data.payload.draftId] = parsedData));
          } else {
            set((prev) => void (prev.draftId = data.payload.draftId));
            set((prev) => void (prev.savedContentData = parsedData));
          }
        } catch (err) {
          notify({ message: 'Ошибка обновления данных', type: 'error' });
          set((state) => void (state.loadingContentData = false));
        } finally {
          set((state) => void (state.loadingContentData = false));
        }
      },

      /** несколько блоков в одном конструкторе */
      drafts: {},
      setDraftIdToMap: (draftId) => {
        set((state) => {
          state.drafts[draftId] = draftId;
        });
      },

      savedContentDataList: {},
    })),
  ),
);
