import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Button, Text, useMantineTheme } from '@mantine/core';
import dayjs from 'dayjs';
import { Pencil } from 'tabler-icons-react';
import { useDebouncedCallback } from 'use-debounce';

import { getItemPermissions } from '@/utils/permissions';

import { formatDate } from '@/constants/common';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import { setSortDirection } from '@/ui/components/CustomTable/CustomTable.helppers';
import { ITableColumns } from '@/ui/components/CustomTable/CustomTable.types';

import { promotionDefaultFilter } from '@/store/slices/promotions/promotions.constants';
import {
  IPromotionItem,
  PromotionFilterSlice,
  ReturnedPromotionConfig,
} from '@/store/slices/promotions/promotions.types';
import {
  isPromotionDirtySelector,
  promotionFiltersSelector,
  promotionsFilterActions,
} from '@/store/slices/promotions/promotionsSlice';

export const usePromotionsConfig = (fetch: (params: PromotionFilterSlice) => void) => {
  const dispatch = useAppDispatch();

  const filters = useAppSelector(promotionFiltersSelector);
  const { resetFilters, setFilter, setFilterWithResetCurrentPage } = promotionsFilterActions;

  const handleSearchDebounced = useDebouncedCallback((params: PromotionFilterSlice) => {
    fetch({ ...filters, ...params });
  }, 500);
  const actionWithDebounce = (params: PromotionFilterSlice) => {
    dispatch(setFilterWithResetCurrentPage(params));
    handleSearchDebounced(params);
  };
  const isDirty = useAppSelector(isPromotionDirtySelector);
  const onChange = (params: PromotionFilterSlice) => {
    dispatch(setFilter(params));
    fetch({ ...filters, ...params });
  };

  const onReset = () => {
    dispatch(resetFilters());
    fetch(promotionDefaultFilter);
  };

  const onChangeSort = (sortField: string) => {
    const params = {
      sortField,
      sortDirection: setSortDirection(filters.sortDirection),
    };
    onChange({ ...filters, ...params });
  };

  useEffect(() => {
    fetch(filters);
    return () => {
      dispatch(setFilter(promotionDefaultFilter));
    };
  }, []);

  return { actionWithDebounce, isDirty, onChange, onReset, filters, onChangeSort };
};

export const usePromotionsColumns = ({ onChangeSort, filters }: ReturnedPromotionConfig) => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const getSort = useCallback(
    (title: string) => (filters.sortField === title ? filters.sortDirection : undefined),
    [filters.sortDirection],
  );

  const columns: ITableColumns<IPromotionItem>[] = [
    {
      title: 'ID',
      field: 'id',
      style: { minWidth: 40, maxWidth: 200, width: 'min-content' },
    },
    {
      title: 'Название',
      field: 'title',
      style: { width: '100%' },
      sortAction: () => {
        onChangeSort('title');
      },
      sort: getSort('title'),
    },
    {
      title: 'Активность',
      field: 'isActive',
      style: { minWidth: 120 },

      sortAction: () => {
        onChangeSort('isActive');
      },
      sort: getSort('isActive'),
      render: ({ isActive }) => (
        <Badge
          color={isActive ? 'green' : 'greyLine'}
          size='lg'
          radius='sm'
          variant='filled'
          p={0}
          ml={8}
          style={{ width: 88 }}
        >
          <Text size='sm' c='black' ta='center' fw={400} style={{ textTransform: 'none' }}>
            {isActive ? 'Активно' : 'Не активно'}
          </Text>
        </Badge>
      ),
    },
    {
      title: 'Дата начала',
      field: 'dateActiveStart',
      style: { minWidth: 120 },
      position: 'right',
      sortAction: () => {
        onChangeSort('dateActiveStart');
      },
      sort: getSort('dateActiveStart'),
      render: ({ dateActiveStart }) =>
        dateActiveStart?.value ? dayjs(dateActiveStart?.value).format(formatDate) : ' - ',
    },
    {
      title: 'Дата окончания',
      field: 'dateActiveEnd',
      position: 'right',
      style: { minWidth: 140 },
      sortAction: () => {
        onChangeSort('dateActiveEnd');
      },
      sort: getSort('dateActiveEnd'),
      render: ({ dateActiveEnd }) =>
        dateActiveEnd?.value ? dayjs(dateActiveEnd?.value).format(formatDate) : ' - ',
    },

    {
      title: '',
      gridWidth: 'auto',
      field: 'actions',
      render: ({ uuid: id, permissions }) => {
        const { canGet, canUpdate, canUpdateActivity } = getItemPermissions(
          'PROMOTION_',
          permissions,
        );
        return canGet || canUpdate || canUpdateActivity ? (
          <Button
            onClick={() => {
              navigate(`/promotions/${id}`);
            }}
            name='Редактировать'
            title='Редактировать'
            variant='subtle'
            size='compact-sm'
            style={{ position: 'static' }}
          >
            <Pencil size={22} color={theme.colors.greyLight[9]} />
          </Button>
        ) : null;
      },
    },
  ];
  return columns;
};
