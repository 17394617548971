import { FC, useEffect } from 'react';
import {
  Box,
  Center,
  Container,
  Group,
  LoadingOverlay,
  Paper,
  ScrollArea,
  Text,
  Title,
} from '@mantine/core';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import DictionaryTable from './components/DictionaryTable/DictionaryTable';

import { useDictionaries } from '@/store/slices/dictionary';
import { setServiceChanged } from '@/store/slices/service/service';

export const Dictionary: FC = () => {
  const dispatch = useAppDispatch();
  const { data = [], isLoading } = useDictionaries();

  useEffect(() => {
    dispatch(setServiceChanged(false));
  }, [dispatch]);

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          height: '96vh',
          overflowY: 'hidden',
        }}
      >
        <Container fluid mb={16} style={{ marginLeft: 'initial', marginRight: 'initial' }}>
          <Group justify='space-between'>
            <Title order={1}>Справочники</Title>
          </Group>
        </Container>

        <Paper
          radius={8}
          p={24}
          pb={12}
          shadow='xs'
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            height: 'calc(100% - 40px)',
            maxHeight: '100%',
            overflow: 'hidden',
          }}
        >
          {data && data.length > 0 && (
            <>
              <ScrollArea h='100%'>
                <DictionaryTable elements={data} />
                <LoadingOverlay visible={isLoading} />
              </ScrollArea>
              <Box
                style={{
                  marginTop: 'auto',
                  display: 'flex',
                  position: 'relative',
                  alignItems: 'center',
                  minHeight: '38px',
                }}
              >
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Text size='md' c='gray'>
                    Всего:
                  </Text>
                  &nbsp;
                  <Text size='md'>{data.length}</Text>
                </Box>
              </Box>
            </>
          )}

          {!data?.length && (
            <Box style={{ position: 'relative', height: '100%' }}>
              <LoadingOverlay visible={isLoading} />

              {!isLoading && (
                <Center style={{ height: '100%' }}>
                  <Text ta='center'>У вас нет справочников</Text>
                </Center>
              )}
            </Box>
          )}
        </Paper>
      </Box>
    </>
  );
};
