import { EPromotionVariant } from '@/types/promotions';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, LoadingOverlay, Paper, Tabs } from '@mantine/core';

import { useModePromotion } from '@/api/promotion/hooks/useModePromotionContainer';

import ModalConfirm from '@/ui/components/ModalConfirm/ModalConfirm';
import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import { ContentEditor } from '@/ui/containers/ContentEditor';
import { setTemplateSelectValue, useStoreContentEditor } from '@/ui/containers/ContentEditor/store';
import { ActionsModal } from '@/ui/containers/PromotionCreation/ActionsModal';
import { PromotionDetailInfo } from '@/ui/containers/PromotionCreation/components/PromotionDetailInfo';
import { PROMOTION_CREATION_FORM_ID } from '@/ui/containers/PromotionCreation/components/PromotionForm/PromotionForm.constants';
import {
  SELECT_VALUE,
  TEMPLATE,
} from '@/ui/containers/PromotionCreation/PromotionCreation.constants';
import { getBreadcrumbs } from '@/ui/containers/PromotionCreation/PromotionCreation.helpers';
import { usePromotionCreationState } from '@/ui/containers/PromotionCreation/PromotionCreation.state';
import { useCreationPromotionHandlers } from '@/ui/containers/PromotionCreation/PromotionsCreation.handlers';
import { EAddPromotionConfirmationTexts } from '@/ui/containers/PromotionsContainer';
import { ActionButtons } from '@/ui/containers/PromotionsContainer/components/ActionButtons';

export const PromotionCreation = () => {
  const { id } = useParams();
  const promotionState = usePromotionCreationState(id);
  const breadcrumbs = getBreadcrumbs(promotionState.title);
  const {
    onCancel,
    handleDelete,
    handleSubmit,
    onConfirmLeave,
    onConfirmChangeTemplate,
    onCancelChangeTemplate,
    onChangeForm,
    state,
  } = useCreationPromotionHandlers(id || '', promotionState);
  const setSelectValue = useStoreContentEditor(setTemplateSelectValue);
  const [templateType, setTemplateType] = useState<EPromotionVariant>(EPromotionVariant.COMPACT);

  useModePromotion(promotionState, id);
  const onTrySave = () => {
    promotionState.setTrySavePromotion(true);
  };

  useEffect(() => {
    setSelectValue(SELECT_VALUE[templateType]);
  }, [templateType]);

  return (
    <Box
      style={{
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PageHeader
        breadcrumbs={breadcrumbs}
        title={promotionState.title}
        rightButton={
          <ActionButtons
            permissions={promotionState.permissions}
            isNewPromotion={promotionState.isNewPromotion}
            handleDelete={() => promotionState.setIsShowDeleteModal(true)}
            onSave={onTrySave}
            formID={PROMOTION_CREATION_FORM_ID}
          />
        }
      />
      <Paper
        radius={8}
        p={24}
        shadow='xs'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <LoadingOverlay visible={promotionState.isLoading} />
        <Tabs color='teal' defaultValue='first' style={{ height: '100%', position: 'relative' }}>
          <Tabs.List style={{ borderBottom: 'none' }}>
            <Tabs.Tab value='first' color='primary'>
              Общее
            </Tabs.Tab>
            {promotionState.permissions.canUpdate && (
              <Tabs.Tab value='second' color='primary'>
                Редактор страницы
              </Tabs.Tab>
            )}
          </Tabs.List>

          <Tabs.Panel value='first' pt='xs' style={{ padding: '24px 0 34px 0', height: 'inherit' }}>
            {promotionState.isShowPromotionDetail && (
              <PromotionDetailInfo
                promotionState={promotionState}
                handleSubmit={handleSubmit}
                setIsShouldBlock={onChangeForm}
                setTemplateType={setTemplateType}
              />
            )}
          </Tabs.Panel>

          <Tabs.Panel
            value='second'
            pt='xs'
            style={{ padding: '24px 0 34px 0', height: 'inherit' }}
          >
            <ContentEditor
              template={TEMPLATE[templateType]}
              isForceChangeTemplate={promotionState.isForceChangeTemplate}
            />
          </Tabs.Panel>
        </Tabs>
        {promotionState.isOpenConfirmModal && (
          <ModalConfirm
            title={EAddPromotionConfirmationTexts.TITLE_CHANGE_TEMPLATE}
            text={EAddPromotionConfirmationTexts.DESCRIPTION_CHANGE_TEMPLATE}
            confirmHandler={onConfirmChangeTemplate}
            opened={promotionState.isOpenConfirmModal}
            onClose={onCancelChangeTemplate}
          />
        )}
        <ActionsModal
          isBlockedRoute={state === 'blocked'}
          isDelWithChanges={promotionState.isShouldBlock && promotionState.isShowDeleteModal}
          isDelete={!promotionState.isShouldBlock && promotionState.isShowDeleteModal}
          onConfirmLeave={onConfirmLeave}
          onCancel={onCancel}
          setIsShowModal={() => promotionState.setIsShowDeleteModal(false)}
          handleDelete={handleDelete}
        />
      </Paper>
    </Box>
  );
};
