import { forwardRef } from 'react';
import { Box } from '@mantine/core';
import { IconGripVertical } from '@tabler/icons';
import { EditRowBtn } from 'src/ui/containers/ContentEditor/Rows/RowConfiguration/EditRowBtn';

import { TRow } from '@/ui/containers/ContentEditor/ContentEditor.types';

import { ContentTypeCard } from '../../ContentTypeCard';

import { AddRowButton } from './AddRowButton/AddRowButton';

import styles from './RowConfiguration.module.scss';

interface Props {
  rowData?: TRow;
  rowId: number;
}

export const RowConfiguration = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { rowData, rowId } = props;

  const hasBlocks = !!rowData?.blocks?.length;

  return (
    <Box
      className={cn(styles.root, {
        [styles.rootWithPadding]: !rowData,
      })}
    >
      <AddRowButton rowId={rowId} rowData={rowData} isShowText={!hasBlocks} />

      {hasBlocks && (
        <Box className={styles.contentCardWrapper}>
          {rowData.blocks?.map(({ variant }, cardId) => {
            return (
              <ContentTypeCard
                type='grid'
                key={cardId + String(variant)}
                variant={variant}
                cardId={cardId}
                rowId={rowId}
              />
            );
          })}
        </Box>
      )}

      {rowData && (
        <Box ref={ref}>
          <IconGripVertical className={styles.dndBtn} size={20} color='#8DA2B5' />
        </Box>
      )}

      {rowData && <EditRowBtn rowId={rowId} />}
    </Box>
  );
});
