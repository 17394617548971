import {
  CSSProperties,
  ForwardedRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';

import { useUploadFile } from '@/api/file/hooks/useUploadTempFile';

import { DEFAULT_MAX_SIZE } from '@/ui/components/FileLoader/FileLoader.constants';
import {
  acceptedTypes,
  handleDropAccepted,
  handleDropRejected,
} from '@/ui/components/FileLoader/FileLoader.helpers';
import { getStyles } from '@/ui/components/FileLoader/FileLoader.styles';
import {
  FileLoaderRef,
  IFileLoaderProps,
  IFileWithPreview,
  ISimpleFile,
  ITempFileData,
} from '@/ui/components/FileLoader/FileLoader.types';

export const useFileLoaderState = (props: IFileLoaderProps, ref: ForwardedRef<FileLoaderRef>) => {
  const {
    fileName,
    sessionID,
    savedFormFiles,
    accept = 'images',
    maxSize = DEFAULT_MAX_SIZE,
    error = false,
    disabled,
    onChange,
    onRemove,
  } = props;

  const [files, setFiles] = useState<IFileWithPreview[]>([]);
  const [savedFiled, setSavedFiles] = useState<ISimpleFile[]>(savedFormFiles ?? []);
  const [tempFileData, setTempFileData] = useState<ITempFileData | null>(null);
  const { isLoading, uploadTempFile } = useUploadFile();

  useImperativeHandle(ref, () => ({
    clearFiles() {
      setFiles([]);
      setSavedFiles([]);
    },
    setNewFile(newFiles: ISimpleFile[]) {
      setSavedFiles(newFiles);
    },
  }));

  const uploadFile = async (formData: FormData, size: number, type: string, name: string) => {
    const fileData = await uploadTempFile(formData);

    if (fileData) {
      setSavedFiles([]);
      const path = process.env.MEDIA_STORAGE_URL + fileData.config?.previewConfig?.path;

      onChange?.({
        id: fileData.id,
        path,
        size: size.toString(),
        type,
        name,
      });

      setTempFileData({
        id: fileData.id,
        path,
      });
    }
  };

  const handleUpload = async ({ file, formData }: { file: File; formData: FormData }) => {
    await uploadFile(formData, file.size, file.type, fileName || file.name);

    setFiles([
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        id: nanoid(),
      }),
    ]);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    disabled,
    accept: acceptedTypes[accept],
    maxSize,
    multiple: false,
    onDropAccepted: (acceptedFiles) =>
      handleUpload(handleDropAccepted(acceptedFiles, accept, fileName, sessionID)),
    onDropRejected: (rejectFiles) => handleDropRejected(rejectFiles, maxSize),
  });

  const handleRemove = (id: string) => {
    setFiles((current) => {
      onRemove?.();
      return current.filter((item) => item.id !== id);
    });
    setSavedFiles((current) => current.filter((item) => item.id !== id));
  };

  const filesToRender = files.length === 0 ? savedFiled : files;

  const style = useMemo<CSSProperties>(
    () =>
      getStyles({
        accept: isDragAccept,
        reject: isDragReject || error,
        hasFiles: filesToRender.length > 0,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFocused, isDragAccept, isDragReject, filesToRender, error],
  );

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    files,
    savedFiled,
    tempFileData,
    isLoading,
    filesToRender,
    style,
    fileType: files[0]?.type ?? '',
    fileSize: files[0]?.size ?? '',
    fileId: savedFiled[0]?.id ?? '',
    fileTempId: tempFileData?.id ?? '',
    isShowPreview: filesToRender.length > 0,
    getRootProps,
    getInputProps,
    handleRemove,
  };
};
