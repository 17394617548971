import { FC } from 'react';
import { Group, NumberInput, Radio, TextInput } from '@mantine/core';

import { DictionaryMultiSelect } from '@/ui/components/DictionaryMultiSelect';
import { DictionarySelect } from '@/ui/components/DictionarySelect';

import { AdditionalFieldProps } from './AdditionalField.types';
import { FileField } from './FileField';

export const AdditionalField: FC<AdditionalFieldProps> = ({
  form,
  field,
  data,
  sessionID,
  disabled,
}) => {
  const { type, code, title, isRequired, isMultiple, dictionaryType } = field;
  const name = `fields.${code}`;

  if (type === 'dictionary' && dictionaryType) {
    const props = { label: title, dictionary: dictionaryType, ...form.getInputProps(name) };
    return isMultiple ? <DictionaryMultiSelect {...props} /> : <DictionarySelect {...props} />;
  }

  if (type === 'file') {
    return <FileField {...{ form, field, data, sessionID, disabled }} />;
  }

  if (type === 'int') {
    return (
      <NumberInput
        withAsterisk={isRequired}
        label={title}
        disabled={disabled}
        {...form.getInputProps(name)}
      />
    );
  }

  if (type === 'bool') {
    return (
      <Radio.Group label={title} withAsterisk={isRequired} {...form.getInputProps(name)}>
        <Group>
          <Radio value='true' label='Да' disabled={disabled} />
          <Radio value='false' label='Нет' disabled={disabled} />
        </Group>
      </Radio.Group>
    );
  }

  if (type === 'string') {
    return (
      <TextInput
        withAsterisk={isRequired}
        label={title}
        disabled={disabled}
        {...form.getInputProps(name)}
      />
    );
  }

  return (
    <div>
      Поле "{code}" имеет неизвестный тип: {type}
    </div>
  );
};
