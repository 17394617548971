import React, { FC, useRef } from 'react';
import { Button, Checkbox } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { Plus } from 'tabler-icons-react';
import { v4 as uuid_v4 } from 'uuid';

import { DnDBlock } from '@/ui/components/DnDBlock';
import { handleAddSnippetData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper/SelectSnippetModalWrapper.helpers';
import { GalleryItemUI } from '@/ui/containers/ContentEditor/snippets/GallerySnippetModal/GalleryItemUI';
import { gallerySnippetSchema } from '@/ui/containers/ContentEditor/snippets/GallerySnippetModal/GallerySnippet.schema';
import { EGallerySnippetFormFields } from '@/ui/containers/ContentEditor/snippets/GallerySnippetModal/GallerySnippetModal.constants';
import { useGallerySnippetModal } from '@/ui/containers/ContentEditor/snippets/GallerySnippetModal/GallerySnippetModal.handlers';
import {
  getGallerySnippetInitialValues,
  replaceFiles,
} from '@/ui/containers/ContentEditor/snippets/GallerySnippetModal/GallerySnippetModal.helpers';
import { useGetSnippetData } from '@/ui/containers/ContentEditor/snippets/hooks/useGetSnippetData';
import { SnippetModal } from '@/ui/containers/ContentEditor/snippets/SnippetModal';

const GalleryModalUI: FC = () => {
  const { defaultValues, snippetData, handleClose } = useGetSnippetData('gallery');
  const sessionID = useRef(uuid_v4());

  const form = useForm({
    initialValues: getGallerySnippetInitialValues(defaultValues),
    validate: yupResolver(gallerySnippetSchema),
  });

  const visibleAddBtn =
    Object.values(form.values.slides?.at(-1) || {}).some((value) => !!value) ||
    !form.values.slides?.length;

  const handlers = useGallerySnippetModal({ form });

  return (
    <SnippetModal
      isOpen
      isNew={snippetData?.isNew}
      onClose={handleClose}
      title='Галерея'
      handleClickBackBtn={handleClose}
      onSubmit={form.onSubmit(async (values) => {
        await replaceFiles(values, sessionID.current);
        return handleAddSnippetData('gallery', values, handleClose, snippetData);
      })}
    >
      <div style={{ gridColumn: 'span 12' }}>
        <Checkbox
          label='Зациклить слайдер'
          checked={form.values[EGallerySnippetFormFields.LOOP_SLIDER]}
          {...form.getInputProps(EGallerySnippetFormFields.LOOP_SLIDER)}
        />
      </div>
      {form.values.slides?.map((slide, index) => {
        return (
          <DnDBlock
            key={index}
            id={slide.image.id}
            moveItem={handlers.handleMoveItem}
            index={index}
          >
            <GalleryItemUI {...{ index, form, slide, sessionID: sessionID.current }} key={index} />
          </DnDBlock>
        );
      })}
      <div style={{ gridColumn: 'span 12', height: 60, display: 'flex' }}>
        {visibleAddBtn && (
          <Button
            variant='white'
            style={{ padding: '10px 0 0 0', height: 'min-content' }}
            onClick={handlers.handleAddSlide}
          >
            <Plus size={18} style={{ marginRight: 10 }} />
            Добавить ещё изображения
          </Button>
        )}
      </div>
    </SnippetModal>
  );
};

export const GallerySnippetModal = React.memo(GalleryModalUI);
