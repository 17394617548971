import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Group } from '@mantine/core';

import { ReactComponent as EnlightmentMini } from '../../../../../../assets/icons/enlighten-mini.svg';
import { ReactComponent as Enlightment } from '../../../../../../assets/icons/enlightment.svg';

interface ILayoutNavbarLogo {
  opened?: boolean;
}

export const LayoutNavbarLogo: FC<ILayoutNavbarLogo> = ({ opened = true }) => {
  const navigate = useNavigate();

  return (
    <Group
      gap={10}
      wrap='nowrap'
      align='start'
      style={{ height: opened ? 40 : 56, marginBottom: 24, cursor: 'pointer' }}
      onClick={() => navigate('/')}
    >
      {opened && <Enlightment width={104} height={40} />}
      {!opened && <EnlightmentMini width={24} height={24} />}
    </Group>
  );
};
