import React, { FC, ReactNode } from 'react';
import { Box, Text } from '@mantine/core';

interface IProps {
  icon: ReactNode;
  title: string;
  description: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const TemplateCard: FC<IProps> = ({ icon, description, title, onClick, disabled }) => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        zIndex: 1,
        width: '100%',
        minWidth: 250,
        height: 244,
        borderRadius: 4,
        border: '1px solid #E9ECEF',
        background: '#FFFFFF',
        padding: 18,
        opacity: disabled ? 0.3 : 1,
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
      onClick={!disabled ? onClick : undefined}
    >
      {icon}
      <Text fz={16} ta='left' fw={600} c='dark6' pt={12}>
        {title}
      </Text>
      <Text fz={14} ta='left' fw={400} c='dark6' pt={8}>
        {description}
      </Text>
    </Box>
  );
};
