import { FC } from 'react';
import { useSelector } from 'react-redux';
import { AppShell, ScrollArea } from '@mantine/core';
import {
  AdjustmentsHorizontal,
  Books,
  DeviceDesktop,
  File,
  FileDownload,
  LayoutGrid,
  Notebook,
  Notes,
  ScreenShare,
  Tool,
} from 'tabler-icons-react';

import { useLocalStorageRoute } from '@/hooks/useLocationStorageRoute';

import { LayoutNavbarBurger } from './components/LayoutNavbarBurger';
import { LayoutNavbarButton } from './components/LayoutNavbarButton';
import { LayoutNavbarFooter } from './components/LayoutNavbarFooter';
import { LayoutNavbarLogo } from './components/LayoutNavbarLogo';
import { usePermissions } from './LayoutNavbar.hooks';

import styles from './LayoutNavbar.module.scss';

import { ReactComponent as Banners } from '@/assets/icons/banners.svg';
import { ReactComponent as UmkIcon } from '@/assets/icons/book.svg';
import { ReactComponent as BookIcon } from '@/assets/icons/books.svg';
import { ReactComponent as ProjectIcon } from '@/assets/icons/project.svg';
import { ReactComponent as QuestionsMenuIcon } from '@/assets/icons/questions-menu.svg';
import { ReactComponent as ReviewMenuIcon } from '@/assets/icons/review-menu.svg';
import { ReactComponent as SupportIcon } from '@/assets/icons/support.svg';
import { selectCurrentService } from '@/store/slices/service/service';

interface Props {
  opened: boolean;
  setOpened: (open: boolean) => void;
}

export const LayoutNavbar: FC<Props> = ({ opened, setOpened }) => {
  const perms = usePermissions();
  const currentService = useSelector(selectCurrentService);

  useLocalStorageRoute();

  return (
    <AppShell.Navbar
      className={cn(styles.root)}
      /*width={{ base: opened ? 220 : 72 }} height='100vh'*/ pl={24}
      py={30}
    >
      <AppShell.Section pb={20}>
        <LayoutNavbarLogo opened={opened} />
        <LayoutNavbarBurger opened={opened} onClick={() => setOpened(!opened)} />
      </AppShell.Section>

      <AppShell.Section grow component={ScrollArea}>
        {currentService === 'configurations' && (
          <>
            {perms.allSettings && (
              <LayoutNavbarButton
                opened={opened}
                to='/general-settings'
                label='Общие настройки'
                icon={<AdjustmentsHorizontal size={24} />}
              />
            )}
            {perms.projects && (
              <LayoutNavbarButton
                opened={opened}
                to='/projects'
                label='Проекты'
                icon={<ProjectIcon size={24} />}
              />
            )}
            {perms.uploads && (
              <LayoutNavbarButton
                opened={opened}
                to='/file-upload'
                label='Медиахранилище'
                icon={<FileDownload size={24} />}
              />
            )}
            {perms.features && (
              <LayoutNavbarButton
                opened={opened}
                to='/feature-toggles'
                label='Сервис включения функциональности'
                icon={<Tool size={24} />}
              />
            )}
            {perms.menu && (
              <LayoutNavbarButton
                opened={opened}
                to='/menu'
                label='Меню'
                icon={<LayoutGrid size={24} />}
              />
            )}
            {perms.menu && (
              <LayoutNavbarButton
                opened={opened}
                to='/cube'
                label='Меню платформы'
                icon={<LayoutGrid size={24} />}
              />
            )}
            {perms.dictionary && (
              <LayoutNavbarButton
                opened={opened}
                to={'/dictionary'}
                label='Справочники'
                icon={<File size={24} />}
              />
            )}
          </>
        )}

        {currentService === 'content' && (
          <>
            {perms.articles && (
              <LayoutNavbarButton
                opened={opened}
                to='/materials'
                label='Материалы'
                icon={<BookIcon />}
              />
            )}
            {perms.news && (
              <LayoutNavbarButton
                opened={opened}
                to='/news'
                label='Новости'
                icon={<Books size={24} />}
              />
            )}
            {perms.banners && (
              <LayoutNavbarButton
                opened={opened}
                to='/banners'
                label='Баннеры'
                icon={<Banners size={24} />}
              />
            )}
            {perms.promotions && (
              <LayoutNavbarButton
                opened={opened}
                to='/promotions'
                label='Акции'
                icon={<BookIcon size={24} />}
              />
            )}
            <LayoutNavbarButton
              opened={opened}
              to='/selections-places'
              label='Подборки'
              icon={<BookIcon size={24} />}
            />
            {perms.promotions && (
              <LayoutNavbarButton
                opened={opened}
                to='/umk'
                label='УМК'
                icon={<UmkIcon size={24} />}
              />
            )}
          </>
        )}

        {currentService === 'support' && (
          <>
            {perms.reviews && (
              <LayoutNavbarButton
                opened={opened}
                to='/reviews'
                label='Отзывы'
                icon={<ReviewMenuIcon size={24} />}
              />
            )}
            {perms.questions && (
              <LayoutNavbarButton
                opened={opened}
                to='/questions'
                label='Вопросы'
                icon={<QuestionsMenuIcon size={24} />}
              />
            )}
            {perms.faq && (
              <LayoutNavbarButton
                opened={opened}
                to='/support'
                label='Поддержка - F.A.Q.'
                icon={<SupportIcon size={24} />}
              />
            )}
          </>
        )}

        {currentService === 'seo' && (
          <>
            {perms.globalSettings && (
              <LayoutNavbarButton
                opened={opened}
                to='/global-settings'
                label='Глобальные настройки'
                icon={<DeviceDesktop size={24} />}
              />
            )}
            {perms.redirects && (
              <LayoutNavbarButton
                opened={opened}
                to='/redirects'
                label='Редиректы'
                icon={<ScreenShare size={24} />}
              />
            )}
            {perms.templates && (
              <LayoutNavbarButton
                opened={opened}
                to='/templates-meta-tags'
                label='Шаблоны мета-тегов'
                icon={<Notes size={24} />}
              />
            )}
            {perms.metas && (
              <LayoutNavbarButton
                opened={opened}
                to='/meta-tags-any-page'
                label='Мета-теги для любой страницы'
                icon={<Notebook size={24} />}
              />
            )}
            {perms.metas && (
              <LayoutNavbarButton
                opened={opened}
                to='/tags'
                label='Теги в каталоге'
                icon={<Notebook size={24} />}
              />
            )}
          </>
        )}
      </AppShell.Section>

      <AppShell.Section>
        <LayoutNavbarFooter opened={opened} />
      </AppShell.Section>
    </AppShell.Navbar>
  );
};
