import { TBreadcrumbsExtItem } from '@/types';

import { FC, ReactNode } from 'react';
import { BoxProps, Container, Group } from '@mantine/core';

import BreadcrumbsExt from '../../../BreadcrumbsExt/BreadcrumbsExt';

import PageHeaderBackLink from './components/PageHeaderBackLink/PageHeaderBackLink';
import PageHeaderTitle from './components/PageHeaderTitle/PageHeaderTitle';

interface IProps extends BoxProps {
  title: string;
  subTitle?: string;
  backLink?: string;
  rightButton?: ReactNode;
  breadcrumbs?: TBreadcrumbsExtItem[];
}

const PageHeader: FC<IProps> = ({ title, subTitle, backLink, rightButton, breadcrumbs }) => {
  return (
    <Container fluid mb={16} style={{ width: '100%', height: 'fit-content' }}>
      {breadcrumbs && <BreadcrumbsExt items={breadcrumbs} />}

      <Group justify='space-between'>
        <Group justify='flex-start' style={{ alignItems: 'baseline' }}>
          {backLink && <PageHeaderBackLink url={backLink} />}
          <PageHeaderTitle title={title} subTitle={subTitle} />
        </Group>

        {rightButton}
      </Group>
    </Container>
  );
};

export default PageHeader;
