import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '@/api/instance';
import { URLS } from '@/api/urls';

import { getFormatDateEndDay, getFormatDateStartDay } from '@/utils/getFormatDateForBackend';
import { getUrlWithSearchParams } from '@/utils/getUrlWithSearchParams';

import { State } from '@/store';
import { createTableFilterSlice } from '@/store/slices/banners/bannersSlice';
import { promotionDefaultFilter } from '@/store/slices/promotions/promotions.constants';
import {
  PromotionFilterSlice,
  PromotionsResponse,
} from '@/store/slices/promotions/promotions.types';

export const promotionsFilterSlice = createTableFilterSlice<PromotionFilterSlice>({
  name: 'promotionsFilter',
  initialState: promotionDefaultFilter,
});

export const promotionsFilterActions = promotionsFilterSlice.actions;

export const promotionsApi = createApi({
  reducerPath: 'api/promotions',
  baseQuery: axiosBaseQuery(),
  tagTypes: [],
  endpoints: (build) => ({
    getPromotionsListing: build.query<PromotionsResponse, PromotionFilterSlice>({
      query: (params) => {
        const { limit, currentPage, title, dateActiveEnd, dateActiveStart, ...rest } = params;
        const newParams = {
          offset: currentPage === 1 ? 0 : Number(limit) * (Number(currentPage) - 1),
          limit,
          title,
          isActive: rest.isActive,
          sortField: rest.sortField,
          sortDirection: rest.sortDirection,
          dateActiveStartFrom: getFormatDateStartDay(dateActiveStart?.[0]),
          dateActiveStartTo: getFormatDateEndDay(dateActiveStart?.[1]),
          dateActiveEndFrom: getFormatDateStartDay(dateActiveEnd?.[0]),
          dateActiveEndTo: getFormatDateEndDay(dateActiveEnd?.[1]),
        };

        return {
          url: getUrlWithSearchParams(URLS.promotions.getPromotionsList, newParams),
        };
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useLazyGetPromotionsListingQuery } = promotionsApi;

const promotionFilter = (state: State) => state.promotionsFilter;

export const promotionFiltersSelector = createSelector(promotionFilter, (filters) => filters);

export const isPromotionDirtySelector = createSelector(promotionFilter, (filters) => {
  const currentFilter: PromotionFilterSlice = {
    isActive: filters.isActive,
    sortField: filters.sortField,
    title: filters.title,
    dateActiveStart: filters.dateActiveStart,
    dateActiveEnd: filters.dateActiveEnd,
  };

  const defaultFilter: PromotionFilterSlice = {
    isActive: promotionDefaultFilter.isActive,
    sortField: promotionDefaultFilter.sortField,
    title: promotionDefaultFilter.title,
    dateActiveStart: promotionDefaultFilter.dateActiveStart,
    dateActiveEnd: promotionDefaultFilter.dateActiveEnd,
  };

  return JSON.stringify(currentFilter) !== JSON.stringify(defaultFilter);
});
