import { IMetaTag } from '@/types/metaTagsAnyPage/metaTagsAnyPage';

import { createSelectorItems } from '@/utils/createSelectorItems';

import {
  DEFAULT_ROBOTS_VALUE,
  EMetaTagFormFields,
} from '@/ui/containers/MetaTagsCreationContainer/MetaTagsForm/MetaTagsForm.constants';
import { IMetaTagFormFields } from '@/ui/containers/MetaTagsCreationContainer/MetaTagsForm/MetaTagsForm.types';

export const getDefaultValues = (metaTag: IMetaTag | null): IMetaTagFormFields => {
  return {
    [EMetaTagFormFields.SITE]: metaTag?.site || '',
    [EMetaTagFormFields.SITE_CHECKBOX]: metaTag?.isHideInSitemap || false,
    [EMetaTagFormFields.TYPE_PAGE]: metaTag?.pageType || '',
    [EMetaTagFormFields.PAGE_H1]: metaTag?.pageH1 || '',
    [EMetaTagFormFields.PAGE_H1_CHECKBOX]: metaTag?.isUseTemplatePageH1 || false,
    [EMetaTagFormFields.TITLE]: metaTag?.title || '',
    [EMetaTagFormFields.TITLE_CHECKBOX]: metaTag?.isUseTemplateTitle || false,
    [EMetaTagFormFields.DESCRIPTION]: metaTag?.description || '',
    [EMetaTagFormFields.DESCRIPTION_CHECKBOX]: metaTag?.isUseTemplateDescription || false,
    [EMetaTagFormFields.KEYWORDS]: metaTag?.keywords || '',
    [EMetaTagFormFields.KEYWORDS_CHECKBOX]: metaTag?.isUseTemplateKeywords || false,
    [EMetaTagFormFields.ROBOTS]: metaTag?.robots || DEFAULT_ROBOTS_VALUE,
    [EMetaTagFormFields.ROBOTS_CHECKBOX]: metaTag?.isUseTemplateRobots || false,
    [EMetaTagFormFields.AUTHOR]: metaTag?.author || '',
    [EMetaTagFormFields.AUTHOR_CHECKBOX]: metaTag?.isUseTemplateAuthor || false,
    [EMetaTagFormFields.COPYRIGHT]: metaTag?.copyright || '',
    [EMetaTagFormFields.COPYRIGHT_CHECKBOX]: metaTag?.isUseTemplateCopyright || false,
    [EMetaTagFormFields.CONTENT]: metaTag?.content || '',
    [EMetaTagFormFields.CONTENT_CHECKBOX]: metaTag?.isUseTemplateContent || false,
    [EMetaTagFormFields.CANONICAL_URL]: metaTag?.canonical || '',
    [EMetaTagFormFields.CANONICAL_URL_CHECKBOX]: metaTag?.isUseTemplateCanonical || false,
  };
};
