import { IMaterials } from '@/types/materials/materials';

import { useMemo, useRef, useState } from 'react';
import { v4 as uuid_v4 } from 'uuid';

import { useCreateDraft } from '@/api/draft/useCreateDraft';
import { useCreateMaterial } from '@/api/materials/hooks/useCreateMaterial';
import { useDeleteMaterial } from '@/api/materials/hooks/useDeleteMaterial';
import { useGetMaterial } from '@/api/materials/hooks/useGetMaterial';
import { useToggleActiveMaterial } from '@/api/materials/hooks/useToggleActiveMaterial';
import { useUpdateMaterials } from '@/api/materials/hooks/useUpdateMaterial';

import { getItemPermissions } from '@/utils/permissions';

import { usePermission } from '@/hooks/usePermissions';

import {
  setSelectorDefaultFormData,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';
import { getBreadcrumbs } from '@/ui/containers/MaterialsCreationContainer/MaterialCreationContainer.helpers';
import { IMaterialsFormFields } from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialForm.types';
import { IMaterialState } from '@/ui/containers/MaterialsCreationContainer/MaterialState.types';

export const useMaterialState = (id: string = ''): IMaterialState => {
  const canCreate = usePermission('ARTICLE_CREATE');
  const draftId = useStoreContentEditor((state) => state.draftId);

  const isNewMaterial = !Boolean(id);
  const [material, setMaterial] = useState<IMaterials | null>(null);
  const title = isNewMaterial ? 'Новый материал' : `${material?.title || `Материал`}`;
  const [isShouldBlock] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const breadcrumbs = getBreadcrumbs(title);
  const isShowMaterialsDetail = isNewMaterial || (!isNewMaterial && material);
  const sessionID = useRef(uuid_v4());
  const defaultFormData = useStoreContentEditor((state) =>
    state.getDefaultFormData<IMaterialsFormFields>(),
  );
  const setDefaultFormData = useStoreContentEditor(setSelectorDefaultFormData);

  const [trySaveMaterials, setTrySaveMaterials] = useState<boolean>(false);

  const { isLoading: isCreateMaterialsLoading, createMaterial } = useCreateMaterial();
  const { isLoading: isCreateDraftLoading, createDraft } = useCreateDraft();
  const { isLoading: isGetMaterialLoading, getMaterial } = useGetMaterial();
  const { isLoading: isUpdateMaterialsLoading, updateMaterial } = useUpdateMaterials();
  const { isLoading: isUpdateMaterialsActivityLoading, toggleActive } = useToggleActiveMaterial();
  const updateMaterialActivity: IMaterialState['updateMaterialActivity'] = (uuid, isActive) =>
    toggleActive({ uuid, isActive }).then((res) => !!res);

  const { isLoading: isDeleteMaterialsLoading, deleteMaterial } = useDeleteMaterial();

  const isLoading =
    isCreateMaterialsLoading ||
    isCreateDraftLoading ||
    isUpdateMaterialsLoading ||
    isUpdateMaterialsActivityLoading ||
    isDeleteMaterialsLoading ||
    isGetMaterialLoading;

  const permissions = useMemo(() => {
    const res = getItemPermissions('ARTICLE_', material?.permissions);
    return {
      ...res,
      canView: !material || !!(isNewMaterial ? canCreate : res.canGet),
      canSave: !!(isNewMaterial ? canCreate : res.canUpdate || res.canUpdateActivity),
      canUpdate: !!(isNewMaterial ? canCreate : res.canUpdate),
    };
  }, [isNewMaterial, canCreate, material]);

  return {
    permissions,
    isNewMaterial,
    material,
    title,
    draftId,
    isShouldBlock,
    breadcrumbs,
    isShowMaterialsDetail,
    sessionID,
    defaultFormData,
    setDefaultFormData,
    isLoading,
    trySaveMaterials,
    isDeleteModalOpen,
    setTrySaveMaterials,
    setMaterial,
    getMaterial,
    createMaterial,
    createDraft,
    updateMaterial,
    updateMaterialActivity,
    deleteMaterial,
    setIsDeleteModalOpen,
  };
};
