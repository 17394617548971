import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Paper } from '@mantine/core';

import { GeneralInformationCard } from '@/ui/components/GeneralInformationCard/GeneralInformationCard';
import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import { ReviewDetailFile } from '@/ui/containers/Reviews/ReviewsDetails/components/modals/ReviewDetailFile';
import { ReviewForm } from '@/ui/containers/Reviews/ReviewsDetails/components/ReviewForm';
import { ReviewActionButtons } from '@/ui/containers/Reviews/ReviewsDetails/ReviewActionButtons/ReviewActionButtons';

import { useGetSingleReviewQuery } from '@/store/slices/reviews/reviewsSlice';

export const ReviewsDetails = () => {
  const params = useParams();

  const { data, isLoading } = useGetSingleReviewQuery(params?.id || '');

  if (isLoading) {
    return <div>Loading</div>;
  }

  const breadcrumbs = [
    {
      name: 'Отзывы',
      url: '/reviews',
    },
    {
      name: `ID: ${params?.id || ''}`,
    },
  ];

  return (
    <Box
      style={{
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PageHeader
        backLink={'/reviews'}
        breadcrumbs={breadcrumbs}
        title={`ID: ${params?.id || ''}`}
        rightButton={<ReviewActionButtons data={data} />}
      />
      <Paper
        radius={8}
        p={24}
        shadow='xs'
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          height: '100%',
          overflow: 'auto',
          position: 'relative',
          gap: 24,
        }}
      >
        {data && <ReviewForm isLoading={isLoading} data={data} />}
        <GeneralInformationCard
          generalId={data?.payload.id}
          cardType='review'
          rating={data?.payload.rating}
          author={{
            name: data?.payload.author.name,
            uuid: data?.payload.author.id,
            createDate: data?.payload.createDate,
            isEntity: data?.payload.author.isEntity,
            entityName: data?.payload.author.entityName,
          }}
          editor={{
            name: data?.payload.reviewChange?.user?.name || ' - ',
            email: data?.payload.reviewChange?.user?.email,
            uuid: data?.payload.reviewChange?.user?.id,
            editDate: data?.payload.reviewChange?.updateAt,
          }}
          entityCode={data?.payload.entity.code}
        />
        <ReviewDetailFile />
      </Paper>
    </Box>
  );
};
