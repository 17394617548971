import { useLocation } from 'react-router-dom';
import { Box, Button, Group, MultiSelect, Select, TextInput } from '@mantine/core';
import { DatePickerInput, DatesRangeValue } from '@mantine/dates';
import { RotateClockwise } from 'tabler-icons-react';

import { formatDate, PAGE_ELEMENTS } from '@/constants/common';

import { ReviewAndQuestionsFilterProps } from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.types';

import {
  replayOptions,
  reviewsProductRating,
  reviewStatusOptions,
} from './ReviewAndQuestionsFilter.consts';

import styles from './ReviewAndQuestionsFilter.module.scss';

import { inputStyles } from '@/pages/Banners/BannersListing/BannerListing.constants';

export const ReviewAndQuestionsFilter = (props: ReviewAndQuestionsFilterProps) => {
  const {
    isDirty,
    id,
    article,
    rating,
    createDate,
    status,
    hasReply,
    actionWithDebounce,
    onReset,
    limit,
    onChange,
  } = props;

  const params = useLocation();

  const isQuestionPage = params.pathname.includes('questions');

  const onChangeDate = (v: DatesRangeValue) => {
    if (!v[0] && !v[1]) {
      return actionWithDebounce({ createDate: [null, null] });
    } else if (v[0] && v[1]) {
      return actionWithDebounce({ createDate: v });
    }
  };

  return (
    <Group className={styles.root} pb='lg' grow mb={24}>
      <Box style={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 208 }}>
        <TextInput
          label={isQuestionPage ? 'ID вопроса' : 'ID отзыва'}
          value={id}
          styles={inputStyles}
          onChange={(v) => actionWithDebounce({ id: v.target.value })}
          placeholder={isQuestionPage ? 'Введите ID вопроса' : 'Введите ID отзыва'}
        />
      </Box>
      <Box
        style={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 190, maxWidth: 190 }}
      >
        <MultiSelect
          label='Статус'
          data={reviewStatusOptions}
          value={status}
          onChange={(value) => actionWithDebounce({ status: value })}
        />
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 208 }}>
        <TextInput
          label='Артикул'
          value={article}
          styles={inputStyles}
          onChange={(v) => actionWithDebounce({ article: v.target.value })}
          placeholder='Введите артикул'
        />
      </Box>
      {!isQuestionPage && (
        <Box
          style={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 190, maxWidth: 190 }}
        >
          <MultiSelect
            label='Оценка'
            data={reviewsProductRating}
            value={rating}
            onChange={(value) => actionWithDebounce({ rating: value })}
          />
        </Box>
      )}
      <Box
        style={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 190, maxWidth: 190 }}
      >
        <Select
          label='Наличие ответа'
          data={replayOptions}
          value={hasReply}
          clearable
          onChange={(value) => {
            if (value === hasReply) {
              actionWithDebounce({ hasReply: '' });
            } else {
              actionWithDebounce({ hasReply: value ?? '' });
            }
          }}
        />
      </Box>
      <Box
        style={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 272, maxWidth: 272 }}
      >
        <DatePickerInput
          label='Дата создания'
          type='range'
          allowSingleDateInRange
          // dropdownPosition='bottom-start'
          value={createDate}
          onChange={onChangeDate}
          locale='ru'
          placeholder='Выберите период'
          valueFormat={formatDate}
          className={styles.datepicker}
        />
      </Box>
      <div style={{ display: 'flex', marginLeft: 'auto', justifyContent: 'end' }}>
        {isDirty && (
          <Box
            style={{ display: 'flex', alignItems: 'center', height: 38, maxWidth: 'fit-content' }}
          >
            <Button
              className={styles.reset}
              variant='subtle'
              radius='xs'
              size='sm'
              rightSection={<RotateClockwise size={20} />}
              onClick={onReset}
            >
              Сбросить
            </Button>
          </Box>
        )}
        <Box className={styles.pageSize}>
          <Select
            className={styles.select}
            size='sm'
            value={limit || PAGE_ELEMENTS[0].value}
            onChange={(v) => onChange({ limit: v as string })}
            allowDeselect
            data={PAGE_ELEMENTS}
          />
        </Box>
      </div>
    </Group>
  );
};
