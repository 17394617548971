import { IFile } from '@/types/file';

import { ChangeEvent, MouseEvent, useRef, useState } from 'react';
import { useForm } from '@mantine/form';
import { v4 as uuid_v4 } from 'uuid';

import { replaceTempFile } from '@/api';

import notify from '@/utils/notify';

import { FileLoaderRef } from '@/ui/components/FileLoader';
import { IFileLoaderOnChangeParams } from '@/ui/components/FileLoader/FileLoader.types';
import { getImageData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper/SelectSnippetModalWrapper.helpers';

export const useFileLoaderForm = () => {

  const sessionID = useRef(uuid_v4());
  const fileLoaderRef = useRef<FileLoaderRef | null>(null);
  const [file, setFile] = useState<IFile | null>(null);

  const mediaStorageUrl = process.env.MEDIA_STORAGE_URL as string;

  const [fileName, setFileName] = useState('');

  const form = useForm({
    initialValues: {
      file: {
        id: '',
        path: '',
        name: '',
      },
    },
  });

  const handleChange = ({ id, path, name }: IFileLoaderOnChangeParams) => {
    form.setFieldValue('file', { id, path, name });
  };

  const handleRemove = () => {
    form.setFieldValue('file', { id: '', path: '', name: '' });
  };

  const imgData = getImageData({
    id: form.values.file.id,
    name: form.values.file.name,
    path: form.values.file.path.replace(mediaStorageUrl, ''),
  });

  const copyHandler = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(mediaStorageUrl + file?.config.previewConfig.path);
      notify({ message: `Ссылка на файл скопирована в буфер обмена`, type: 'success' });
    } catch (error) {
      notify({ message: `Ошибка при копировании ссылки`, type: 'error' });
    }
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => setFileName(e.target.value);

  const handleSubmit = (values: typeof form.values) => {
    if (!values.file.id) return;

    replaceTempFile({
      query: {
        sessionId: sessionID.current,
        ids: [values.file.id],
      },
    })
      .then((fileResponse) => {
        setFile(fileResponse?.payload.items[0] || null);
        handleRemove();
        form.reset();
      })
      .catch((error) => {
        console.error('Error replacing temp file:', error);
      });
  };

  const fileLink = mediaStorageUrl + file?.config.previewConfig.path;

  const resetForm = () => {
    fileLoaderRef.current?.clearFiles();
    setFileName('');
  };

  return {
    fileLink,
    sessionID,
    file,
    mediaStorageUrl,
    fileName,
    form,
    imgData,
    fileLoaderRef,
    copyHandler,
    handleChange,
    handleRemove,
    onInputChange,
    handleSubmit,
    resetForm,
  };
};
