import { TMenu } from '@/types';

import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '@mantine/core';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { TableColumnTitle } from '@/ui/components/TableExt/components/TableColumnTitle';
import { TableHeadSortable } from '@/ui/components/TableExt/components/TableHeadSortable';

import { setTypeMenu } from '@/store/slices/menu/menuTypes';

interface IProps {
  items: TMenu[];
}

export const MenuContainerTable: FC<IProps> = ({ items }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onClickHandler = (code: string) => {
    dispatch(setTypeMenu({ type: code }));
    navigate(`/menu/${code}`);
  };

  return (
    <Table highlightOnHover verticalSpacing='xs' fz='sm' horizontalSpacing='xs'>
      <Table.Thead className='sticky'>
        <Table.Tr>
          <TableHeadSortable name='Название' onSort={() => {}} reversed={false} sorted />
          <Table.Th>
            <TableColumnTitle>Код</TableColumnTitle>
          </Table.Th>
          <Table.Th style={{ width: '500px' }}>&nbsp;</Table.Th>
        </Table.Tr>
      </Table.Thead>

      <Table.Tbody>
        {items?.map((item, index) => (
          <Table.Tr
            key={index}
            style={{ cursor: 'pointer' }}
            onClick={() => onClickHandler(item.code)}
          >
            <Table.Td>{item.name}</Table.Td>
            <Table.Td>{item.code}</Table.Td>
            <Table.Td style={{ width: '500px' }}></Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
};
