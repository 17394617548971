import React from 'react';
import { Box, Slider as SliderLib, Text } from '@mantine/core';

import { TSliderProps } from './Slider.type';

export const Slider: React.FC<TSliderProps> = (props) => {
  return (
    <>
      <Text span>{props.title}</Text>

      <Box style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        <SliderLib
          labelTransition='skew-down'
          labelTransitionDuration={150}
          labelTransitionTimingFunction='ease'
          {...(props.elementProps || {})}
        />

        {props.description && (
          <Text span fz={14} fw={400} c='greyDark'>
            {props.description}
          </Text>
        )}
      </Box>
    </>
  );
};
