import { FC } from 'react';
import { useDocumentTitle } from '@mantine/hooks';

import { TITLE_MENU } from '@/constants/titles';

import { MenuContainer } from '@/ui/containers/MenuContainer';

export const Menu: FC = () => {
  useDocumentTitle(TITLE_MENU);

  return <MenuContainer />;
};
