import { FC, Fragment } from 'react';
import { Container, Grid, Text } from '@mantine/core';

interface IProps {
  title: string;
  items: any[];
}

export const FieldModifiers: FC<IProps> = ({ title, items }) => {
  return (
    <Container p={0} m={0}>
      <Text c='greyDark' style={{ gridColumn: 'span 2', fontWeight: 700 }}>
        {title}
      </Text>
      <Grid c='greyDark' style={{ fontSize: '14px', fontWeight: 400, margin: 0 }} p={0} m={0}>
        {items.map(({ id, fullText, shortText, templateText }) => {
          return (
            <Fragment key={id}>
              <Grid.Col span={4} style={{ paddingLeft: 0, width: 105 }}>
                <Text c='greyDark'>{fullText}</Text>
              </Grid.Col>
              <Grid.Col span={2} style={{ textAlign: 'center' }}>
                <Text c='greyDark'>{shortText}</Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text c='greyDark'>{templateText}</Text>
              </Grid.Col>
            </Fragment>
          );
        })}
      </Grid>
    </Container>
  );
};
