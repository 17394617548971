import React, { FC, useEffect } from 'react';
import { Box, Group, InputBase, Radio, Text } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { useProductsByIds } from '@/api/catalog/hooks/useProductsByIds';

import { Loader } from '@/ui/containers/ContentEditor/Loader';
import { handleAddSnippetData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper/SelectSnippetModalWrapper.helpers';
import { useGetSnippetData } from '@/ui/containers/ContentEditor/snippets/hooks/useGetSnippetData';
import { CustomProducts } from '@/ui/containers/ContentEditor/snippets/ProductsSnippetModal/components/CustomProducts/CustomProducts';
import { EProductsSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/ProductsSnippetModal/ProductsSnippetModal.constants';
import {
  getProductsSnippetInitialValues,
  validateProductItems,
} from '@/ui/containers/ContentEditor/snippets/ProductsSnippetModal/ProductsSnippetModal.helpers';
import { productsSnippetSchema } from '@/ui/containers/ContentEditor/snippets/ProductsSnippetModal/ProductsSnippetModal.schema';
import { SnippetModal } from '@/ui/containers/ContentEditor/snippets/SnippetModal';

export const ProductsModalUI: FC = () => {
  const { defaultValues, snippetData, handleClose } = useGetSnippetData('products');
  const { isLoading, getProductsByIds } = useProductsByIds();

  const form = useForm({
    initialValues: getProductsSnippetInitialValues(),
    validate: yupResolver(productsSnippetSchema),
  });

  useEffect(() => {
    if (defaultValues?.products) {
      const { stringProductValues, nonStringProductValues } = validateProductItems(
        defaultValues.products,
      );

      getProductsByIds({
        productIds: stringProductValues,
      }).then((response) => {
        if (response?.payload) {
          form.setValues({
            ...defaultValues,
            products: [...response.payload.items, ...nonStringProductValues],
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SnippetModal
      isOpen
      isNew={snippetData?.isNew}
      withScroll
      onClose={handleClose}
      title='Слайдер продуктов'
      handleClickBackBtn={handleClose}
      onSubmit={form.onSubmit(async (values) => {
        const normalizeValues = {
          ...values,
          products: values.products.map((el) => (typeof el === 'string' ? el : el.id)),
        };
        return handleAddSnippetData('products', normalizeValues, handleClose, snippetData);
      })}
    >
      <Loader loading={isLoading} blur />
      <InputBase
        value={form.getInputProps(EProductsSnippetFormFields.TITLE).value}
        label='Название полки'
        placeholder='Введите название'
        style={{ width: '100%', gridColumn: 'span 12' }}
        {...form.getInputProps(EProductsSnippetFormFields.TITLE)}
      />
      <Radio.Group
        label='Выбор типа сборки'
        withAsterisk
        style={{ gridColumn: 'span 6' }}
        value={form.getInputProps(EProductsSnippetFormFields.TYPE).value}
        {...form.getInputProps(EProductsSnippetFormFields.TYPE)}
      >
        <Group>
          <Radio value='custom' label='Ручная' />
        </Group>
      </Radio.Group>
      <Radio.Group
        label='Смотреть всё'
        withAsterisk
        style={{ width: '100%', display: 'flex', flexDirection: 'column', gridColumn: 'span 6' }}
        value={
          form.getInputProps(EProductsSnippetFormFields.IS_SHOW_ALL_PRODUCTS).value
            ? 'true'
            : 'false'
        }
        onChange={(value) => {
          form.setFieldValue(EProductsSnippetFormFields.IS_SHOW_ALL_PRODUCTS, value === 'true');
        }}
      >
        <Group style={{ display: 'flex', width: '100%' }}>
          <Radio value='true' label='Да' />
          <Radio value='false' label='Нет' />
        </Group>
      </Radio.Group>
      {form.values.type === 'custom' && (
        <Box style={{ display: 'grid', gridColumn: 'span 12', gap: '24px' }}>
          <Text
            style={{ color: '#66788A', lineHeight: '17px', fontSize: '14px', gridColumn: 'span 6' }}
          >
            Для ручной сборки товаров введите артикулы товаров в поле и нажмите на кнопку
            «Добавить», чтобы добавить артикулы
          </Text>
          <CustomProducts form={form} />
        </Box>
      )}
    </SnippetModal>
  );
};

export const ProductsSnippetModal = React.memo(ProductsModalUI);
