import { DownloadFile, DownloadSnippet, SnippetValues } from '@prosv/core/types/snippets';
import { v4 as uuid_v4 } from 'uuid';

import { EDownloadsSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/DownloadsSnippetModal/DownloadsSnippetModal.constants';

export const getDownloadsSnippetInitialValues = (
  defaultValues?: DownloadSnippet,
): SnippetValues<DownloadSnippet> => {
  const getDefaultFile = (file?: DownloadFile) => {
    return {
      [EDownloadsSnippetFormFields.NAME]: file?.name || '',
      [EDownloadsSnippetFormFields.FILE]: file?.file || {
        id: '',
        fileType: '',
        fileSize: '',
        filePath: '',
        fileName: '',
      },
    };
  };

  const files = defaultValues?.files?.map(getDefaultFile);
  const defaultFile = getDefaultFile();

  return {
    id: defaultValues?.id || uuid_v4(),
    title: defaultValues?.title || '',
    files: files || [defaultFile],
  };
};
