import { FC, PropsWithChildren } from 'react';
import { Text } from '@mantine/core';

export const TableColumnTitle: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text c='darkest' size='sm' fw={600}>
      {children}
    </Text>
  );
};
