import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '@/api/instance';
import { URLS } from '@/api/urls';

import { getUrlWithSearchParams } from '@/utils/getUrlWithSearchParams';
import notify from '@/utils/notify';

import { supportsInitialState } from '@/pages/Support/SupportsListing.constants';
import { State } from '@/store';
import { createTableFilterSlice } from '@/store/slices/banners/bannersSlice';
import { urlCodeError } from '@/store/slices/support/supportDetails.constants';
import {
  CategoryTreatmentResponse,
  ISupportsListResponse,
  SupportDetailForm,
  SupportDetailResponse,
  SupportReject,
} from '@/store/slices/support/supportDetailsTypes';

export interface CreateFilterSlice {
  currentPage?: number;
  limit?: string;
  category?: string[];
  question?: string;
  isActive?: string | null;
  sortField?: string;
  sortDirection?: 'asc' | 'desc';
}

export const supportDetailSlice = createApi({
  reducerPath: 'api/supportDetailApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [],
  endpoints: (build) => ({
    getSupportsListing: build.query<ISupportsListResponse, CreateFilterSlice>({
      query: (params) => {
        const { limit, currentPage, ...rest } = params;

        const newParams = {
          offset: currentPage === 1 ? 0 : Number(limit) * (Number(currentPage) - 1),
          limit,
          category: rest.category,
          question: rest.question,
          isActive: rest.isActive,
          sortField: rest.sortField,
          sortDirection: rest.sortDirection,
        };

        return {
          method: 'get',
          url: getUrlWithSearchParams(URLS.support.listing, newParams, { arrayFormat: 'comma' }),
        };
      },
    }),
    getSupportDetail: build.query<SupportDetailResponse, string>({
      query: (id) => {
        return {
          url: getUrlWithSearchParams(URLS.support.detail(id)),
        };
      },

      keepUnusedDataFor: 0,
    }),
    getCategoryTreatment: build.query<CategoryTreatmentResponse, void>({
      query: () => ({
        url: getUrlWithSearchParams('v1/dictionary/faqcategory', { limit: 20, offset: 0 }),
      }),
    }),
    createNewQuestion: build.query<void, { data: SupportDetailForm; backToList: () => void }>({
      query: ({ data }) => {
        const requestData = {
          ...data,
          isActive: !!data.isActive,
          isDisplayOnMain: !!data.isDisplayOnMain,
          isDisplayOnHeader: !!data.isDisplayOnHeader,
          positionOnMain: !!data.isDisplayOnMain ? data.positionOnMain : null,
        };
        return {
          url: getUrlWithSearchParams(URLS.support.listing),
          method: 'post',
          data: requestData,
        };
      },

      onQueryStarted: async ({ backToList, data }, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          notify({ message: 'Вопрос был успешно создан', type: 'success' });
          backToList();
        } catch (e) {
          const { error } = e as { error: SupportReject };
          if (error?.data?.code === urlCodeError) {
            notify({ message: `Символьный код "${data.urlCode}" уже занят`, type: 'error' });
          } else {
            notify({ message: 'Произошла ошибка при создании вопроса', type: 'error' });
          }
        }
      },
    }),
    updateQuestion: build.query<
      void,
      { data: SupportDetailForm; id: string; backToList: () => void }
    >({
      query: ({ data, id }) => {
        const requestData = {
          ...data,
          isActive: !!data.isActive,
          isDisplayOnMain: !!data.isDisplayOnMain,
          isDisplayOnHeader: !!data.isDisplayOnHeader,
          positionOnMain: !!data.isDisplayOnMain ? data.positionOnMain : null,
        };
        return {
          url: getUrlWithSearchParams(URLS.support.detail(id)),
          method: 'patch',
          data: requestData,
        };
      },
      onQueryStarted: async ({ backToList }, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          notify({ message: 'Вопрос был успешно обновлен', type: 'success' });
          backToList();
        } catch (e) {
          notify({ message: 'Произошла ошибка при обновлении вопроса', type: 'error' });
        }
      },
    }),
    deleteQuestion: build.query<void, { id: string; onSuccess: () => void }>({
      query: ({ id }) => ({
        url: getUrlWithSearchParams(URLS.support.detail(id)),
        method: 'delete',
      }),
      onQueryStarted: async ({ onSuccess }, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          notify({ message: 'Вопрос был успешно удален', type: 'success' });
          onSuccess();
        } catch (e) {
          notify({ message: 'Произошла ошибка при удалении вопроса ', type: 'error' });
        }
      },
    }),
  }),
});

export const supportsFilterSlice = createTableFilterSlice<CreateFilterSlice>({
  name: 'supportsFilter',
  initialState: supportsInitialState,
});

export const supportsFilterActions = supportsFilterSlice.actions;

const support = (state: State) => state.supportsFilter;

export const isDirtySupportFilter = createSelector(
  support,
  ({ isActive, sortField, category, sortDirection, question }) => {
    return (
      JSON.stringify({ isActive, sortField, category, sortDirection, question }) !==
      JSON.stringify({
        isActive: supportsInitialState.isActive,
        sortField: supportsInitialState.sortField,
        category: supportsInitialState.category,
        sortDirection: supportsInitialState.sortDirection,
        question: supportsInitialState.question,
      })
    );
  },
);

export const supportsFiltersSelector = createSelector(support, (filters) => {
  const { limit, currentPage, question, category, isActive, sortField, sortDirection } = filters;
  return { limit, currentPage, question, category, isActive, sortField, sortDirection };
});

export const {
  useGetSupportDetailQuery,
  useGetCategoryTreatmentQuery,
  useLazyCreateNewQuestionQuery,
  useLazyUpdateQuestionQuery,
  useLazyGetSupportsListingQuery,
  useLazyDeleteQuestionQuery,
} = supportDetailSlice;
