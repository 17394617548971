import { getFormatDateEndDay, getFormatDateStartDay } from '@/utils/getFormatDateForBackend';

import { ReviewAndQuestionsQueryParams } from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.types';

export const formatReviewsRequestData = (params: ReviewAndQuestionsQueryParams) => {
  return {
    offset: params.currentPage === 1 ? 0 : Number(params.limit) * (Number(params.currentPage) - 1),
    limit: params.limit,
    sortField: params.sortField,
    sortDirection: params.sortDirection,
    status: params.status || [],
    rating: params.rating || [],
    periodStart: params.createDate?.[0] ? getFormatDateStartDay(params.createDate?.[0]) : null,
    periodEnd: params.createDate?.[1] ? getFormatDateEndDay(params.createDate?.[1]) : null,
    article: params.article,
    id: params.id,
    hasReply: params.hasReply,
  };
};
