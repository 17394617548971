import React, { FC } from 'react';
import { Box, Text } from '@mantine/core';
import { useForm } from '@mantine/form';

import { CatalogTagsFormFields } from '@/modules/catalogTags/types';

import { FileLoader } from '@/ui/components/FileLoader';
import { IFileLoaderOnChangeParams } from '@/ui/components/FileLoader/FileLoader.types';
import { getImageData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper';

import styles from './CatalogTagsForm.module.scss';

interface Props {
  formId: string;
  handleSubmit: (values: CatalogTagsFormFields) => void;
  sessionID: string;
}

export const CatalogTagsForm: FC<Props> = ({ formId, handleSubmit, sessionID }) => {
  const form = useForm({
    initialValues: {
      file: {
        id: '',
        path: '',
        name: '',
      },
    },
  });

  const handleChange = ({ id, path, name }: IFileLoaderOnChangeParams) => {
    form.values.file = {
      id,
      path,
      name,
    };
  };

  const handleRemove = () => {
    form.values.file = {
      id: '',
      path: '',
      name: '',
    };
  };

  const imgData = getImageData({
    id: form.values.file.id,
    name: form.values.file.name,
    path: form.values.file.path.replace(process.env.MEDIA_STORAGE_URL as string, ''),
  });

  return (
    <form id={formId} onSubmit={form.onSubmit(handleSubmit)}>
      <Box className={styles.tip}>
        <Text fw={500}>Загрузка тегов</Text>
        <Text c='#66788A'>Добавьте файл .csv с перечнем тегов для отображения в Каталоге</Text>
      </Box>
      <FileLoader
        accept='catalogTagsFiles'
        sessionID={sessionID}
        savedFormFiles={imgData}
        placeholder='Перетащите или добавьте файл'
        onChange={handleChange}
        onRemove={handleRemove}
      />
    </form>
  );
};
