import { useEffect } from 'react';
import { Box, Button, Group, MultiSelect, Select, TextInput } from '@mantine/core';
import { RotateClockwise } from 'tabler-icons-react';
import { useDebounce } from 'use-debounce';

import { PAGE_ELEMENTS } from '@/constants/common';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import styles from './BannerListingFilters.module.scss';

import {
  inputStyles,
  locationOptions,
  pageOptions,
  siteOptions,
  work,
} from '@/pages/Banners/BannersListing/BannerListing.constants';
import {
  bannerFiltersSelector,
  isDirtyFilterSelector,
} from '@/store/slices/banners/bannersSelectors';
import { bannersFilterActions, CreateFilterSlice } from '@/store/slices/banners/bannersSlice';

export const BannerListingFilters = ({
  getBannersListing,
}: {
  getBannersListing: (params: CreateFilterSlice) => void;
}) => {
  const { resetFilters, setFilterWithResetCurrentPage } = bannersFilterActions;
  const { limit, page, place, site, currentPage, isActive, sortDirection, sortField } =
    useAppSelector(bannerFiltersSelector);

  const query = useAppSelector((state) => state.bannersFilter.query);
  const debouncedValue = useDebounce(query, 500);

  const isDirty = useAppSelector(isDirtyFilterSelector);

  const dispatch = useAppDispatch();

  const handleChange = (value: Partial<CreateFilterSlice>) => {
    dispatch(setFilterWithResetCurrentPage(value));
  };

  const handleSetTitle = (v: string) => {
    handleChange({ query: v });
  };

  useEffect(() => {
    getBannersListing({
      query: debouncedValue[0],
      limit,
      page,
      place,
      site,
      currentPage,
      isActive,
      sortDirection,
      sortField,
    });
  }, [
    limit,
    page,
    place,
    site,
    currentPage,
    isActive,
    sortDirection,
    sortField,
    debouncedValue[0],
  ]);

  return (
    <Group mb={24} justify='left' align='flex-end' gap='xl' grow style={{ zIndex: 5 }}>
      <Box className={styles.field}>
        <TextInput
          label='Заголовок'
          placeholder='Заголовок'
          value={query}
          styles={inputStyles}
          onChange={(v) => handleSetTitle(v.target.value)}
        />
      </Box>
      <Box className={styles.field}>
        <MultiSelect
          label='Страница'
          placeholder='Страница'
          data={pageOptions}
          onChange={(v) => handleChange({ page: v })}
          value={page}
          height={40}
        />
      </Box>
      <Box className={styles.field}>
        <MultiSelect
          label='Расположение'
          placeholder='Расположение'
          data={locationOptions}
          value={place}
          height={40}
          onChange={(v) => handleChange({ place: v })}
        />
      </Box>
      <Box className={styles.field}>
        <MultiSelect
          label='Сайт'
          placeholder='Сайт'
          height={40}
          data={siteOptions}
          value={site}
          onChange={(v) => handleChange({ site: v })}
        />
      </Box>
      <Box className={styles.field}>
        <Select
          label='Включен'
          placeholder='Да/Нет'
          clearable
          height={40}
          data={work}
          value={isActive}
          onChange={(value) => {
            handleChange({ isActive: value === isActive ? undefined : value });
          }}
        />
      </Box>
      <div style={{ display: 'flex', marginLeft: 'auto', justifyContent: 'end' }}>
        {!isDirty && (
          <Box
            style={{ display: 'flex', alignItems: 'center', height: 38, maxWidth: 'fit-content' }}
          >
            <Button
              className={styles.resetBtn}
              variant='subtle'
              radius='xs'
              size='sm'
              rightSection={<RotateClockwise size={20} />}
              onClick={() => dispatch(resetFilters())}
            >
              Сбросить
            </Button>
          </Box>
        )}
        <Box className={styles.pageSize}>
          <Select
            className={styles.select}
            size='sm'
            value={limit || PAGE_ELEMENTS[0].value}
            onChange={(v) => handleChange({ limit: v as string })}
            allowDeselect
            data={PAGE_ELEMENTS}
          />
        </Box>
      </div>
    </Group>
  );
};
