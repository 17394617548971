import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Modal, Text } from '@mantine/core';

import styles from './UmkDetailFormModal.module.scss';

interface Props {
  handleClose: () => void;
  createdUmkLink: string;
  isOpen: boolean;
}

export const UmkDetailFormModal: FC<Props> = ({ handleClose, createdUmkLink, isOpen }) => {
  return (
    <Modal
      opened={isOpen}
      onClose={handleClose}
      title={<h2>Перейти к редактированию?</h2>}
      centered
      className={styles.modal}
    >
      <Box
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          rowGap: '20px',
          columnGap: '10px',
        }}
      >
        <Text style={{ gridColumn: 'span 4' }}>Страница для данного элемента уже существует.</Text>
        <Button
          component={Link}
          to={`/umk/${createdUmkLink}`}
          target='_blank'
          style={{ width: '100%', gridColumn: 'span 2' }}
        >
          Да
        </Button>
        <Button
          onClick={handleClose}
          variant='outline'
          style={{ width: '100%', gridColumn: 'span 2', color: 'red' }}
        >
          Нет
        </Button>
      </Box>
    </Modal>
  );
};
