import React, { FC } from 'react';
import { Input, InputBase, Text, Textarea } from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form';
import { PeopleSnippet, Person, SnippetValues } from '@prosv/core/types/snippets';
import { Trash } from 'tabler-icons-react';

import { S3_STORAGE_LINK } from '@/constants/common';

import { FileLoader } from '@/ui/components/FileLoader';
import { IFileLoaderOnChangeParams } from '@/ui/components/FileLoader/FileLoader.types';
import { TooltipTextWrapper } from '@/ui/components/TooltipFormWrapper';
import { getImageData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper/SelectSnippetModalWrapper.helpers';

import { EPeopleSnippetFormFields } from './PeopleSnippetModal.constants';

import styles from './PeopleFromItem.module.scss';

type TPeopleFromItemProps = {
  index: number;
  form: UseFormReturnType<SnippetValues<PeopleSnippet>>;
  person: Person;
  sessionID: string;
};

export const PeopleFromItemUI: FC<TPeopleFromItemProps> = ({ index, form, sessionID }) => {
  const handleRemovePerson = () => {
    form.values.people?.splice(index, 1);
    form.setValues(form.values);
  };

  const handleChange = ({ id, path, name }: IFileLoaderOnChangeParams) => {
    if (form.values.people?.[index]) {
      form.values.people[index] = {
        ...form.values.people[index],
        file: {
          id: id,
          path,
          name,
        },
      };
    }
  };
  const handleRemove = () => {
    if (form.values.people?.[index]) {
      form.values.people[index] = {
        ...form.values.people[index],
        file: {
          id: '',
          path: '',
          name: '',
        },
      };
    }
  };

  const imgData = getImageData({
    id: form.values.people?.[index].file?.id,
    name: form.values.people?.[index].file?.name,
    path: form.values.people?.[index].file?.path.replace(S3_STORAGE_LINK as string, ''),
  });

  return (
    <div
      key={index}
      style={{
        gridColumn: 'span 12',
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gap: '24px',
      }}
    >
      <Text
        size='sm'
        c='black'
        ta='center'
        style={{
          textTransform: 'none',
          fontWeight: 500,
          gridColumn: 'span 12',
          textAlign: 'start',
          marginTop: 20,
          display: 'flex',
          alignItems: 'center',
          gap: 10,
        }}
      >
        Человек {index + 1}
        {index !== 0 && (
          <Trash
            size={20}
            color='gray'
            onClick={handleRemovePerson}
            style={{ cursor: 'pointer' }}
          />
        )}
      </Text>

      <InputBase
        label={
          <TooltipTextWrapper
            label='ФИО'
            text='Используется также для заполнения SEO-тега name у изображения'
          />
        }
        placeholder='Введите ФИО'
        style={{ gridColumn: 'span 3' }}
        {...form.getInputProps(`people.${index}.${EPeopleSnippetFormFields.FULL_NAME}`)}
      />

      <InputBase
        label='Должность'
        placeholder='Введите должность'
        style={{ gridColumn: 'span 3' }}
        {...form.getInputProps(`people.${index}.${EPeopleSnippetFormFields.POSITION}`)}
      />

      <InputBase
        label='Телефон'
        style={{ gridColumn: 'span 3' }}
        placeholder='+7 (___) ___ __ __'
        {...form.getInputProps(`people.${index}.${EPeopleSnippetFormFields.PHONE}`)}
      />

      <InputBase
        label='Почта'
        placeholder='Введите почту'
        style={{ gridColumn: 'span 3' }}
        {...form.getInputProps(`people.${index}.${EPeopleSnippetFormFields.EMAIL}`)}
      />

      <Textarea
        label={
          <TooltipTextWrapper
            label='Краткая информация'
            text='Используется также для заполнения SEO-тега name у изображения'
          />
        }
        placeholder='Введите краткую информацию'
        className={styles.textarea}
        {...form.getInputProps(`people.${index}.${EPeopleSnippetFormFields.BRIEF_INFORMATION}`)}
      />

      <Input.Wrapper
        label='Изображение'
        style={{ gridColumn: 'span 6', gridRow: 'span 2' }}
        {...form.getInputProps(`people.${index}.${EPeopleSnippetFormFields.FILE}`)}
      >
        <FileLoader
          sessionID={sessionID}
          onRemove={handleRemove}
          onChange={handleChange}
          savedFormFiles={imgData}
          placeholder='Перетащите или добавьте изображение. Изображение должно быть не более 1000 пикс. в высоту'
        />
      </Input.Wrapper>
      <InputBase
        label='Описание'
        placeholder='Введите описание изображения'
        style={{ gridColumn: 'span 3' }}
        {...form.getInputProps(`people.${index}.${EPeopleSnippetFormFields.ALT}`)}
      />
      <InputBase
        label='Заголовок изображения'
        placeholder='Введите заголовок'
        style={{ gridColumn: 'span 3' }}
        {...form.getInputProps(`people.${index}.${EPeopleSnippetFormFields.IMAGE_TITLE}`)}
      />
    </div>
  );
};
