import { FC } from 'react';
import { Box, Title } from '@mantine/core';

interface IPageHeaderTitle {
  title: string;
  subTitle?: string;
}

const PageHeaderTitle: FC<IPageHeaderTitle> = ({ title, subTitle }) => {
  return (
    <Title order={1}>
      {title}
      {subTitle && (
        <Box
          style={{
            display: 'block',
            color: 'var(--mantine-color-gray-6)',
            fontSize: '18px',
          }}
        >
          {subTitle}
        </Box>
      )}
    </Title>
  );
};

export default PageHeaderTitle;
