import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { unstable_useBlocker as useBlocker, useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from '@mantine/hooks';

import { useCreateDraft } from '@/api/draft/useCreateDraft';

import notify from '@/utils/notify';
import { getItemPermissions } from '@/utils/permissions';

import { getLoadingSelector, useStoreContentEditor } from '@/ui/containers/ContentEditor/store';
import { createNewDraftData } from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.constants';

import { EditorsState, UmkTabType } from './UmkDetail.types';

import { getUmkUrl } from '@/pages/UmkDetail/UmkDetail.helpers';
import { useDeleteUmkPageMutation, useLazyGetUmkQuery } from '@/store/slices/umk/umk.hooks';
import { UmkType } from '@/store/slices/umkList/umkList.types';

export const useUmkDetailState = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const createDraft = useCreateDraft();
  const isLoading = useStoreContentEditor(getLoadingSelector);
  const [deleteUmk] = useDeleteUmkPageMutation();
  const [umkType] = useLocalStorage<UmkType>({ key: 'umkType' });
  const [getUmkQueryTrigger, { data: umkDetail }] = useLazyGetUmkQuery();

  const [firstDraftId, setFirstDraftId] = useState<string>();
  const [secondDraftId, setSecondDraftId] = useState<string>();
  const [activeTab, setActiveTab] = useState<string | UmkTabType>('general');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isShouldBlock, setIsShouldBlock] = useState(false);

  const [editorsModified, setEditorsModified] = useState<EditorsState>({
    firstBlockEditor: false,
    secondBlockEditor: false,
  });

  const updateEditorModified = useCallback(
    (editorName: keyof EditorsState, isModified: boolean) => {
      setEditorsModified((prevState) => ({
        ...prevState,
        [editorName]: isModified,
      }));
    },
    [setEditorsModified],
  );

  const resetEditorModifiedState = useCallback(
    () =>
      setEditorsModified({
        firstBlockEditor: false,
        secondBlockEditor: false,
      }),
    [setEditorsModified],
  );

  const { proceed, reset, state } = useBlocker(
    isShouldBlock || editorsModified.firstBlockEditor || editorsModified.secondBlockEditor,
  );

  const isDirections = umkType === 'direction';
  const isShowGeneralForm = firstDraftId && secondDraftId;
  const isNewUmk = id === 'new';
  const previewUrl = getUmkUrl(isDirections, firstDraftId, secondDraftId);
  const permissions = getItemPermissions('UMK_PAGE_', umkDetail?.permissions);
  const draftIds = isShowGeneralForm && ([firstDraftId, secondDraftId] as undefined | string[]);

  const firstTabLabel = isDirections ? 'Редактор страницы' : 'Редактор страницы (Блок 1)';

  const getDraftId = (setter: Dispatch<SetStateAction<string | undefined>>) => {
    createDraft.createDraft(createNewDraftData).then((res) => {
      if (res?.payload.draftId) {
        setter(res?.payload.draftId);
      }
    });
  };

  const handleSetDrafts = () => {
    getDraftId(setFirstDraftId);
    getDraftId(setSecondDraftId);
  };

  const fetchUmk = async () => {
    const umk = await getUmkQueryTrigger(id);
    if (umk.data) {
      setFirstDraftId(umk.data.draftIds[0]);
      setSecondDraftId(umk.data.draftIds[1]);
    }
  };

  const notifyDeleteSuccess = () =>
    notify({ message: 'Контент УМК успешно удален', type: 'success' });

  const notifyDeleteError = () =>
    notify({
      message: 'Произошла ошибка, изменения не были сохранены',
      type: 'error',
    });

  const handleDeleteUmk = () => {
    if (umkDetail) {
      deleteUmk(umkDetail.id)
        .unwrap()
        .then(() => {
          notifyDeleteSuccess();
          setDeleteModalOpen(false);
          navigate('/umk');
        })
        .catch(notifyDeleteError);
    }
  };

  const onCancel = useCallback(() => {
    reset?.();
  }, [reset]);

  const onConfirmLeave = useCallback(() => {
    proceed?.();
  }, [proceed]);

  useEffect(() => {
    getUmkQueryTrigger(id);
  }, [getUmkQueryTrigger, id]);

  const title = isNewUmk ? 'Новая страница' : `ID: ${id}`;

  return {
    id,
    title,
    createDraft,
    umkDetail,
    isLoading,
    firstDraftId,
    secondDraftId,
    activeTab,
    isDeleteModalOpen,
    isShowGeneralForm,
    isNewUmk,
    previewUrl,
    permissions,
    draftIds,
    firstTabLabel,
    isDirections,
    isUnsavedChangesModalOpen: state === 'blocked',
    onCancel,
    onConfirmLeave,
    deleteUmk,
    getUmkQueryTrigger,
    setActiveTab,
    setDeleteModalOpen,
    fetchUmk,
    getDraftId,
    handleSetDrafts,
    handleDeleteUmk,
    setIsShouldBlock,
    editorsModified,
    updateEditorModified,
    resetEditorModifiedState,
  };
};
