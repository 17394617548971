import { FC, useEffect } from 'react';
import { Box, CloseButton, Group, Image, SimpleGrid, Text } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconPhoto, IconUpload, IconX } from '@tabler/icons';

import { MAX_FILES, MAX_SIZE } from './FilesLoader.constants';
import { useFilesLoader } from './FilesLoader.hooks';
import { FilesLoaderProps } from './FilesLoader.types';

import styles from './FilesLoader.module.scss';

export const FilesLoader: FC<FilesLoaderProps> = ({
  sessionID,
  savedAttachments,
  acceptImages,
  maxFiles = MAX_FILES,
  maxSize = MAX_SIZE,
  accept = acceptImages ? IMAGE_MIME_TYPE : undefined,
  multiple = true,
  onChange,
  ...props
}) => {
  const {
    freeSlots,
    isUploading: loading,
    onDrop,
    files,
    savedFiles,
    removeFile,
    removeSavedFile,
  } = useFilesLoader({ sessionID, acceptImages, savedAttachments, maxFiles });

  const previewLength = savedFiles.length + files.length;

  useEffect(() => {
    if (!loading)
      onChange?.([...savedFiles.map(({ id }) => id), ...files.map(({ api }) => api?.id ?? '')]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, savedFiles, loading]);

  return (
    <Box>
      {!freeSlots && (
        <Box>
          <Text size='sm' inline>
            Добавлено максимальное количество файлов({maxFiles}). Для добавления новых файлов
            удалите часть из уже добавленных.
          </Text>
        </Box>
      )}

      {freeSlots > 0 && (
        <Dropzone
          className={styles.dropzone}
          {...{ loading, accept, multiple, maxFiles, maxSize, onDrop }}
          {...props}
        >
          <Group
            justify='center'
            gap='xl'
            style={{ minHeight: previewLength > 0 ? 20 : 160, pointerEvents: 'none' }}
          >
            <Dropzone.Accept>
              <IconUpload size={50} stroke={1.5} />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX size={50} stroke={1.5} />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconPhoto size={50} stroke={1.5} />
            </Dropzone.Idle>

            <div>
              <Text size='xl' inline>
                Перетащите или добавьте файлы
              </Text>
              <Text size='sm' color='dimmed' inline mt={7}>
                Максимальное количество: {maxFiles}. Максимальный размер:{' '}
                {Math.round(maxSize / 1024 ** 2)}Mb.
              </Text>
            </div>
          </Group>
        </Dropzone>
      )}

      {previewLength > 0 && (
        <SimpleGrid cols={{ sm: 2, md: 3, lg: 4, xl: 6 }} mt={previewLength > 0 ? 'xl' : 0}>
          {savedFiles.map((item) => (
            <Box className={styles.thumb}>
              <Image
                key={item.id}
                src={process.env.MEDIA_STORAGE_URL + item.config.previewConfig.path}
                title={item.name}
              />
              <CloseButton
                className={styles.deleteButton}
                color='red'
                radius={50}
                onClick={() => removeSavedFile(item.id)}
              />
            </Box>
          ))}

          {files.map((file, index) => {
            const imageUrl = URL.createObjectURL(file);
            return (
              <Box className={styles.thumb} key={file.id}>
                <Image
                  key={index}
                  src={imageUrl}
                  onLoad={() => URL.revokeObjectURL(imageUrl)}
                  title={file.name}
                />
                <CloseButton
                  className={styles.deleteButton}
                  color='red'
                  radius={50}
                  onClick={() => file.id && removeFile(file.id)}
                />
              </Box>
            );
          })}
        </SimpleGrid>
      )}
    </Box>
  );
};
