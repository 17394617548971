import { TCreateDraft } from '@/types/draft/draft';

export const sortDirections = {
  asc: 'desc',
  desc: 'asc',
};

export const USER_ID_MOCK = '07988542-b26a-4a8d-be85-799623ebc808'; // TODO исправить после реализации авторизации

export const createPromotionDraftData: TCreateDraft = {
  content: '',
  meta: {
    userId: USER_ID_MOCK,
  },
};

export enum EAddPromotionConfirmationTexts {
  TITLE = 'Вы уверены?',
  OUT_TITLE = 'Вы уверены, что хотите выйти?',
  NOT_SAVE_MENU_SITE = 'У вас есть несохраненные данные.',
  TITLE_DELETE_ACTION = 'Удалить акцию?',
  DELETE_DESCRIPTION_ACTION = 'Акция будет удалена безвозвратно',
  TITLE_CHANGE_TEMPLATE = 'Сменить тип акции Полная/Компактная?',
  DESCRIPTION_CHANGE_TEMPLATE = 'Введенные данные на вкладке "Редактор страницы" будут удалены. Шаблон будет обновлен в соответствии с выбранным типом акции',
}

export const DEFAULT_LIMIT = '10';
