import React, { FC, useRef } from 'react';
import { Checkbox, ColorInput, Group, MultiSelect, Radio, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { v4 as uuid_v4 } from 'uuid';

import { handleAddSnippetData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper/SelectSnippetModalWrapper.helpers';
import { replaceFiles } from '@/ui/containers/ContentEditor/snippets/GallerySnippetModal/GallerySnippetModal.helpers';
import { useGetSnippetData } from '@/ui/containers/ContentEditor/snippets/hooks/useGetSnippetData';
import { CustomNews } from '@/ui/containers/ContentEditor/snippets/NewsSnippetModal/components/CustomNews';
import { ENewsSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/NewsSnippetModal/NewsSnippetModal.constants';
import { getNewsSnippetInitialValues } from '@/ui/containers/ContentEditor/snippets/NewsSnippetModal/NewsSnippetModal.helpers';
import { SnippetModal } from '@/ui/containers/ContentEditor/snippets/SnippetModal';
import { COLOR_PICKER_COLORS } from '@/ui/containers/ContentEditor/snippets/TextSnippetModal/TextSnippetModal.constants';

import { useDictionaryOptions } from '@/store/slices/dictionary';

const NewsModalUI: FC = () => {
  const { defaultValues, snippetData, handleClose } = useGetSnippetData('news');
  const sessionID = useRef(uuid_v4());

  const { data: tagSelectOptions } = useDictionaryOptions('tag');
  const { data: categorySelectOptions } = useDictionaryOptions('category');
  const { data: divisionSelectOptions } = useDictionaryOptions('division');
  const { data: companySelectOptions } = useDictionaryOptions('company');
  const { data: themeSelectOptions } = useDictionaryOptions('theme');

  const form = useForm({
    initialValues: getNewsSnippetInitialValues(defaultValues),
  });

  return (
    <SnippetModal
      isOpen
      isNew={snippetData?.isNew}
      onClose={handleClose}
      title='Слайдер новостей'
      handleClickBackBtn={handleClose}
      onSubmit={form.onSubmit(async (values) => {
        if ((values.news && values.news.length > 0) || values.type !== 'custom') {
          // TODO: разобраться с полем slides в снипете новостей
          await replaceFiles(values as any, sessionID.current);
          return handleAddSnippetData('news', values, handleClose, snippetData);
        }
      })}
    >
      <div style={{ gridColumn: 'span 12' }}>
        <Radio.Group
          label='Выбор типа сборки'
          withAsterisk
          value={form.getInputProps(ENewsSnippetFormFields.TYPE).value}
          {...form.getInputProps(ENewsSnippetFormFields.TYPE)}
        >
          <Group>
            <Radio value='auto' label='Автоматическая' />
            <Radio value='filters' label='Автоматическая с фильтрами' />
            <Radio value='custom' label='Ручная' />
          </Group>
        </Radio.Group>
      </div>
      <div style={{ gridColumn: 'span 12' }}>
        <Checkbox
          label='Зациклить слайдер'
          checked={form.getInputProps(ENewsSnippetFormFields.LOOP_SLIDER).value}
          {...form.getInputProps(ENewsSnippetFormFields.LOOP_SLIDER)}
        />
      </div>
      {form.values.type === 'auto' && (
        <ColorInput
          placeholder='Выберите цвет'
          label='Цвет заливки фона'
          style={{ gridColumn: '1 / 4' }}
          withPicker={false}
          swatches={COLOR_PICKER_COLORS}
          {...form.getInputProps(ENewsSnippetFormFields.BACKGROUND)}
        />
      )}
      {form.values.type === 'custom' && <CustomNews form={form} />}
      {form.values.type === 'filters' && (
        <div
          style={{
            gridColumn: 'span 12',
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
            gap: '24px',
          }}
        >
          <Select
            label='Категория'
            placeholder='Категория'
            data={categorySelectOptions}
            style={{ gridColumn: 'span 5' }}
            value={form.getInputProps(ENewsSnippetFormFields.CATEGORY).value}
            {...form.getInputProps(ENewsSnippetFormFields.CATEGORY)}
          />
          <Select
            label='Тема'
            placeholder='Тема'
            data={themeSelectOptions}
            style={{ gridColumn: 'span 5' }}
            value={form.getInputProps(ENewsSnippetFormFields.THEME).value}
            {...form.getInputProps(ENewsSnippetFormFields.THEME)}
          />

          <Select
            label='Дивизион'
            placeholder='Дивизион'
            data={divisionSelectOptions}
            style={{ gridColumn: 'span 5' }}
            value={form.getInputProps(ENewsSnippetFormFields.DIVISION).value}
            {...form.getInputProps(ENewsSnippetFormFields.DIVISION)}
          />

          <Select
            label='Компания'
            placeholder='Компания'
            data={companySelectOptions}
            style={{ gridColumn: 'span 5' }}
            value={form.getInputProps(ENewsSnippetFormFields.COMPANY).value}
            {...form.getInputProps(ENewsSnippetFormFields.COMPANY)}
          />

          <MultiSelect
            label='Теги'
            comboboxProps={{ position: 'top' }}
            placeholder='Компания'
            data={tagSelectOptions}
            style={{ gridColumn: '1 / 4' }}
            value={form.getInputProps(ENewsSnippetFormFields.TAGS).value}
            {...form.getInputProps(ENewsSnippetFormFields.TAGS)}
          />
        </div>
      )}
    </SnippetModal>
  );
};

export const NewsSnippetModal = React.memo(NewsModalUI);
