import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { LoadingOverlay, Modal, Table } from '@mantine/core';

import { getGlobalSettings, putGlobalSettings } from '@/api/globalSettings/globalSettings';

import notify from '@/utils/notify';

import { TableColumnTitle } from '@/ui/components/TableExt/components/TableColumnTitle';
import { GlobalSettingsForm } from '@/ui/containers/GlobalSettingsContainer/components/GlobalSettingsForm/GlobalSettingsForm';
import { IGlobalSettingFormFields } from '@/ui/containers/GlobalSettingsContainer/components/GlobalSettingsForm/GlobalSettingsForm.types';
import {
  getSettingsByType,
  prepareData,
} from '@/ui/containers/GlobalSettingsContainer/components/GlobalSettingTable/GlobalSettingsTable.helpers';
import {
  ISetting,
  TSettingType,
} from '@/ui/containers/GlobalSettingsContainer/components/GlobalSettingTable/GlobalSettingsTable.types';
import { GlobalSettingsTableBody } from '@/ui/containers/GlobalSettingsContainer/components/GlobalSettingTable/GlobalSettingsTableBody';
import { IGlobalSettingItem } from '@/ui/containers/GlobalSettingsContainer/GlobalSettingsContainer.types';

interface IProps {
  elements: IGlobalSettingItem[];
}

export const GlobalSettingTable: FC<IProps> = ({ elements }) => {
  const [siteType, setSiteType] = useState<TSettingType | null>(null);
  const [siteSettings, setSettings] = useState<ISetting[] | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const onSubmit = async (values: IGlobalSettingFormFields) => {
    try {
      if (siteType) {
        setIsFetching(true);
        await putGlobalSettings(prepareData(siteType, values));
        setSiteType(null);
        setSettings(null);
      }
    } catch (err) {
      // eslint-disable-next-line no-restricted-syntax
      console.log(err);
      notify({ message: 'Ошибка сохранения глобальных настроек', type: 'error' });
    } finally {
      setIsFetching(false);
    }
  };

  const settingsByType = useMemo(() => {
    if (!siteSettings || !siteType) return null;
    return siteSettings.filter((item) => item.name.startsWith(siteType));
  }, [siteSettings, siteType]);

  const onGetGlobalSettings = useCallback(async () => {
    try {
      setIsFetching(true);
      const data = await getGlobalSettings();
      if (data) {
        setSettings(getSettingsByType(data.payload));
      }
    } catch (err) {
      // eslint-disable-next-line no-restricted-syntax
      console.log(err);
    } finally {
      setIsFetching(false);
    }
  }, []);

  const onCloseModalHandle = () => {
    setSiteType(null);
    setSettings(null);
  };

  useEffect(() => {
    if (siteType) {
      onGetGlobalSettings();
    }
  }, [siteType]);

  return (
    <>
      <Table highlightOnHover verticalSpacing='xs' fz='sm'>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>
              <TableColumnTitle>Название сайта</TableColumnTitle>
            </Table.Th>
          </Table.Tr>
        </Table.Thead>

        <GlobalSettingsTableBody items={elements} onClickHandle={(type) => setSiteType(type)} />
        {settingsByType && !isFetching && (
          <Modal size={'lg'} opened={!!settingsByType} onClose={onCloseModalHandle}>
            <GlobalSettingsForm globalSetting={settingsByType} handleSubmit={onSubmit} />
          </Modal>
        )}
      </Table>
      <LoadingOverlay visible={isFetching} />
    </>
  );
};
