import { FC } from 'react';
import { useDocumentTitle } from '@mantine/hooks';

import { TITLE_MENU } from '@/constants/titles';

import MenusContainer from '@/ui/containers/MenusContainer/MenusContainer';

const test = false;

export const Menus: FC = () => {
  useDocumentTitle(TITLE_MENU);

  if (test) return null;

  return <MenusContainer />;
};
