import { TemplateItem } from '@/types/templates/templates';

import { ETemplateFormFields } from '@/ui/containers/TemplateCreationContainer/TemplateForm/TemplateForm.constants';
import { ITemplateFormFields } from '@/ui/containers/TemplateCreationContainer/TemplateForm/TemplateForm.types';

export const getDefaultValues = (template: TemplateItem | null): ITemplateFormFields => {
  return {
    //[ETemplateFormFields.NAME]: null,
    [ETemplateFormFields.SITE]: template?.site ?? '',
    [ETemplateFormFields.PAGE_TYPE]: template?.pageType ?? '',
    [ETemplateFormFields.PAGE_H1]: template?.pageH1 || '',
    [ETemplateFormFields.TITLE]: template?.title || '',
    [ETemplateFormFields.DESCRIPTION]: template?.description || '',
    [ETemplateFormFields.KEYWORDS]: template?.keywords || '',
    [ETemplateFormFields.ROBOTS]: template?.robots || ['all'],
    [ETemplateFormFields.AUTHOR]: template?.author || '',
    [ETemplateFormFields.COPYRIGHT]: template?.copyright || '',
    [ETemplateFormFields.CONTENT]: template?.content || '',
    [ETemplateFormFields.CANONICAL]: template?.canonical || '',
  };
};
