import React, { useEffect } from 'react';
import { Box, Button, Tabs } from '@mantine/core';

import notify from '@/utils/notify';

import { useAppSelector } from '@/hooks/useAppSelector';
import { ExportModalWindow, useAsyncJob } from '@/modules/asyncJob';

import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import { ReviewsProductsTab } from '@/ui/containers/Reviews/listing/tabs/ProductsTab/ReviewsProductsTab';

import styles from './Reviews.module.scss';

import {
  reviewsProductsFilterSelector,
  useLazyExportReviewsQuery,
} from '@/store/slices/reviews/reviewsSlice';

const SUCCESS_MESSAGE = 'Задача по экспорту отзывов принята в работу';
const ERROR_MESSAGE = 'Возникла ошибка при экспорте отзывов, попробуйте еще раз';

export const Reviews = () => {
  const [exportReviews] = useLazyExportReviewsQuery();
  const filters = useAppSelector(reviewsProductsFilterSelector);
  const { jobId, getAsyncJobInfo, setJobId, asyncJobData, handleCloseModal, openModal } =
    useAsyncJob();

  const handleExportReviews = async () => {
    exportReviews(filters)
      .unwrap()
      .then((res) => {
        setJobId(res.id);
        notify({
          type: 'success',
          message: SUCCESS_MESSAGE,
        });
      })
      .catch(() => {
        notify({
          type: 'error',
          message: ERROR_MESSAGE,
        });
      });
  };

  return (
    <>
      {asyncJobData && (
        <ExportModalWindow isOpen={openModal} jobData={asyncJobData} onClose={handleCloseModal} />
      )}
      <PageHeader
        title='Отзывы'
        rightButton={
          <Box style={{ display: 'flex', gap: '10px' }}>
            {jobId && (
              <Button
                style={{ width: '260px', border: '1px solid #BFCEDB' }}
                onClick={getAsyncJobInfo}
              >
                Узнать статус экспорта
              </Button>
            )}
            <Button
              style={{ width: '200px', border: '1px solid #BFCEDB' }}
              variant='white'
              onClick={handleExportReviews}
            >
              Скачать
            </Button>
          </Box>
        }
      />

      <Tabs className={styles.tabs} defaultValue='products'>
        <Tabs.List>
          <Tabs.Tab value='products'>Продукты</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel className={styles.panel} value='products' pt='xs'>
          <ReviewsProductsTab />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};
