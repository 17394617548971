import { CSSProperties, FC } from 'react';
import { Text } from '@mantine/core';

interface IProps {
  title: string;
  numSpanInGrid?: string;
  isRequire?: boolean;
  styles?: CSSProperties;
  className?: string;
}

export const CustomLabel: FC<IProps> = ({
  title,
  numSpanInGrid,
  isRequire = false,
  className,
  styles,
}) => {
  return (
    <Text className={className} style={{ gridColumn: `span ${numSpanInGrid}`, ...styles }}>
      {title} {isRequire && <span style={{ color: '#EB5757' }}>{'*'}</span>}
    </Text>
  );
};
