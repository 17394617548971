import React from 'react';
import { Box, Button, InputBase } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { SnippetValues } from '@prosv/core/types/snippets';
import { QuestionSnippet as QuestionSnippetType } from '@prosv/core/types/snippets';
import { Plus, Trash } from 'tabler-icons-react';

import { DnDBlock } from '@/ui/components/DnDBlock';
import { handleAddSnippetData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper';
import { useGetSnippetData } from '@/ui/containers/ContentEditor/snippets/hooks/useGetSnippetData';
import { QuestionSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/QuestionSnippet/QuestionSnippet.constants';
import { getQuestionSnippetDefaultValues } from '@/ui/containers/ContentEditor/snippets/QuestionSnippet/QuestionSnippet.helpers';
import { useQuestionSnippetModal } from '@/ui/containers/ContentEditor/snippets/QuestionSnippet/QuestionSnippet.hooks';
import { questionSnippetSchema } from '@/ui/containers/ContentEditor/snippets/QuestionSnippet/QuestionSnippet.schema';
import { QuestionTextEditorField } from '@/ui/containers/ContentEditor/snippets/QuestionSnippet/QuestionTextEditorField';
import { SnippetModal } from '@/ui/containers/ContentEditor/snippets/SnippetModal';

export const QuestionSnippet = () => {
  const { defaultValues, snippetData, handleClose } = useGetSnippetData('question');

  const form = useForm({
    initialValues: getQuestionSnippetDefaultValues(defaultValues),
    validate: yupResolver(questionSnippetSchema),
  });

  const handlers = useQuestionSnippetModal({ form });

  const handleSubmit = async (values: SnippetValues<QuestionSnippetType>) => {
    return handleAddSnippetData('question', { ...values }, handleClose, snippetData);
  };
  return (
    <SnippetModal
      isOpen
      isNew={snippetData?.isNew}
      onClose={handleClose}
      title='Вопросы и ответы'
      handleClickBackBtn={handleClose}
      onSubmit={form.onSubmit((values) => handleSubmit(values))}
    >
      <InputBase
        label='Заголовок блока'
        placeholder='Введите заголовок блока'
        withAsterisk
        style={{ gridColumn: 'span 6' }}
        {...form.getInputProps(QuestionSnippetFormFields.TITLE)}
      />
      {form.values.questions?.map((question, index) => {
        return (
          <DnDBlock
            key={index}
            id={question.answer + index}
            moveItem={handlers.handleMoveItem}
            index={index}
          >
            <Box
              style={{
                padding: '20px',
                width: '100%',
                gridColumn: 'span 12',
                display: 'flex',
                gap: 10,
                flexDirection: 'column',
              }}
            >
              {index !== 0 && (
                <Trash
                  size={20}
                  color='gray'
                  onClick={() => handlers.handleRemoveBlock(index)}
                  style={{ cursor: 'pointer', position: 'absolute', top: '16px', right: '16px' }}
                />
              )}
              <h2 style={{ margin: 0 }}>Блок {index + 1}</h2>

              <InputBase
                label='Вопрос'
                placeholder='Введите вопрос'
                style={{ gridColumn: 'span 6' }}
                {...form.getInputProps(`questions.${index}.${QuestionSnippetFormFields.QUESTION}`)}
              />
              <QuestionTextEditorField form={form} index={index} />
            </Box>
          </DnDBlock>
        );
      })}
      <div style={{ gridColumn: 'span 12', height: 60, display: 'flex' }}>
        <Button
          variant='white'
          style={{ gridColumn: 'span 12' }}
          onClick={handlers.handleAddQuestion}
        >
          <Plus size={18} style={{ marginRight: 10 }} />
          Добавить ещё блок
        </Button>
      </div>
    </SnippetModal>
  );
};
