import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Center,
  Container,
  Group,
  LoadingOverlay,
  Paper,
  ScrollArea,
  Text,
  Title,
} from '@mantine/core';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { FeatureEditorModal } from './components/FeatureEditorModal';
import FeatureTogglesTable from './components/FeatureTogglesTable/FeatureTogglesTable';

import {
  editFeatureSelector,
  featureListSelector,
  fetchFeaturesListAction,
  loadingFeatureListSelector,
} from '@/store/slices/features/features';
import { setServiceChanged } from '@/store/slices/service/service';

export const FeatureToggles: FC = () => {
  const dispatch = useAppDispatch();
  const featureList = useSelector(featureListSelector);
  const editFeature = useSelector(editFeatureSelector);
  const isLoadingFeature = useSelector(loadingFeatureListSelector);

  useEffect(() => {
    dispatch(setServiceChanged(false));
    dispatch(fetchFeaturesListAction());
  }, [dispatch]);

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          height: '96vh',
          overflowY: 'hidden',
        }}
      >
        <Container fluid mb={16} style={{ marginLeft: 'initial', marginRight: 'initial' }}>
          <Group justify='space-between'>
            <Title order={1}>Сервис включения функциональности</Title>
          </Group>
        </Container>

        <Paper
          radius={8}
          p={24}
          pb={12}
          shadow='xs'
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            maxHeight: '100%',
            overflow: 'hidden',
          }}
        >
          {featureList && featureList?.length > 0 && (
            <ScrollArea h='100%'>
              <FeatureTogglesTable elements={featureList} />
              <LoadingOverlay visible={isLoadingFeature} />
            </ScrollArea>
          )}

          {!featureList?.length && (
            <Box style={{ position: 'relative', height: '100%' }}>
              <LoadingOverlay visible={isLoadingFeature} />

              {!isLoadingFeature && (
                <Center style={{ height: '100%' }}>
                  <Text ta='center'>У вас нет feature toggles</Text>
                </Center>
              )}
            </Box>
          )}
        </Paper>
      </Box>

      {!!editFeature && <FeatureEditorModal editFeature={editFeature} />}
    </>
  );
};
