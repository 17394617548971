import { IMetaTag } from '@/types/metaTagsAnyPage/metaTagsAnyPage';

import { FC, FormEvent, useEffect } from 'react';
import { Box } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { MetaTagForm } from '@/ui/containers/MetaTagsCreationContainer/MetaTagsForm/MetaTagsForm';
import { META_TAG_CREATION_FORM_ID } from '@/ui/containers/MetaTagsCreationContainer/MetaTagsForm/MetaTagsForm.constants';
import { getDefaultValues } from '@/ui/containers/MetaTagsCreationContainer/MetaTagsForm/MetaTagsForm.helpers';
import { metaTagSchema } from '@/ui/containers/MetaTagsCreationContainer/MetaTagsForm/MetaTagsForm.schema';
import { IMetaTagFormFields } from '@/ui/containers/MetaTagsCreationContainer/MetaTagsForm/MetaTagsForm.types';
import { LegendInfo } from '@/ui/containers/TemplateCreationContainer/LegendInfo/LegendInfo';

interface IProps {
  handleSubmit: (values: IMetaTagFormFields, event?: FormEvent<HTMLFormElement>) => void;
  metaTagTemplate: IMetaTag | null;
  setIsShouldBlock: (isShouldBlock: boolean) => void;
}

export const MetaTagsDetailInfo: FC<IProps> = ({
  metaTagTemplate,
  setIsShouldBlock,
  handleSubmit,
}) => {
  const form = useForm({
    initialValues: getDefaultValues(metaTagTemplate),
    validate: yupResolver(metaTagSchema),
  });

  useEffect(() => {
    if (form.isDirty()) {
      setIsShouldBlock(true);
      return;
    }
  }, [form]);

  return (
    <Box
      style={{
        height: 'fit-content',
        display: 'flex',
        gap: '24px',
        paddingBottom: '22px',
        width: '100%',
      }}
    >
      <form
        id={META_TAG_CREATION_FORM_ID}
        style={{
          borderRadius: '6px',
          padding: '16px 12px',
          overflow: 'scroll',
          width: '100%',
        }}
        onSubmit={form.onSubmit(handleSubmit)}
      >
        <MetaTagForm form={form} />
      </form>
      <Box>
        <LegendInfo />
      </Box>
    </Box>
  );
};
